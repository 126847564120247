import { Component } from '@angular/core';
import { SwPush, SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  constructor(private _sw_push: SwPush, private _sw_update: SwUpdate) {
    // Check for update on the server and reload the application if new update is available.
    if (this._sw_push.isEnabled) {
      this._sw_update.available.subscribe(() => {
        if (
          confirm(
            'A new version of shutlips is available, do you want to load new version?'
          )
        ) {
          window.location.reload();
        }
      });
    }
  }
}
