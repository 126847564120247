<div class="question">
  <div class="q-content">
    <img
      src="{{ avatar }}"
      alt="{{ username }}"
      class="img-fluid"
      width="80px"
      height="70px"
    />
    <form
      (ngSubmit)="onSubmit()"
      #addTopicForm="ngForm"
      style="margin-top: 1.8em;"
    >
      <div class="form-group">
        <input
          name="topic"
          placeholder="Enter your discussion topic"
          type="text"
          class="form-control form-control-sm"
          autofocus
          id="title"
          required
          [(ngModel)]="title"
          name="title"
          #ctitle="ngModel"
          (input)="check_title()"
          style="margin-bottom: .5em;font-size: 15px;"
          maxlength="80"
        />
        <div class="alert alert-danger alert-sm" *ngIf="taken && title">
          A discussion with this title already exist
        </div>
        <textarea
          name="comment"
          rows="3"
          [(ngModel)]="content"
          placeholder="Add some details to start a discussion"
          name="ucontent"
          #ucontent="ngModel"
          required
          class="form-control form-control-sm"
          style="margin-bottom: .5em; font-size: 15px;"
        ></textarea>

        <select
          id="category"
          required
          [(ngModel)]="dcategory"
          name="tcategory"
          #tcategory="ngModel"
          style="font-size: 15px; margin-bottom: .5em; margin-right: .5em; display: inline-block;"
          class="form-control form-control-sm col-8"
        >
          <option selected="">Select Category</option>
          <option *ngFor="let c of categories" [value]="c.id">
            {{ c.title }}
          </option>
        </select>
        <div
          [hidden]="tcategory.valid || tcategory.pristine"
          class="invalid-feedback"
        >
          Category is required
        </div>
        <button
          class="btn btn-primary btn-sm col-3"
          [disabled]="addTopicForm.form.invalid || taken"
          type="submit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</div>
