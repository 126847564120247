<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <div class="col-lg-7">
        <div class="row">
          <div class="col-2">
            <img src="{{ avatar }}" alt="{{ username }}" class="img-fluid img-circle"
              style="max-width: 80px; max-height: 70px; margin-bottom: 1em" />
          </div>
          <div class="col-10">
            <h5>
              <a href="javascript:void(0)" (click)="users = total_users">
                <i class="fa fa-users"></i>
                Total Users: {{ users.length }}
              </a>
              |
              <a href="javascript:void(0)" (click)="users = active_users">
                <i class="fa fa-users"></i>
                Total Active Users: {{ active_users.length }}
              </a>
              <br />
              <a href="javascript:void(0)" (click)="users = female_users">
                <i class="fa fa-users"></i>
                Total Female Users: {{ female_users.length }}
              </a>
              |
              <a href="javascript:void(0)" (click)="users = male_users">
                <i class="fa fa-users"></i>
                Total Male Users: {{ male_users.length }}
              </a>
              <br />
              <a href="javascript:void(0)" (click)="users = suspended_users">
                <i class="fa fa-users"></i>
                Total Suspended Users: {{ suspended_users.length }}
              </a>
              |
              <a href="javascript:void(0)" (click)="users = deactivated_users">
                <i class="fa fa-users"></i>
                Total Deactivated Users: {{ deactivated_users.length }}
              </a>
            </h5>
          </div>
        </div>
        <div class="list-group mt-3" *ngIf="(users | filterBy: userFilter).length === 0">
          <p class="text-center">No User Found</p>
        </div>
        <div class="list-group mt-3">
          <div class="list-group-item list-group-item-action flex-column align-items-start" *ngFor="
              let t of users
                | filterBy: userFilter
                | paginate: { itemsPerPage: 20, currentPage: p }
            ">
            <div class="row">
              <div class="col-md-2">
                <img src="{{ t.avatarUrl }}" alt="{{ t.username }}" class="img-fluid img-circle"
                  style="max-width: 80px; max-height: 70px;" />
              </div>
              <div class="col-md-10">
                <div class="d-flex w-100 justify-content-between">
                  <h6 class="mb-1">
                    <a title="{{ t.username }}" [routerLink]="['/user-profile', t.username]">
                      {{ t.username }}
                    </a>
                  </h6>
                </div>
                <small>
                  <b>
                    Role: {{ t.role }} | Gender: {{ t.gender }} | Age:
                    {{ t.age }} | Joined: {{ date(t.joined) }}
                    <span *ngIf="t.suspended" class="text-danger">
                      | Suspended: {{ date(t.suspended_date) }}
                    </span>
                    <span *ngIf="t.deactivated" class="text-danger">
                      | Deactivated: {{ date(t.deactivated_date) }}
                    </span>
                  </b>
                </small>
                <ul class="list-inline mt-2 text-capitalize">
                  <li class="list-inline-item" *ngIf="t.username !== username">
                    <button class="btn btn-sm btn-default" (click)="set_user(t.username)">
                      <i class="fa fa-envelope"></i>
                    </button>
                  </li>
                  <li class="list-inline-item" *ngIf="role === 'admin'">
                    <button class="btn btn-sm btn-primary" *ngIf="t.role !== 'admin'" (click)="make_admin(t.uid)">
                      Admin
                    </button>
                    <button class="btn btn-sm btn-warning" *ngIf="t.role === 'admin'" (click)="revoke_admin(t.uid)">
                      Revoke Admin
                    </button>
                  </li>
                  <li class="list-inline-item" *ngIf="role === 'admin'">
                    <button class="btn btn-sm btn-primary" *ngIf="t.role !== 'moderator'"
                      (click)="make_moderator(t.uid)">
                      Moderator
                    </button>
                    <button class="btn btn-sm btn-warning" *ngIf="t.role === 'moderator'"
                      (click)="revoke_moderator(t.uid)">
                      Revoke Moderator
                    </button>
                  </li>
                  <li class="list-inline-item"
                    *ngIf="role !== 'admin' && t.role !== 'admin' && t.username !== username">
                    <button class="btn btn-sm btn-warning" *ngIf="t.suspended === false" (click)="suspend_user(t.uid)">
                      Suspend User
                    </button>
                    <button class="btn btn-sm btn-primary" *ngIf="t.suspended === true"
                      (click)="reactivate_user(t.uid)">
                      Unsuspend User
                    </button>
                  </li>
                  <li class="list-inline-item" *ngIf="role === 'admin'">
                    <button class="btn btn-sm btn-warning" *ngIf="t.deactivated === false"
                      (click)="disable_user(t.uid)">
                      Disable User
                    </button>
                    <button class="btn btn-sm btn-primary" *ngIf="t.deactivated === true" (click)="enable_user(t.uid)">
                      Enable User
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>

      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>