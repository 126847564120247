import { Component, OnInit, OnDestroy } from '@angular/core';
import { ForumService } from 'src/app/services/forum.service';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.css'],
})
export class ProfilePreviewComponent implements OnInit, OnDestroy {
  admin: boolean = false;
  edit: boolean = false;
  edit_verse: boolean = false;
  avatar: string;
  username: string;
  quote: string;
  name: string;
  sub: Subscription;
  quotes = [];
  quote_uid: string;
  verse_uid: string;
  verses = [];
  verse: string = '';
  text: string = '';
  role: string = '';
  slotID = environment.adsense_profile_preview.slotID;
  slotID1 = environment.adsense_profile_preview_1.slotID;
  adClient = environment.adsense_profile_preview.adClient;

  constructor(private _forum: ForumService) {
    this.username = window.sessionStorage['slu_username']
      ? window.sessionStorage['slu_username']
      : window.localStorage['slu_username'];
    this.avatar = window.sessionStorage['slu_avatarUrl']
      ? window.sessionStorage['slu_avatarUrl']
      : window.localStorage['slu_avatarUrl'];
    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];
  }

  ngOnInit() {
    if (this.role === 'admin' || this.role === 'moderator') {
      this.admin = true;
    }

    this.sub = this._forum.get_quotes().subscribe((q) => (this.quotes = q));
    this.sub = this._forum.get_verses().subscribe((q) => (this.verses = q));
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

  change(quote) {
    this.edit = !this.edit;
    this.quote = quote.quote;
    this.name = quote.name;
    this.quote_uid = quote.uid;
  }

  change_verse(verse) {
    this.edit_verse = !this.edit_verse;
    this.verse = verse.verse;
    this.text = verse.text;
    this.verse_uid = verse.uid;
  }

  new_quote() {
    this.edit = !this.edit;
  }

  new_verse() {
    this.edit_verse = !this.edit_verse;
  }

  onAddQuote() {
    const data = {
      name: this.name.trim(),
      avatarUrl: this.avatar,
      quote: this.quote.trim(),
    };

    this._forum.add_quote(data);
    this.quote = '';
    this.name = '';
    this.edit = false;
  }

  onAddVerse() {
    const data = {
      verse: this.verse.trim(),
      text: this.text.trim(),
    };

    this._forum.add_verse(data);
    this.verse = '';
    this.text = '';
    this.edit_verse = false;
  }

  onChangeQuote() {
    const data = {
      name: this.name.trim(),
      avatarUrl: this.avatar,
      quote: this.quote,
    };

    this._forum.change_quote(data, this.quote_uid);
    this.quote = '';
    this.name = '';
    this.edit = false;
  }

  onChangeVerse() {
    const data = {
      verse: this.verse.trim(),
      // text: this.text.trim(),
    };

    this._forum.change_verse(data, this.verse_uid);
    this.verse = '';
    this.text = '';
    this.edit_verse = false;
  }
}
