import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ForumService } from 'src/app/services/forum.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  display = true;
  sub: Subscription;
  verses = [];
  version: string = 'v2.4';

  constructor(private _router: Router, private _forum: ForumService) {
    this._router.events.subscribe((event) => {
      if (
        (event['url'] && event['url'] === '/auth/sign-up') ||
        (event['url'] && event['url'] === '/auth/sign-in') ||
        (event['url'] && event['url'] === '/auth/forgot-password') ||
        (event['url'] && event['url'] === '/auth/reset-password') ||
        (event['url'] && event['url'] === '/auth/recovery-questions')
      ) {
        this.display = false;
      }
    });
  }

  ngOnInit() {
    if (window.location.pathname.includes('auth')) {
      this.display = false;
    } else {
      this.display = true;
    }

    this.sub = this._forum.get_verses().subscribe((q) => (this.verses = q));
  }

  clear_pn() {
    window.sessionStorage.removeItem('scroll-to');
    window.sessionStorage.removeItem('prev-page');
  }
}
