<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-7 hm-3-bg">
        <div class="bg-white hm-3-p">
          <div class="row cat-h3-top" style="border-bottom: 5px solid #f2f2f2;">
            <div class="col-xs-2 mr-2 ml-3">
              <img
                src="{{ avatar }}"
                alt="{{ username }}"
                class="img-fluid"
                style="max-width: 80px; max-height: 70px; margin-bottom: 1em;"
              />
            </div>
            <div class="col-xs-10 bl_header mt-3">
              <h4>
                {{ username }}
              </h4>
              <b>{{ role }}</b>
            </div>
          </div>

          <div>
            <div class="general-info-right text-center">
              <div class="col-lg-12">
                <h4 class="text-center mt-4">
                  Send push notification to users
                </h4>
                <form
                  [formGroup]="messageForm"
                  (ngSubmit)="send()"
                  class="mt-2"
                >
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Message title"
                      formControlName="title"
                    />
                  </div>
                  <div class="form-group mt-2">
                    <textarea
                      class="form-control form-control-sm"
                      placeholder="Message title"
                      rows="1"
                      formControlName="message"
                    ></textarea>
                  </div>
                  <button
                    class="btn btn-primary btn-sm mt-3"
                    [disabled]="messageForm.invalid"
                  >
                    Send
                  </button>
                </form>

                <hr />
                <h4 class="text-center mt-2">
                  Last sent messages
                </h4>
                <small *ngIf="messages?.length === 0"
                  >No messages sent yet.</small
                >
                <ul class="list-group mt-3" *ngIf="messages?.length > 0">
                  <li class="list-group-item" *ngFor="let m of messages">
                    <h5>{{ m.title }}</h5>
                    <small>{{ m.message }}</small> -
                    <small>{{ date(m.time) }}</small>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <!--END OF CONTENT-->
        </div>
      </div>
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
