<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <!-- End of col-lg-2-->
      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-12">
            <img src="./assets/images/1-2.jpg" alt="about-banner" class="img-fluid" />
          </div>
          <div class="col-lg-12 about-title">
            <h6>
              Moderator Guidelines
            </h6>
          </div>
          <div class="col-lg-8 about-content" *ngIf="edit">
            <form (ngSubmit)="edit_page()" #aboutForm="ngForm">
              <h4 class="mb-2">Edit Moderator Guidelines Page</h4>
              <div class="form-group">
                <angular-editor [(ngModel)]="content" [ngModelOptions]="{ standalone: true }" [config]="config">
                </angular-editor>
              </div>
              <div class="form-group" style="margin-top: 0.5rem;">
                <button [disabled]="!aboutForm.form.valid" type="submit" class="btn btn-sm btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-8 about-content" *ngIf="!edit">
            <div *ngFor="let about of page_content">
              <button class="btn btn-sm btn-primary mb-2" (click)="show_edit(about)" *ngIf="role === 'admin'">
                Edit Page
              </button>
              <div [innerHtml]="about.content"></div>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <!-- <img
              src="./assets/images/add-2.png"
              alt="addsence"
              class="img-fluid"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>