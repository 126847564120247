import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FilterPipe } from 'ngx-filter-pipe';

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.css'],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  id: string;
  new_category: string;
  category_topics = [];
  categories = [];
  forum_categories = [];
  topic_comments = [];
  topic_comment_replies = [];
  show_delete_category = false;
  sub: Subscription;
  category_image: string = '';
  admin: boolean = false;
  p: number = 1;
  userFilter: any;
  cat: Object;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _route: ActivatedRoute,
    private filterPipe: FilterPipe
  ) {
    this._forum.changeMessage('');

    this.p =
      window.sessionStorage['prev-page'] !== undefined
        ? window.sessionStorage['prev-page']
        : this.p;
  }

  ngOnInit() {
    this._auth.isAuthenticated();
    this._route.paramMap.subscribe((d) => {
      this.id = d.get('id');

      this.sub = this._forum
        .get_forum_category(this.id)
        .subscribe((fc) => (this.categories = fc));

      this.sub = this._forum
        .get_category_topics(this.id)
        .subscribe((topics) => {
          this.category_topics = topics;

          this.filterPipe.transform(this.category_topics, { title: '' });
        });

      this.sub = this._forum
        .get_topic_comments(this.id)
        .subscribe((tc) => (this.topic_comments = tc));
      this.sub = this._forum
        .get_topic_comment_replies(this.id)
        .subscribe((tcr) => (this.topic_comment_replies = tcr));
    });
    this.sub = this._forum
      .get_forum_categories()
      .subscribe((topics) => (this.forum_categories = topics));
    if (
      window.sessionStorage['slu_role'] === 'admin' ||
      window.sessionStorage['slu_role'] === 'moderator' ||
      window.localStorage['slu_role'] === 'admin' ||
      window.localStorage['slu_role'] === 'moderator'
    ) {
      this.admin = true;
    }

    this._forum.currentSearch.subscribe((message) => {
      this.userFilter = { title: message };
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();

    this._forum.search = '';
  }

  category(category: string) {
    return category.split('-').join(' ');
  }

  set_category() {
    window.sessionStorage['set_category'] = this.id;
  }

  dateTime(dt: string): string {
    return moment(dt).fromNow();
  }

  first30(content: string) {
    return `${content.substring(0, 150)}...`;
  }

  set_page_number(page: number, id: string) {
    window.sessionStorage['prev-page'] = page;
    window.sessionStorage['scroll-to'] = id;
  }
}
