<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <!-- End of col-lg-2-->
      <div class="col-lg-7 blog_wrap mb-3">
        <div *ngFor="let p of topics">
          <div class="row">
            <div class="col-lg-12">
              <small class="bg-primary featured" *ngIf="p.featured">
                <i class="fa fa-bookmark"></i> featured
              </small>
              <div class="single_blog" *ngIf="!edit_topic_form">
                <div class="blog-header text-center">
                  <span class="text-center">Started by
                    <span class="post_aouther">
                      <a [routerLink]="['/user-profile', p.author]">{{
                        p.author
                        }}</a></span></span>
                  <span class="post_time text-center">{{
                    dateTime(p.timeCreated)
                    }}</span>
                  <span *ngIf="p.edited">
                    | edited {{ dateTime(p.edited_time) }}</span>
                  <span>|</span>
                  <span class="post-cat text-center"><a title="{{ p.category }}"
                      [routerLink]="['/categories', p.category]">{{ category(p.category) }}</a></span>
                </div>
                <div class="row blg-img text-center">
                  <div class="col-lg-12 bl_header about-title">
                    <img src="{{ p.author_avatar }}" class="img-fluid mr-3 rounded-circle" alt="{{ p.author }}"
                      width="80px" height="70px" />
                    <h4>
                      {{ p.title }}
                    </h4>
                    <p>
                      {{
                      topic_comments.length + comment_replies.length > 0
                      ? topic_comments.length + comment_replies.length
                      : 'No'
                      }}
                      comments in this discussion
                    </p>
                    <button class="btn btn-sm btn-primary" (click)="add_to_featured(p.uid)" *ngIf="
                        (!p.featured && role === 'admin') ||
                        role === 'moderator'
                      ">
                      Add to featured
                    </button>
                    <button class="btn btn-sm btn-primary" (click)="remove_from_featured(p.uid)" *ngIf="
                        (p.featured && role === 'admin') || role === 'moderator'
                      ">
                      Remove from featured
                    </button>
                  </div>
                </div>
                <div class="blog-paragrap text-center" [innerHtml]="p.content"></div>
                <div class="ads">
                  <ng-adsense [adClient]="adClient" [adSlot]="slotID"></ng-adsense>
                </div>
              </div>
            </div>
          </div>

          <div class="row mb-5">
            <div class="col-lg-12">
              <div class="single_blog" *ngIf="edit_topic_form">
                <form (ngSubmit)="edit_topic()" #editTopicForm="ngForm">
                  <div class="form-group mb-2">
                    <select class="form-control form-control-sm" id="topic_category" required
                      [(ngModel)]="topic_category" name="tcategory" #tcategory="ngModel" [value]="topic_category">
                      <option *ngFor="let c of categories" [value]="c.id">
                        {{ c.title }}
                      </option>
                    </select>
                    <div [hidden]="tcategory.valid || tcategory.pristine" class="invalid-feedback">
                      Category is required
                    </div>
                  </div>
                  <div class="form-group">
                    <textarea [(ngModel)]="content" placeholder="Enter your content here"
                      class="form-control form-control-sm" name="ucontent" #ucontent="ngModel"></textarea>
                    <div [hidden]="ucontent.valid || ucontent.pristine" class="invalid-feedback">
                      Content is required
                    </div>
                  </div>
                  <div class="form-group mt-2">
                    <button [disabled]="!editTopicForm.form.valid" type="submit" class="btn btn-primary btn-sm mr-3">
                      Save Changes
                    </button>
                    <button type="button" class="btn btn-primary btn-sm" (click)="
                        edit(p.category, p.content, p.uid, p.timeCreated)
                      ">
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div class="row text-center">
            <div class="col-lg-3 col-xs-12 com-header">comment now</div>
            <div class="col-lg-9 col-xs-12">
              <div class="comment_relly">
                <ul *ngIf="suspended !== 'true'">
                  <li>
                    <a title="Total Comments" class="light-link"><i class="fa fa-comments"></i> [{{
                      topic_comments.length + comment_replies.length
                      ? topic_comments.length + comment_replies.length
                      : 'No Comments'
                      }}]</a>
                  </li>
                  <li style="margin-left: 10px">
                    <a (click)="like(p.id, p.author)" *ngIf="liked_topics.length === 0" title="Like"
                      class="text-primary"><i class="fa fa-thumbs-up"></i> [{{
                      p.likes != 0 ? p.likes : 0
                      }}]
                    </a>
                    <a (click)="unlike(p.id, p.author)" *ngIf="liked_topics.length > 0" title="Like"
                      class="text-primary"><i class="fa fa-thumbs-down"></i> [{{
                      p.likes != 0 ? p.likes : 0
                      }}]
                    </a>
                  </li>
                  <li style="margin-left: 10px">
                    <a title="Add To Favorite" class="text-primary" (click)="favorite(p)"
                      *ngIf="favorites.length === 0">
                      <i class="fa fa-star"></i> Add to favorites
                    </a>
                    <a class="text-danger" *ngIf="favorites.length !== 0" (click)="remove_favorite(p)">
                      <i class="fa fa-star"></i> remove from favorites</a>
                  </li>
                  <li style="margin-left: 10px" *ngIf="check_if_mobile()">
                    <span title="share discussion" class="text-primary" (click)="share(p.title)"
                      style="cursor: pointer">
                      <i class="fa fa-share"></i> Share post
                    </span>
                  </li>
                  <li *ngIf="
                      p.author == username ||
                      role === 'admin' ||
                      role === 'moderator'
                    " style="margin-left: 10px">
                    <a (click)="
                        edit(p.category, p.content, p.uid, p.timeCreated)
                      " title="Edit" class="text-primary"><i class="fa fa-pencil"></i> Edit</a>
                  </li>
                  <li *ngIf="
                      p.author == username ||
                      role === 'admin' ||
                      role === 'moderator'
                    " style="margin-left: 10px">
                    <a (click)="delete(p.id, p.author, p.timeCreated)" title="Delete" class="text-danger"><i
                        class="fa fa-trash"></i> Delete</a>
                  </li>
                  <li *ngIf="p.author != username" style="margin-left: 10px">
                    <a (click)="show_report_form()" title="Report" class="light-red"><i class="fa fa-ban"></i>
                      Report</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="row" *ngIf="suspended !== 'true'">
            <div class="col-lg-12 col-xs-12 mb-5" *ngIf="report_topic_form">
              <div class="articles-info">
                <section class="articles-info-section">
                  <div class="comments">
                    <!-- WRITE NEW COMMENT -->
                    <form (ngSubmit)="report(p.id, p.author, p.title)" #reportTopicForm="ngForm">
                      <textarea class="col-xs-12 form-control form-control-sm" placeholder="Tell us why you think {{
                          p.author
                        }}'s discussion is offensive" id="report_topic" required style="border-radius: 0px"
                        [(ngModel)]="report_topic" name="report_topic" #c_report_topic="ngModel"></textarea>
                      <div *ngIf="
                          !c_report_topic.valid &&
                          (c_report_topic.dirty || c_report_topic.touched)
                        " class="alert alert-danger">
                        You need a reason to report {{ p.author }}'s discussion
                      </div>
                      <button type="submit" class="btn btn-primary btn-sm mr-3 mt-2"
                        [disabled]="!reportTopicForm.form.valid">
                        Report Discussion
                      </button>
                      <button type="button" class="btn btn-default btn-sm mt-2" (click)="cancel_report()">
                        Changed my mind!
                      </button>
                    </form>
                  </div>
                </section>
              </div>
            </div>
          </div>

          <div class="big-border">
            <div class="row">
              <div class="col-lg-12" *ngIf="topic_comments.length === 0">
                <!-- COMMENTS LIST -->
                <div class="media">
                  <div class="media-body">
                    <span class="text-center mt-2 mb-2">
                      <h5>No comments yet, be the first to comment</h5>
                    </span>
                  </div>
                </div>
              </div>

              <!-- New comment box -->
              <div class="col-lg-12" *ngIf="suspended !== 'true'">
                <div class="media">
                  <img class="mr-3 rounded-circle" src="{{ avatar }}" alt="{{ username }}" />
                  <div class="media-body">
                    <form (ngSubmit)="onSubmit(p.author)" #commentForm="ngForm">
                      <div class="input-group mb-3">
                        <textarea rows="1" class="form-control form-control-sm" placeholder="Write a comment..."
                          id="comment_message" required [(ngModel)]="comment_message" name="comment_message"
                          spellcheck="true" #c_message="ngModel"></textarea>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary" type="submit" [disabled]="!commentForm.valid">
                            <small><b>post</b></small>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div class="col-lg-12">
                <div class="media" *ngFor="let c of topic_comments">
                  <img class="mr-3 rounded-circle" src="{{ c.avatarUrl }}" alt="{{ c.user }}" />
                  <div class="media-body" id="{{ c.uid }}">
                    <div>
                      <div class="main_com">
                        <span class="mt-0 auther_name">
                          <a [routerLink]="['/user-profile', c.user]">{{
                            c.user
                            }}</a>
                          {{ c.comment }}
                        </span>
                      </div>
                      <div class="comment_relly">
                        <ul *ngIf="suspended !== 'true'">
                          <li>
                            {{ dateTime(c.time)
                            }}<span *ngIf="c.edited_at">
                              - edited - {{ dateTime(c.edited_at) }}</span>
                          </li>
                          <li>
                            <a (click)="
                                reply_comment(c.user, c.comment, c.time, c.uid)
                              ">
                              Reply</a>
                          </li>
                          <li>
                            <a title="Likes" class="text-primary comment-content-footer-icon" (click)="
                                like_comment(
                                  c.post,
                                  c.time,
                                  c.comment,
                                  c.user,
                                  c.uid
                                )
                              " *ngIf="get_liked_comments(c.comment, c.uid) === 0"><i class="fa fa-thumbs-up"></i> [{{
                              c.likes
                              }}]</a>
                            <a title="Unlike" class="text-primary comment-content-footer-icon" (click)="
                                unlike_comment(
                                  c.post,
                                  c.time,
                                  c.comment,
                                  c.user,
                                  c.uid
                                )
                              " *ngIf="get_liked_comments(c.comment, c.uid) > 0"><i class="fa fa-thumbs-down"></i> [{{
                              c.likes
                              }}]</a>
                          </li>
                          <li *ngIf="
                              c.user === username ||
                              role === 'admin' ||
                              role === 'moderator'
                            ">
                            <a href="javascript:void(0)" (click)="
                                edit_topic_comment(c.comment, c.uid, c.time)
                              " class="ml-2"><i class="fa fa-pencil"></i> edit</a>
                          </li>
                          <li *ngIf="
                              role === 'admin' ||
                              role === 'moderator' ||
                              c.user === username
                            ">
                            <a href="javascript:void(0)" (click)="delete_comment(c.comment, c.uid, c.time)"
                              class="ml-2 text-danger"><i class="fa fa-trash"></i> delete</a>
                          </li>
                        </ul>
                      </div>

                      <!-- Edit comment -->
                      <div class="media" *ngIf="edit_comment && comment_uid === c.uid">
                        <div class="media-body">
                          <form #editCommentForm="ngForm" (ngSubmit)="save_comment()">
                            <div class="input-group mb-3">
                              <textarea rows="1" class="form-control form-control-sm" placeholder="Edit comment..."
                                autofocus required spellcheck="true" [(ngModel)]="current_comment"
                                name="current_comment" #c_reply="ngModel"></textarea>
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="submit"
                                  [disabled]="editCommentForm.invalid">
                                  <small><b>save</b></small>
                                </button>
                              </div>
                            </div>
                            <button class="btn btn-default btn-sm" type="button" (click)="edit_comment = !edit_comment">
                              cancel
                            </button>
                          </form>
                        </div>
                      </div>

                      <!-- comment reply -->
                      <div class="media" *ngIf="
                          comment_form &&
                          reply_to == c.user &&
                          parent_comment == c.comment &&
                          parent_comment_time == c.time
                        ">
                        <img class="mr-3 rounded-circle" src="{{ avatar }}" alt="{{ username }}" />
                        <div class="media-body" *ngIf="suspended !== 'true'">
                          <form (ngSubmit)="send_reply(p.author, c.user)" #commentReplyForm="ngForm">
                            <div class="input-group mb-3">
                              <textarea rows="1" class="form-control form-control-sm" placeholder="Write a comment..."
                                autofocus aria-label="Write a comment..." aria-describedby="basic-addon2"
                                id="comment_reply_input" required spellcheck="true" [(ngModel)]="comment_reply"
                                name="comment_reply" #c_reply="ngModel"></textarea>
                              <div class="input-group-append">
                                <button class="btn btn-outline-secondary" type="submit"
                                  [disabled]="!commentReplyForm.valid">
                                  <small><b>post</b></small>
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>

                      <!-- sub comments -->
                      <div *ngFor="let cr of comment_replies">
                        <div class="media">
                          <a class="pr-3" [routerLink]="['/user-profile', cr.user]" *ngIf="
                              (c.user == cr.reply_to &&
                                c.comment == cr.parent_comment &&
                                c.time == cr.parent_comment_time) ||
                              (cr.user == cr.reply_to &&
                                cr.root_comment == c.comment &&
                                cr.root_comment_time == c.time) ||
                              (cr.root_comment == c.comment &&
                                cr.root_comment_time == c.time)
                            ">
                            <img src="{{ cr.avatarUrl }}" alt="{{ cr.user }}" class="rounded-circle" />
                          </a>
                          <div class="media-body" *ngIf="
                              (c.user == cr.reply_to &&
                                c.comment == cr.parent_comment &&
                                c.time == cr.parent_comment_time) ||
                              (cr.user == cr.reply_to &&
                                cr.root_comment == c.comment &&
                                cr.root_comment_time == c.time) ||
                              (cr.root_comment == c.comment &&
                                cr.root_comment_time == c.time)
                            " id="{{ cr.uid }}">
                            <div class="main_com">
                              <span class="mt-0 auther_name">
                                <a [routerLink]="['/user-profile', cr.user]">{{
                                  cr.user
                                  }}</a>
                              </span>
                              <span><a [routerLink]="['/user-profile', cr.reply_to]">@{{ cr.reply_to }}</a>
                                {{ cr.comment }}</span>
                            </div>
                            <div class="comment_relly">
                              <ul *ngIf="suspended !== 'true'">
                                <li>
                                  {{ dateTime(cr.time)
                                  }}<span *ngIf="cr.edited_at">
                                    - edited -
                                    {{ dateTime(cr.edited_at) }}</span>
                                </li>
                                <li>
                                  <a (click)="
                                      reply_child_comment(
                                        cr.user,
                                        cr.comment,
                                        cr.time,
                                        c.comment,
                                        c.time,
                                        cr.uid
                                      )
                                    ">
                                    Reply
                                  </a>
                                </li>
                                <li>
                                  <a title="Likes" class="text-dark comment-content-footer-icon" (click)="
                                      like_child_comment(
                                        cr.post,
                                        cr.time,
                                        cr.comment,
                                        cr.user,
                                        cr.uid
                                      )
                                    " *ngIf="
                                      get_liked_comments(cr.comment, cr.uid) ===
                                      0
                                    "><i class="fa fa-thumbs-up"></i> [{{
                                    cr.likes
                                    }}]</a>
                                  <a title="Likes" class="text-primary comment-content-footer-icon" (click)="
                                      unlike_child_comment(
                                        cr.post,
                                        cr.time,
                                        cr.comment,
                                        cr.user,
                                        cr.uid
                                      )
                                    " *ngIf="
                                      get_liked_comments(cr.comment, cr.uid) > 0
                                    "><i class="fa fa-thumbs-down"></i> [{{
                                    cr.likes
                                    }}]</a>
                                </li>
                                <li *ngIf="
                                    cr.user === username ||
                                    role === 'admin' ||
                                    role === 'moderator'
                                  ">
                                  <a href="javascript:void(0)" (click)="
                                      edit_topic_child_comment(
                                        c.uid,
                                        cr.comment,
                                        cr.time,
                                        cr.uid
                                      )
                                    " class="ml-2"><i class="fa fa-pencil"></i> edit</a>
                                </li>
                                <li *ngIf="
                                    role === 'admin' ||
                                    role === 'moderator' ||
                                    cr.user === username
                                  ">
                                  <a href="javascript:void(0)" (click)="
                                      delete_child_comment(
                                        cr.comment,
                                        cr.uid,
                                        cr.time
                                      )
                                    " class="ml-2 text-danger"><i class="fa fa-trash"></i> delete</a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>

                        <!-- Edit comment -->
                        <div class="media" *ngIf="
                            edit_child_comment &&
                            c.uid === parent_comment_uid &&
                            cr.uid === comment_uid
                          ">
                          <img class="mr-3 rounded-circle" src="{{ avatar }}" alt="{{ username }}" />
                          <div class="media-body">
                            <form #editCommentForm="ngForm" (ngSubmit)="save_child_comment(cr.uid)">
                              <div class="input-group mb-3">
                                <textarea rows="1" class="form-control form-control-sm" placeholder="Edit comment..."
                                  autofocus required spellcheck="true" [(ngModel)]="current_comment"
                                  name="current_comment" #c_reply="ngModel"></textarea>
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary" type="submit"
                                    [disabled]="editCommentForm.invalid">
                                    <small><b>save</b></small>
                                  </button>
                                </div>
                              </div>
                              <button class="btn btn-default btn-sm" type="button" (click)="
                                  edit_child_comment = !edit_child_comment
                                ">
                                cancel
                              </button>
                            </form>
                          </div>
                        </div>

                        <!-- New comment box -->
                        <div class="media" *ngIf="
                            comment_form &&
                            reply_to == cr.user &&
                            parent_comment == cr.comment &&
                            parent_comment_time == cr.time &&
                            cr.user == reply_to &&
                            root_comment == c.comment &&
                            root_comment_time == c.time
                          " id="comment-form">
                          <img class="mr-3 rounded-circle" src="{{ avatar }}" alt="{{ username }}" />
                          <div class="media-body" *ngIf="suspended !== 'true'">
                            <form (ngSubmit)="send_child_reply(p.author, c.user)" #commentReplyForm="ngForm">
                              <div class="input-group mb-3">
                                <textarea rows="1" class="form-control form-control-sm" placeholder="Write a comment..."
                                  style="width: 70%" aria-label="Write a comment..." aria-describedby="basic-addon2"
                                  id="comment_reply_input" autofocus spellcheck="true" required
                                  [(ngModel)]="comment_reply" name="comment_reply" #c_reply="ngModel"></textarea>
                                <div class="input-group-append">
                                  <button class="btn btn-outline-secondary" type="submit"
                                    [disabled]="!commentReplyForm.valid">
                                    <small><b>post</b></small>
                                  </button>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="single_blog text-center invisible" *ngIf="!topics[0]?.content" id="not_available">
          <span class="alert alert-danger alert-sm">this discussion may have been deleted</span>
        </div>
      </div>

      <!-- End of col-lg-7-->
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>