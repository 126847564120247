import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Subscription } from "rxjs";
import { ForumService } from "src/app/services/forum.service";
import * as moment from "moment";
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: "app-trending",
  templateUrl: "./trending.component.html",
  styleUrls: ["./trending.component.css"]
})
export class TrendingComponent implements OnInit {
  uid: string;
  avatar: string;
  username: string;
  sub: Subscription;
  trending = [];
  isLoggedIn: boolean;

  constructor(
    private _spinner: NgxSpinnerService,
    private _forum: ForumService
  ) {}

  ngOnInit() {
    this._spinner.show();
    this.username = window.sessionStorage["slu_username"]
      ? window.sessionStorage["slu_username"]
      : window.localStorage["slu_username"];
    this.uid = window.sessionStorage["slu_uid"]
      ? window.sessionStorage["slu_uid"]
      : window.localStorage["slu_uid"];
    this.avatar = window.sessionStorage["slu_avatarUrl"]
      ? window.sessionStorage["slu_avatarUrl"]
      : window.localStorage["slu_avatarUrl"];
    this.sub = this._forum.get_trending().subscribe(t => {
      this.trending = t;
      this._spinner.hide();
    });

    if (
      (window.sessionStorage["shutlips_session_id"] !== undefined &&
        window.sessionStorage["shutlips_username"] !== undefined &&
        window.sessionStorage["slu_username"] !== undefined &&
        window.sessionStorage["slu_avatarUrl"] !== undefined) ||
      (window.localStorage["shutlips_session_id"] !== undefined &&
        window.localStorage["shutlips_username"] !== undefined &&
        window.localStorage["slu_username"] !== undefined &&
        window.localStorage["slu_avatarUrl"] !== undefined)
    ) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }
  }

  dateTime(dt: string): string {
    return moment(dt).fromNow();
  }

  first50(content: string): string {
    if (content.length >= 150) {
      return `${content.substring(0, 150)}...`;
    } else {
      return content;
    }
  }

  category(category: string) {
    return category.split("-").join(" ");
  }
}
