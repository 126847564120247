import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as uuid from 'uuid';
import { finalize } from 'rxjs/operators';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-manage-categories',
  templateUrl: './manage-categories.component.html',
  styleUrls: ['./manage-categories.component.css'],
})
export class ManageCategoriesComponent implements OnInit, OnDestroy {
  sub: Subscription;
  forum_title: string;
  forum_description: string;
  blog_title: string;
  blog_description: string;
  blog_position: number;
  forum_categories = [];
  blog_categories = [];
  edit_forum_cat: boolean = false;
  edit_blog_cat: boolean = false;
  new_forum_cat: boolean = false;
  new_blog_cat: boolean = false;
  forum_category_uid: string;
  blog_category_uid: string;
  new_category: string;
  show_delete_forum_category: boolean;
  show_delete_blog_category: boolean;
  downloadUrl: string;
  role: string;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _router: Router,
    private _afstorage: AngularFireStorage
  ) {}

  ngOnInit() {
    this._auth.isAuthenticated();

    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];
    console.log(this.role);
    if (this.role !== 'admin') {
      this._router.navigate(['/']);
    }

    this.sub = this._forum
      .get_forum_categories()
      .subscribe((fc) => (this.forum_categories = fc));

    this.sub = this._forum
      .get_blog_categories()
      .subscribe((bc) => (this.blog_categories = bc));
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  addForumCategory() {
    if (this.downloadUrl == '') {
      alert('please select an image');
    } else {
      const data = {
        id: this.forum_title
          .trim()
          .replace(/[^a-zA-Z0-9 ]/g, '')
          .split(' ')
          .join('-')
          .toLowerCase(),
        title: this.forum_title.trim(),
        description: this.forum_description.trim(),
        category_image: this.downloadUrl,
      };
      this.new_forum_cat = !this.new_forum_cat;
      this._forum.add_category(data);

      this.forum_title = '';
      this.forum_description = '';
      this.downloadUrl = '';
    }
  }

  addBlogCategory() {
    const data = {
      id: this.blog_title
        .trim()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .split(' ')
        .join('-')
        .toLowerCase(),
      title: this.blog_title.trim(),
      description: this.blog_description.trim(),
      position: this.blog_position,
    };

    this._forum.add_blog_category(data);
    this.new_blog_cat = !this.new_blog_cat;
    this.blog_title = '';
    this.blog_description = '';
  }

  editForumCategory() {
    if (this.downloadUrl === '') {
      alert('please select an image');
    } else {
      const data = {
        id: this.forum_title
          .trim()
          .replace(/[^a-zA-Z0-9 ]/g, '')
          .split(' ')
          .join('-')
          .toLowerCase(),
        title: this.forum_title.trim(),
        description: this.forum_description.trim(),
        category_image: this.downloadUrl,
      };

      this._forum.edit_forum_category(data, this.forum_category_uid);

      this.forum_title = '';
      this.forum_description = '';
      this.forum_category_uid = '';
      this.downloadUrl = '';
      this.edit_forum_cat = !this.edit_forum_cat;
    }
  }

  editBlogCategory() {
    const data = {
      id: this.blog_title
        .trim()
        .replace(/[^a-zA-Z0-9 ]/g, '')
        .split(' ')
        .join('-')
        .toLowerCase(),
      title: this.blog_title.trim(),
      description: this.blog_description.trim(),
      position: this.blog_position,
    };

    this._forum.edit_blog_category(data, this.blog_category_uid);

    this.blog_title = '';
    this.blog_description = '';
    this.blog_category_uid = '';
    this.edit_blog_cat = !this.edit_blog_cat;
  }

  edit_forum_category(fc) {
    this.edit_forum_cat = true;
    this.forum_title = fc.title;
    this.forum_description = fc.description;
    this.forum_category_uid = fc.uid;
    this.downloadUrl = fc.category_image == undefined ? '' : fc.category_image;
  }

  edit_blog_category(bc) {
    this.blog_title = bc.title;
    this.blog_description = bc.description;
    this.edit_blog_cat = true;
    this.blog_category_uid = bc.uid;
    this.blog_position = bc.position;
  }

  onSubmit(id) {
    const data = {
      category: this.new_category.trim(),
    };
    this.show_delete_forum_category = !this.show_delete_forum_category;
    this._forum.move_category(id, data);
  }

  onMoveBC(id) {
    const data = {
      category: this.new_category.trim(),
    };
    this.show_delete_blog_category = !this.show_delete_blog_category;
    this._forum.move_blog_category(id, data);
  }

  async move_forum_category(fc) {
    this.forum_title = fc.title;
    this.forum_description = fc.description;
    this.forum_category_uid = fc.uid;
    this.sub = await this._forum.get_category_topics(fc.id).subscribe((ct) => {
      if (ct.length > 0) {
        this.show_delete_forum_category = !this.show_delete_forum_category;
      } else {
        this.delete_forum_category(fc.id);
      }
    });
  }

  move_blog_category(fc) {
    this.blog_title = fc.title;
    this.blog_description = fc.description;
    this.blog_category_uid = fc.uid;
    this.sub = this._forum.get_blog_category(fc.id).subscribe((ct) => {
      if (ct.length > 0) {
        this.show_delete_blog_category = !this.show_delete_blog_category;
      } else {
        this.delete_blog_category(fc.id);
      }
    });
  }

  delete_forum_category(id) {
    let con = confirm('are you sure you want to delete this category?');
    if (con) {
      this._forum.delete_forum_category(id);
    }
    this.forum_title = '';
    this.forum_description = '';
    this.forum_category_uid = '';
  }

  delete_blog_category(fc) {
    let con = confirm('Are you sure you want to delete this category');
    if (con) {
      this._forum.delete_blog_category(fc);
    }
    this.blog_title = '';
    this.blog_description = '';
    this.blog_category_uid = '';
  }

  cancel() {
    this.edit_blog_cat = false;
    this.edit_forum_cat = false;
    this.new_forum_cat = false;
    this.new_blog_cat = false;
    this.blog_title = '';
    this.forum_title = '';
    this.blog_category_uid = '';
    this.forum_category_uid = '';
    this.blog_description = '';
    this.forum_description = '';
    this.downloadUrl = '';
  }

  upload_category_image(event) {
    const id = uuid.v4();
    const file = event.target.files[0];
    const filePath = `/categories/images/${id}`;
    const ref = this._afstorage.ref(filePath);
    const task = this._afstorage.upload(filePath, file);

    // get notified when the download URL is available
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe((url) => {
            this.downloadUrl = url;
          });
        })
      )
      .subscribe();
  }
}
