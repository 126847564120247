<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
>
  <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <!-- End of col-lg-2-->
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-7 col-12 blog_wrap">
        <div class="mt-3">
          <h3
            class="about-title text-primary"
            style="text-transform: capitalize;"
          >
            Blog: {{ category(id) }}
          </h3>
          <p style="margin-left: 15px;">
            {{
              category_topics.length == 0
                ? "There are no"
                : category_topics.length
            }}
            posts in this category
          </p>
          <button
            class="btn btn-primary btn-sm"
            type="button"
            data-toggle="collapse"
            data-target="#collapseExample"
            aria-expanded="false"
            aria-controls="collapseExample"
            style="margin-left: 15px;"
          >
            Category Description
          </button>
          <hr />
        </div>

        <div class="collapse" id="collapseExample">
          <div class="single_blog">
            <div class="card card-body">
              <small
                *ngFor="let c of categories"
                [innerHtml]="c.description"
              ></small>
            </div>
          </div>
        </div>

        <!-- move category -->
        <div class="single_blog" *ngIf="show_delete_category">
          <article>
            <div class="articles-header">
              <small>
                <b>{{ category(id) }}</b>
                has articles attached to it, you will have to move topics to new
                category
              </small>
            </div>
            <div class="articles-content">
              <form (ngSubmit)="onSubmit()" #moveCategoryForm="ngForm">
                <div class="form-group">
                  <select
                    class="form-control form-control-sm"
                    id="new_category"
                    required
                    [(ngModel)]="new_category"
                    name="tcategory"
                    #tcategory="ngModel"
                  >
                    <option selected>Select new category *</option>
                    <option *ngFor="let c of blog_categories" [value]="c.title">
                      {{ c.title }}
                    </option>
                  </select>
                  <div
                    [hidden]="tcategory.valid || tcategory.pristine"
                    class="invalid-feedback"
                  >
                    Category is required
                  </div>
                </div>
                <div class="form-group" style="margin-top: 0.5em;">
                  <button
                    [disabled]="!moveCategoryForm.form.valid"
                    type="submit"
                    class="btn btn-primary btn-sm"
                  >
                    Move Discussions
                  </button>
                </div>
              </form>
            </div>
            <div class="articles-footer"></div>
          </article>
        </div>

        <!-- if empty -->
        <section class="articles" *ngIf="category_topics.length == 0">
          <article>
            <div class="articles-header">
              <button
                title="Move Category"
                (click)="move_category()"
                *ngIf="category_topics.length > 0 && admin"
                class="btn btn-danger btn-sm"
              >
                Delete
                {{ id }}
                category
              </button>
            </div>
            <div class="articles-content" *ngIf="admin">
              <small>
                There are no blog posts under {{ category(id) }} category at the
                moment, got anything to add?
                <a
                  [routerLink]="['/add-post']"
                  title="Add Post"
                  (click)="set_category()"
                >
                  Yes, i do
                </a>
              </small>
            </div>
          </article>
        </section>

        <div
          class="single_blog text-center"
          *ngIf="(category_topics | filterBy: userFilter).length === 0"
        >
          No post found
        </div>

        <div class="single_blog pt-0 pr-0">
          <div
            class="row pt-0 pr-0"
            *ngFor="
              let t of category_topics
                | filterBy: userFilter
                | paginate: { itemsPerPage: 15, currentPage: p }
            "
          >
            <div class="col-lg-4 col-sm-6">
              <small
                class="bg-primary featured d-block d-sm-block d-md-block d-lg-none d-xl-none"
                *ngIf="t.featured"
              >
                <i class="fa fa-bookmark"></i> featured
              </small>
              <div class="blg-img">
                <img
                  src="{{ t.imgUrl }}"
                  alt="{{ t.title }}"
                  class="img-fluid mt-3"
                />
              </div>
            </div>
            <div class="col-lg-8 col-sm-6 pt-0 pr-0">
              <small
                class="bg-primary featured d-none d-sm-none d-md-none d-lg-block d-xl-block"
                *ngIf="t.featured"
              >
                <i class="fa fa-bookmark"></i> featured
              </small>
              <div
                class="blog-header-2 pt-3 pr-3"
                [ngClass]="{ 'mt-4': t.featured }"
              >
                <h6>
                  <a
                    title="{{ t.title }}"
                    [routerLink]="['/post', t.id]"
                    (click)="set_page_number(p, t.id)"
                  >
                    {{ t.title }}
                  </a>
                </h6>
              </div>
              <div class="author_name_date pr-3">
                <small>
                  <span>
                    Posted by Nonye in
                    <a
                      title="{{ t.category }}"
                      [routerLink]="['/blog-category', t.category]"
                      style="color: #4183c4; text-transform: capitalize;"
                    >
                      {{ category(t.category) }}
                    </a>
                  </span>
                </small>
              </div>
              <div class="blog-paragrap pr-3">
                <p [innerHtml]="first50(t.content)"></p>
              </div>
            </div>
          </div>
          <div class="text-center">
            <pagination-controls
              (pageChange)="p = $event"
            ></pagination-controls>
          </div>
        </div>
      </div>
      <!-- End of col-lg-7-->
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
