<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-7 hm-3-bg">
        <div class="bg-white hm-3-p">
          <div class="row cat-h3-top" style="border-bottom: 5px solid #f2f2f2">
            <div class="col-md-3">
              <img
                src="{{ avatar }}"
                alt="{{ username }}"
                class="img-fluid img-circle"
                style="max-width: 80px; max-height: 70px; margin-bottom: 1em"
              />
            </div>
            <div class="col-lg-9 bl_header mt-3" *ngFor="let u of users">
              <h4>{{ u.username }}</h4>
              <small>
                <b>
                  Shutlips {{ u.role }} | Joined {{ dateTime(u.joined) }} |
                  {{
                    user_topics.length > 0
                      ? user_topics.length + " Articles"
                      : "No Articles"
                  }}
                  |
                  <span *ngIf="blocked_users.length == 0">
                    No Blocked Users
                  </span>
                  <span *ngIf="blocked_users.length > 0">
                    <a [routerLink]="['/blocked-users']">
                      {{ blocked_users.length }} Blocked Users
                    </a>
                  </span>
                </b>
              </small>
            </div>
          </div>

          <div class="alert alert-danger alert-sm" *ngIf="suspended === 'true'">
            Your account has been suspended, please contact the moderator at
            moderator@shutlips.com
          </div>

          <div
            class="content"
            style="margin-top: 2em"
            *ngIf="suspended === 'false'"
          >
            <nav>
              <div
                class="nav nav-tabs"
                id="nav-tab"
                role="tablist"
                style="margin-bottom: 3em"
              >
                <a
                  class="nav-item nav-link active"
                  id="nav-articles-tab"
                  data-toggle="tab"
                  href="#nav-articles"
                  role="tab"
                  aria-controls="nav-articles"
                  aria-selected="true"
                >
                  Articles
                </a>
                <a
                  class="nav-item nav-link"
                  id="nav-favorites-tab"
                  data-toggle="tab"
                  href="#nav-favorites"
                  role="tab"
                  aria-controls="nav-favorites"
                  aria-selected="false"
                >
                  Favorites Topics
                </a>
                <a
                  class="nav-item nav-link"
                  id="nav-blocked-users-tab"
                  data-toggle="tab"
                  href="#nav-blocked-users"
                  role="tab"
                  aria-controls="nav-blocked-users"
                  aria-selected="false"
                >
                  Blocked Users
                </a>
              </div>
            </nav>
            <div class="tab-content" id="nav-tabContent">
              <div
                class="tab-pane fade show active"
                id="nav-articles"
                role="tabpanel"
                aria-labelledby="nav-articles-tab"
              >
                <span *ngIf="user_topics.length == 0">
                  <strong><em>You don't have any articles yet.</em></strong>
                </span>

                <div *ngFor="let ct of user_topics" style="margin-bottom: 2em">
                  <img
                    src="{{ ct.author_avatar }}"
                    alt="{{ ct.author }}"
                    width="80px"
                    height="80px"
                    class="img-fluid"
                  />
                  <div class="detail">
                    <h6 class="text-capitalize name">
                      <a [routerLink]="['/topic', ct.id]">{{ ct.title }}</a>
                    </h6>
                    <small>
                      <span>
                        By
                        <a
                          [routerLink]="['/user-profile', ct.author]"
                          style="color: #c154c1"
                        >
                          {{ ct.author }}
                        </a>
                        in
                        <a
                          title="{{ ct.category }}"
                          [routerLink]="['/categories', ct.category]"
                          style="color: #c154c1; text-transform: capitalize"
                        >
                          {{ category(ct.category) }}
                        </a>
                        - {{ dateTime(ct.timeCreated) }}
                        <span *ngIf="ct.edited">
                          | edited - {{ dateTime(ct.edited_time) }}
                        </span>
                      </span>
                    </small>
                    <br />
                    <small class="text-capitalize mt-2">
                      {{ first30(ct.content) }}
                    </small>
                    <ul class="list-inline mt-2 text-capitalize">
                      <li class="list-inline-item">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        likes [{{ ct.likes != 0 ? ct.likes : 0 }}]
                      </li>
                      <li class="list-inline-item">
                        <i class="fa fa-comments" aria-hidden="true"></i>
                        comments [{{ ct.comments != 0 ? ct.comments : 0 }}]
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-favorites"
                role="tabpanel"
                aria-labelledby="nav-favorites-tab"
              >
                <span *ngIf="favorite_topics.length == 0">
                  <strong><em>You have no favorite topics yet.</em></strong>
                </span>

                <div
                  *ngFor="let ct of favorite_topics"
                  style="margin-bottom: 2em"
                >
                  <img
                    src="{{ ct.post.author_avatar }}"
                    alt="{{ ct.post.author }}"
                    width="80px"
                    height="80px"
                    class="img-fluid"
                  />
                  <div class="detail">
                    <h6 class="text-capitalize name">
                      <a [routerLink]="['/topic', ct.post.id]">
                        {{ ct.post.title }}
                      </a>
                    </h6>
                    <small>
                      <span>
                        By
                        <a
                          [routerLink]="['/user-profile', ct.post.author]"
                          style="color: #c154c1"
                        >
                          {{ ct.post.author }}
                        </a>
                        in
                        <a
                          title="{{ ct.category }}"
                          [routerLink]="['/categories', ct.post.category]"
                          style="color: #c154c1; text-transform: capitalize"
                        >
                          {{ category(ct.post.category) }}
                        </a>
                        - {{ dateTime(ct.post.timeCreated) }}
                        <span *ngIf="ct.edited">
                          | edited - {{ dateTime(ct.post.edited_time) }}
                        </span>
                      </span>
                    </small>
                    <br />
                    <small class="text-capitalize mt-2">
                      {{ first30(ct.post.content) }}
                    </small>
                    <ul
                      class="list-inline mt-2 text-capitalize"
                      *ngIf="suspended === 'false'"
                    >
                      <li class="list-inline-item">
                        <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                        likes [{{ ct.post.likes != 0 ? ct.post.likes : 0 }}]
                      </li>
                      <li class="list-inline-item">
                        <i class="fa fa-comments" aria-hidden="true"></i>
                        comments [{{
                          ct.post.comments != 0 ? ct.post.comments : 0
                        }}]
                      </li>
                      <li
                        class="list-inline-item text-danger"
                        (click)="remove_from_favorites(ct.post.id)"
                      >
                        <i class="fa fa-trash"></i>
                        Delete
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="nav-blocked-users"
                role="tabpanel"
                aria-labelledby="nav-blocked-users-tab"
              >
                <span *ngIf="blocked_users.length == 0">
                  <strong><em>You have no blocked users.</em></strong>
                </span>

                <div
                  *ngFor="let ct of blocked_users"
                  style="margin-bottom: 2em"
                >
                  <img
                    src="{{ ct.avatarUrl }}"
                    alt="{{ ct.username }}"
                    width="80px"
                    height="80px"
                    class="img-fluid"
                  />
                  <div class="detail">
                    <h6 class="text-capitalize name">
                      <a [routerLink]="['/user-profile', ct.username]">
                        {{ ct.username }}
                      </a>
                    </h6>
                    <small class="text-capitalize mt-2">
                      Joined {{ dateTime(ct.joined) }}
                    </small>
                    <ul
                      class="list-inline mt-2 text-capitalize"
                      *ngIf="suspended === 'false'"
                    >
                      <li
                        class="list-inline-item text-danger"
                        (click)="unblock_user(ct)"
                      >
                        <i class="fa fa-shield"></i>
                        Unblock User
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--END OF CONTENT-->
        </div>
      </div>
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
