<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-12">
            <img src="./assets/images/about-img.png" alt="about-banner" class="img-fluid" />
          </div>
          <div class="col-lg-12 about-title">
            <h6>
              About Shutlips
            </h6>
          </div>
          <div class="col-lg-8 about-content" *ngIf="edit">
            <form (ngSubmit)="edit_page()" #aboutForm="ngForm">
              <h4 class="mb-2">Edit About Page</h4>
              <div class="form-group">
                <angular-editor [(ngModel)]="content" [ngModelOptions]="{ standalone: true }" [config]="config">
                </angular-editor>
              </div>
              <div class="form-group" style="margin-top: 0.5rem;">
                <button [disabled]="!aboutForm.form.valid" type="submit" class="btn btn-sm btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-12" style="font: 14px ariel;">
            <div class="accordion mt-3" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                    aria-controls="collapseOne">
                    <h5 class="mb-0">
                      Who are we?
                      <span style="float: right;"><i class="fa fa-plus text-primary"></i></span>
                    </h5>
                  </a>
                </div>
                <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                  <div class="card-body">
                    At its simplest, Shutlips is really just a good
                    old-fashioned discussion forum. It’s a place where everyday
                    people go to genuinely connect on shared pain, grief, shame,
                    struggles, strife and harsh realities of life. Our platform
                    offers the room to discuss social issues, share stories and
                    opinions, divulge secrets, ask questions and get answers.
                    <br /><br />
                    At Shutlips, our community is known for its cut-throat
                    advice and brutal honesty. Topics are organized into threads
                    that anyone can initiate, and registered community members
                    can also submit content on various topics in the form of
                    text posts and comments. <br /><br />
                    Sometimes, we can’t find someone trustworthy to confide in -
                    we are scared or too embarrassed to put a name or face to
                    real issues, so we suffer in the silence of depression,
                    guilt, shame, and loneliness. We are here to change all of
                    that! The genius behind Shutlips is that it offers users the
                    choice to participate anonymously without risk of exposure.
                    Again, because honesty is prized so much in this forum,
                    you&#39;re likely to find people who are willing to help all
                    from first-hand experience.

                    <h5 class="mb-2 mt-3 text-primary">
                      Why you should register?
                    </h5>
                    Whether you are looking for a place to express your
                    thoughts, share a deep secret, have someone listen and
                    respond to you or even just have a specific question, you
                    are sure to find interesting first-hand experiences on the
                    Shutlips forum. It&#39;s worth getting on board to see if
                    other people are in the same boat as you are.
                  </div>
                </div>

                <div class="card-header" id="headingTwo">
                  <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                    aria-controls="collapseTwo">
                    <h5 class="mb-0">
                      Our Mission
                      <span style="float: right;"><i class="fa fa-plus text-primary"></i></span>
                    </h5>
                  </a>
                </div>
                <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                  <div class="card-body">
                    We are determined to build an interactive community that
                    will encourage and inspire YOU through this thing called
                    LIFE. If anyone finds hope, inspiration, solidarity and even
                    humor on our website, we have done our job. <br /><br />
                    Basically, we are here to let you know by reminding you
                    daily that you are not alone!!! We hope to achieve this by
                    motivating and inspiring users through stories and
                    experiences shared on our forum. We want users to be able to
                    connect with others who have been through such issues, or
                    those currently going through those issues and
                    unfortunately, on the verge.
                  </div>
                </div>

                <div class="card-header" id="headingThree">
                  <a href="javascript:void(0)" data-toggle="collapse" data-target="#collapseThree" aria-expanded="true"
                    aria-controls="collapseThree">
                    <h5 class="mb-0">
                      Our Vision
                      <span style="float: right;"><i class="fa fa-plus text-primary"></i></span>
                    </h5>
                  </a>
                </div>
                <div id="collapseThree" class="collapse" aria-labelledby="headingThree" data-parent="#accordionExample">
                  <div class="card-body">
                    Our main vision is to bridge the mental health gap in
                    Nigeria and Africa as a whole through our platform, giving
                    people an outlet to talk about issues that really matter. As
                    the website grows, we hope to partner with NGOs and
                    Foundations that align with our values to get survivors the
                    professional help they need psychologically and mentally. We
                    also hope to partner with not just Clinical Psychologists
                    but also with Health and Sex therapists to help our users on
                    a more professional level. <br /><br />
                    We will keep updating our Website once those services are
                    made available.
                  </div>
                </div>
              </div>
            </div>
            <h5 class="mt-5 mb-2 text-primary">
              Letter from our FOUNDER AND CEO Nonye Emenike
            </h5>
            We are all on a journey. A journey called LIFE. <br /><br />
            Life is beautiful but also dynamic and challenging. Everyone is
            either fighting a battle or has fought battles that in one way or
            the other connects us all. We are all experiencing life in different
            dimensions, paces and phases. No single person is at the same pace
            which is what makes our individual journey so unique. <br /><br />
            We all have gone through a situation and have survived it for a sole
            purpose. The sole purpose of helping someone else who is either
            currently going through a similar situation or someone who is about
            to go through the same issue. All our lives are interconnected. We
            were not designed, built or created to go through life alone. Our
            individual stories are our connection to one another. These
            individual stories when shared together connects us as a community,
            a society and as a global force in whole.
            <br /><br />
            The idea to build the Shutlips community stems from a personal
            experience. In September 2014, after I graduated from University of
            Hull with an MSc. in Analytical and Forensic Chemistry and was so
            optimistic about running the word like Beyoncé said I could, I was
            so sure I would land a great opportunity as I not only had my
            degrees going for me, I had my good looks and rather charming
            personality (let’s just say I am no shrinking violet). <br /><br />
            Unfortunately, 2+ years later, I was still in the job market! I did
            eventually get a job in 2017… but in the time before that, LIFE
            happened to me. By March 2015, I had been diagnosed with fibroids
            that had me considering a hysterectomy despite being only 29 years
            old. And in December 2015, I was raped by armed robbers who broke
            into my house while I was asleep. I was raped in my own bed!
            <br /><br />
            I don’t believe words are enough to describe the empty shell that I
            became after these incidents - in addition to dealing with the
            emotional and psychological trauma of being raped, there was the
            physical ordeal that came with the fibroids, the torture of having
            to be on anti- retroviral drugs (to prevent contracting HIV from the
            rapists), and the fact that I couldn’t support myself financially,
            at all. In all areas of my life, nothing was working, it was as if
            the world had suddenly stopped. It was so bad that I couldn’t even
            find succor in my faith as I blamed God for everything that happened
            back to back. I mean, he let all those things happen to me
            undeservedly…right? <br /><br />
            Fortunately, God was redirecting me. I eventually got on the path of
            healing and survival, putting one foot in front of the other each
            day, so much that I started to experience self- discovery and
            self-awareness. I started to feel the need to realize my PURPOSE so
            intensely. Also, the need to go on my MISSION and fulfill my DESTINY
            was so strong! I knew I had to reach out somehow to the world around
            me and connect with the realness - the truth that we do not talk
            about because we don&#39;t trust anyone with our deepest fears,
            realities and secrets. Our environment is not one where we can be
            vulnerable enough to genuinely talk about certain issues that
            matter, issues that affect us all without being stigmatized. I came
            to the realization that I could join the fight to change all those
            limitations with Shutlips. <br /><br />
            In my 3 decades here on earth I have known shame, abuse,
            blame, stigmatization, victimization, brokenness, rejections and a
            lot of pain...and I know there are other people out there who can
            relate, people who are going through the same or even worse. Are you
            the one? Do you know anyone who fits the mould? I hope my platform
            gives you a stage to tell your story. I strongly believe that
            together we can help others heal in the solidarity that they are not
            alone. I truly believe this, because I know that if I had one person
            who understood exactly what I was going through, let alone an entire
            community of like-minded people, perhaps my healing wouldn’t have
            been so uncertain and lonely. <br /><br />
            Join me. Let your pain be someone’s gain. Be a SERUM to someone’s
            VENOM. <br /><br />
            <img src="./assets/images/nonye-image.jpg" alt="Nonye-shutlips.com" class="img-fluid mb-2" />
            <br /><br />
            Welcome to Shutlips! <br /><br />
            XO
            <br /><br />
            <b>PS:</b> Besides reading, commenting and sharing in other people’s
            stories and experiences, if you ever need more personal advice and
            would love to hear from me, Please send me an email on
            <a href="mailto:secrets@shutlips.com">secrets@shutlips.com</a> and I
            will respond to all your questions as best as I can.
          </div>

          <div class="col-lg-4 col-12" id="adsense">
            <!-- <img
              src="./assets/images/add-2.png"
              alt="addsence"
              class="img-fluid"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>