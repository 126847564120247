<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-7 hm-3-bg">
        <div class="col-lg-12 about-title">
          <h6>Shutlips Archive</h6>
        </div>
        <div class="accordion" id="accordionExample">
          <div class="card">
            <div class="card-header" id="deleted_topics">
              <a href="javascript:void(0)" data-toggle="collapse" data-target="#all_deleted_topics" aria-expanded="true"
                aria-controls="all_deleted_topics">
                <h5 class="mb-0">
                  Deleted Discussions - [{{ deleted_topics?.length }}]
                  <span style="float: right"><i class="fa fa-plus text-primary"></i></span>
                </h5>
              </a>
            </div>

            <div id="all_deleted_topics" class="collapse" aria-labelledby="deleted_topics"
              data-parent="#accordionExample">
              <div class="card-body">
                <div class="row mb-2 pt-0 pr-0" *ngFor="
                    let t of deleted_topics
                      | paginate: { itemsPerPage: 15, currentPage: dt }
                  " [ngClass]="{ question: t.featured }">
                  <div class="col-lg-2 pt-0 pr-0" id="{{ t.id }}">
                    <div class="blg-img pt-2">
                      <img src="{{ t.author_avatar }}" alt="{{ t.author }}" class="img-fluid" width="80px"
                        height="70px" />
                    </div>
                  </div>
                  <div class="col-lg-10 pt-0 pr-0">
                    <div class="blog-header-2 pt-3" [ngClass]="{ 'mt-4': t.featured }">
                      <h6>
                        <a title="{{ t.title }}" [routerLink]="['/archive', t.id]">
                          {{ t.title }}
                        </a>
                      </h6>
                    </div>
                    <div class="author_name_date">
                      <small>
                        <span>
                          By
                          <a [routerLink]="['/user-profile', t.author]" style="color: #c154c1">
                            {{ t.author }}
                          </a>
                          in
                          <a title="{{ t.category }}" [routerLink]="['/categories', t.category]"
                            style="color: #c154c1; text-transform: capitalize">
                            {{ category(t.category) }}
                          </a>
                          <span *ngIf="t.edited">
                            | edited - {{ dateTime(t.edited_time) }}
                          </span>
                        </span>
                      </small>
                    </div>
                    <div class="blog-paragrap pr-2">
                      <span [innerHtml]="first50(t.content)"></span>
                      <ul class="list-inline mt-2 text-capitalize">
                        <li class="list-inline-item">
                          <i class="fa fa-thumbs-up"></i>
                          {{ t.likes > 0 ? t.likes : 'No' }} likes
                        </li>
                        <li class="list-inline-item">
                          <i class="fa fa-comments"></i>
                          {{ t.comments > 0 ? t.comments : 'No' }} comments
                        </li>
                        <li class="list-inline-item pointer" (click)="restore_topic(t)">
                          <i class="fa fa-check"></i>
                          Restore Discussion
                        </li>
                        <li class="list-inline-item pointer" (click)="delete_topic(t)">
                          <i class="fa fa-trash"></i>
                          Delete Discussion
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <pagination-controls (pageChange)="dt = $event"></pagination-controls>
              </div>
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-header" id="deleted_posts">
              <a href="javascript:void(0)" data-toggle="collapse" data-target="#all_deleted_posts" aria-expanded="true"
                aria-controls="all_deleted_posts">
                <h5 class="mb-0">
                  Deleted Posts - [{{ deleted_posts?.length }}]
                  <span style="float: right"><i class="fa fa-plus text-primary"></i></span>
                </h5>
              </a>
            </div>

            <div id="all_deleted_posts" class="collapse" aria-labelledby="deleted_posts"
              data-parent="#accordionExample">
              <div class="card-body">
                <div class="single_blog pt-0 pr-0" *ngFor="
                    let post of deleted_posts
                      | paginate: { itemsPerPage: 15, currentPage: dp };
                    let i = index
                  ">
                  <div class="row pt-0 pr-0">
                    <div class="col-lg-4 col-sm-6">
                      <small class="bg-primary featured d-block d-sm-block d-md-block d-lg-none d-xl-none"
                        *ngIf="post.featured">
                        <i class="fa fa-bookmark"></i> featured
                      </small>
                      <div class="blg-img">
                        <img src="{{ post.imgUrl }}" alt="{{ post.title }}" class="img-fluid mt-3" />
                      </div>
                    </div>
                    <div class="col-lg-8 col-sm-6 pt-0 pr-0">
                      <small class="bg-primary featured d-none d-sm-none d-md-none d-lg-block d-xl-block"
                        *ngIf="post.featured">
                        <i class="fa fa-bookmark"></i> featured
                      </small>
                      <div class="blog-header-2 pt-3 pr-3" [ngClass]="{ 'mt-4': post.featured }">
                        <h6>
                          <a title="{{ post.title }}" [routerLink]="['/archive-post', post.id]">
                            {{ post.title }}
                          </a>
                        </h6>
                      </div>
                      <div class="author_name_date pr-3">
                        <small>
                          <span>
                            Posted by Nonye in
                            <a title="{{ post.category }}" [routerLink]="['/blog-category', post.category]"
                              style="color: #4183c4; text-transform: capitalize">
                              {{ category(post.category) }}
                            </a>
                          </span>
                        </small>
                      </div>
                      <div class="blog-paragrap pr-3">
                        <p [innerHtml]="first50(post.content)"></p>
                        <ul class="list-inline text-capitalize mt-2">
                          <li class="list-inline-item pointer" (click)="restore_post(post)">
                            <i class="fa fa-check"></i>
                            Restore Post
                          </li>
                          <li class="list-inline-item pointer" (click)="delete_post(post.uid)">
                            <i class="fa fa-trash"></i>
                            Delete Post
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination-controls (pageChange)="dp = $event"></pagination-controls>
              </div>
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-header" id="deleted_comments">
              <a href="javascript:void(0)" data-toggle="collapse" data-target="#all_deleted_comments"
                aria-expanded="true" aria-controls="all_deleted_comments">
                <h5 class="mb-0">
                  Deleted Comments - [{{ deleted_comments?.length }}]
                  <span style="float: right"><i class="fa fa-plus text-primary"></i></span>
                </h5>
              </a>
            </div>

            <div id="all_deleted_comments" class="collapse" aria-labelledby="deleted_posts"
              data-parent="#accordionExample">
              <div class="card-body">
                <div class="media" *ngFor="
                    let c of deleted_comments
                      | paginate: { itemsPerPage: 15, currentPage: dtc }
                  ">
                  <img class="mr-3 rounded-circle" src="{{ c.avatarUrl }}" alt="{{ c.user }}" />
                  <div class="media-body" id="{{ c.uid }}">
                    <div>
                      <div class="main_com">
                        Discussion:
                        <a class="pr-3" [routerLink]="['/topic', c.post]">Visit Discussion</a>
                        <br />
                        <span class="mt-0 auther_name">
                          <a [routerLink]="['/user-profile', c.user]">{{
                            c.user
                          }}</a>
                          {{ c.comment }}
                        </span>
                      </div>
                      <div class="comment_relly">
                        <ul>
                          <li>
                            {{ dateTime(c.time)
                            }}<span *ngIf="c.edited_at">
                              - edited - {{ dateTime(c.edited_at) }}</span>
                          </li>
                          <li class="list-inline-item pointer" (click)="restore_comment(c)">
                            <i class="fa fa-check"></i>
                            Restore Comment
                          </li>
                          <li class="list-inline-item pointer" (click)="delete_comment(c.uid)">
                            <i class="fa fa-trash"></i>
                            Delete Comment
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination-controls (pageChange)="dtc = $event"></pagination-controls>
              </div>
            </div>
          </div>

          <div class="card mt-3">
            <div class="card-header" id="deleted_comment_replies">
              <a href="javascript:void(0)" data-toggle="collapse" data-target="#all_deleted_comment_replies"
                aria-expanded="true" aria-controls="all_deleted_comments">
                <h5 class="mb-0">
                  Deleted Comment Replies - [{{
                    deleted_comment_replies?.length
                  }}]
                  <span style="float: right"><i class="fa fa-plus text-primary"></i></span>
                </h5>
              </a>
            </div>

            <div id="all_deleted_comment_replies" class="collapse" aria-labelledby="deleted_comment_replies"
              data-parent="#accordionExample">
              <div class="card-body">
                <!-- sub comments -->
                <div *ngFor="
                    let cr of deleted_comment_replies
                      | paginate: { itemsPerPage: 15, currentPage: dtcr }
                  ">
                  <div class="media">
                    <a class="pr-3" [routerLink]="['/user-profile', cr.user]">
                      <img src="{{ cr.avatarUrl }}" alt="{{ cr.user }}" class="rounded-circle" />
                    </a>
                    <div class="media-body">
                      <div class="main_com">
                        Discussion:
                        <a class="pr-3" [routerLink]="['/topic', cr.post]">Visit Discussion</a>
                        <br />
                        <span class="mt-0 auther_name">
                          <a [routerLink]="['/user-profile', cr.user]">{{
                            cr.user
                          }}</a>
                        </span>
                        <span><a [routerLink]="['/user-profile', cr.reply_to]">@{{ cr.reply_to }}</a>
                          {{ cr.comment }}</span>
                      </div>
                      <div class="comment_relly">
                        <ul>
                          <li>
                            {{ dateTime(cr.time)
                            }}<span *ngIf="cr.edited_at">
                              - edited -
                              {{ dateTime(cr.edited_at) }}</span>
                          </li>
                          <li class="list-inline-item pointer" (click)="restore_comment_reply(cr)">
                            <i class="fa fa-check"></i>
                            Restore Comment Reply
                          </li>
                          <li class="list-inline-item pointer" (click)="delete_comment_reply(cr.uid)">
                            <i class="fa fa-trash"></i>
                            Delete Comment Reply
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <pagination-controls (pageChange)="dtcr = $event"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>