import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { FilterPipe } from 'ngx-filter-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { SwPush } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-topics',
  templateUrl: './topics.component.html',
  styleUrls: ['./topics.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TopicsComponent implements OnInit, OnDestroy {
  sub: Subscription;
  username: string;
  role: string;
  avatar: string;
  topics = [];
  blocked_users = [];
  blocked = false;
  suspended: string;
  p: number = 1;
  search: string = '';
  userFilter: any;
  intro: boolean;
  modalbutton: any;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private filterPipe: FilterPipe,
    private _spinner: NgxSpinnerService,
    private _sw_push: SwPush,
    private _router: Router
  ) {
    this._auth.isAuthenticated();

    this._forum.changeMessage('');

    this.p =
      window.sessionStorage['prev-page'] !== undefined
        ? window.sessionStorage['prev-page']
        : this.p;
  }

  ngOnInit() {
    if (this._auth.isAuthenticated()) {
      this._spinner.show();
      this.username = window.sessionStorage['slu_username']
        ? window.sessionStorage['slu_username']
        : window.localStorage['slu_username'];
      this.role = window.sessionStorage['slu_role']
        ? window.sessionStorage['slu_role']
        : window.localStorage['slu_role'];
      this.avatar = window.sessionStorage['slu_avatarUrl']
        ? window.sessionStorage['slu_avatarUrl']
        : window.localStorage['slu_avatarUrl'];
      this.suspended = window.sessionStorage['slu_suspended']
        ? window.sessionStorage['slu_suspended']
        : window.localStorage['slu_suspended'];
      if (window.sessionStorage['new_user'] === 'true') {
        this.intro = true;
      } else {
        this.intro = false;
      }

      this.sub = this._forum.get_topics().subscribe((topics) => {
        if (topics) {
          this.topics = topics;
          this._forum.get_blocked_users(this.username).subscribe((bu) => {
            if (bu) {
              this.blocked_users = bu;
              for (let i = 0; i < topics.length; i++) {
                for (let j = 0; j < bu.length; j++) {
                  let count = 1;
                  var index = topics.indexOf(topics[i]);
                  if (
                    this.blocked_users[j].username === this.topics[i].author
                  ) {
                    count++;
                    this.topics.splice(index, count);
                  }
                }
              }
            }
          });

          this._spinner.hide();

          this.filterPipe.transform(this.topics, { title: '' });
        }
      });

      this.sub = this._forum.currentSearch.subscribe((message) => {
        this.userFilter = { title: message };
      });

      setTimeout(() => {
        if (
          window.sessionStorage['prev-page'] !== (null || undefined) &&
          window.sessionStorage['scroll-to'] !== (null || undefined)
        ) {
          let element = document.getElementById(
            window.sessionStorage['scroll-to']
          );
          element.scrollIntoView({
            behavior: 'smooth',
            inline: 'nearest',
          });
        }
      }, 2000);

      // prompt to subscribe after 5 seconds
      setTimeout(() => {
        if (
          Notification.permission !== ('granted' || 'denied') &&
          !window.location.href.includes('/auth') &&
          this._sw_push.isEnabled
        ) {
          document.getElementById('modalbutton').click();
        }
      }, 3000);

      // failsafe
      this._spinner.hide();
    } else {
      // failsafe;
      this._spinner.hide();
      this._router.navigate(['/auth/sign-in']);
    }
  }

  ngOnDestroy() {
    // this.sub.unsubscribe();
  }

  subscribe = () => {
    document.getElementById('closemodal').click();
    this._spinner.show();
    try {
      if (Notification.permission !== 'granted' && this._sw_push.isEnabled) {
        this._sw_push
          .requestSubscription({
            serverPublicKey: environment.vapid_public_key,
          })
          .then((sub: any) => {
            this._auth.save_vapid_to_user(this.username, this.role, sub);
            this._spinner.hide();
          })
          .catch((err) => {
            console.error('Could not subscribe to notifications', err);
            this._spinner.hide();
          });
      }
    } catch (error) {
      console.log("couldn't subscribe for user notification: ", error);
      this._spinner.hide();
    }
  };

  dateTime(dt: string): string {
    return moment(dt).fromNow();
  }

  category(category: string) {
    return category.split('-').join(' ');
  }

  first50(content: string): string {
    if (content.length >= 150) {
      return `${content.substring(0, 150)}...`;
    } else {
      return content;
    }
  }

  set_page_number(page: number, id: string) {
    window.sessionStorage['prev-page'] = page;
    window.sessionStorage['scroll-to'] = id;
  }

  close_intro() {
    this.intro = !this.intro;
    window.sessionStorage.removeItem('new_user');
  }
}
