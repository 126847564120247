<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <!-- End of col-lg-2-->
      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-12">
            <img
              src="./assets/images/1-1.jpg"
              alt="about-banner"
              class="img-fluid"
            />
          </div>
          <div class="col-lg-12 about-title">
            <h6>
              What's Trending - Top 10
            </h6>
          </div>
          <div class="col-lg-8">
            <p>
              Checkout the hottest and most talked about topics on Shutlips this
              week.
            </p>

            <div class="list-group">
              <div
                class="list-group-item list-group-item-action flex-column align-items-start"
                *ngFor="let t of trending"
              >
                <div class="row">
                  <div class="col-md-2">
                    <img
                      src="{{ t.author_avatar }}"
                      alt="{{ t.author }}"
                      class="img-fluid img-circle"
                      style="max-width: 80px; max-height: 70px; margin-bottom: 1em"
                    />
                  </div>
                  <div class="col-md-10">
                    <div class="d-flex w-100 justify-content-between">
                      <h6 class="mb-1">
                        <a
                          title="{{ t.title }}"
                          [routerLink]="['/topic', t.id]"
                        >
                          {{ t.title }}
                        </a>
                      </h6>
                    </div>
                    <small
                      class="mb-1"
                      style="color: #000"
                      [innerHTML]="first50(t.content)"
                    ></small>
                    <small>
                      by
                      <a
                        [routerLink]="['/user-profile', t.author]"
                        style="color: #c154c1"
                      >
                        {{ t.author }}
                      </a>
                      in
                      <a
                        title="{{ t.category }}"
                        [routerLink]="['/categories', t.category]"
                        style="color: #c154c1; text-transform: capitalize"
                      >
                        {{ category(t.category) }}
                      </a>
                    </small>
                    <ul class="list-inline mt-2 text-capitalize">
                      <li class="list-inline-item">
                        <i class="fa fa-thumbs-up"></i>
                        {{ t.likes > 0 ? t.likes : "No" }} likes
                      </li>
                      <li class="list-inline-item">
                        <i class="fa fa-comments"></i>
                        {{ t.comments > 0 ? t.comments : "No" }} comments
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <p>
              Start a conversation anonymously on shutlips today!
            </p>
          </div>
          <div class="col-lg-4 col-12">
            <div class="addsbygoogle"></div>
            <!-- <img
              src="./assets/images/add-2.png"
              alt="addsence"
              class="img-fluid"
            /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
