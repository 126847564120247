<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
>
  <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-7 hm-3-bg">
        <div class="bg-white hm-3-p">
          <div class="row cat-h3-top" style="border-bottom: 5px solid #f2f2f2;">
            <div class="col-xs-2 mr-2 ml-3">
              <img
                src="{{ avatar }}"
                alt="{{ username }}"
                class="img-fluid"
                style="max-width: 80px; max-height: 70px; margin-bottom: 1em;"
              />
            </div>
            <div class="col-xs-10 bl_header mt-3">
              <h4>
                {{ username }}
              </h4>
              <b>{{ role }}</b>
            </div>
          </div>

          <div
            class="alert alert-danger alert-sm"
            *ngIf="suspended !== 'false'"
          >
            Your account has been suspended, please contact the moderator at
            moderator@shutlips.com
          </div>

          <div *ngIf="suspended !== 'true'">
            <div class="general-info-right text-center">
              <div class="col-lg-12">
                Need a change of photo?
                <button
                  class="btn btn-primary btn-sm mb-3 mt-3 ml-1"
                  type="button"
                  (click)="show_avatar()"
                >
                  Update Profile Photo
                </button>
                <img
                  src="{{ avatar }}"
                  alt="user_avatar"
                  class="img-fluid img-rounded"
                  style="display: inline-block; width: 80px; height: 80px;"
                  *ngIf="selected"
                />
                <div
                  class="card card-body"
                  *ngIf="display_avatar"
                  style="display: inline-block;"
                >
                  <img
                    *ngFor="let img of imgUrls; let i = index"
                    src="{{ img }}"
                    alt="user_avatar"
                    class="img-fluid img-rounded mr-1 ml-1"
                    style="
                      display: inline-block !important;
                      width: 80px;
                      height: 80px;
                    "
                    (click)="select_avatar(img)"
                    id="image-{{ i }}"
                  />
                </div>
                <!-- 
                <h4 class="text-center">Change Username</h4>
                <small *ngIf="!user?.old_username && !user?.username_changed_at"
                  >Please make sure you proceed with an anonymous identity as
                  this can only be changed once and do not refresh the page
                  during this action if you choose to proceed.</small
                >
                <small *ngIf="user?.old_username && user?.old_username !== ''">
                  You have already changed your username
                </small>
                <hr />
                <form
                  (ngSubmit)="change_username(usernameForm)"
                  #usernameForm="ngForm"
                  class="mb-3"
                  *ngIf="!user?.old_username && !user?.username_changed_at"
                >
                  <div class="col-md-12">
                    <div class="form-group">
                      <input
                        class="form-control form-control-sm"
                        type="text"
                        placeholder="Enter new username"
                        id="new_username"
                        minlength="2"
                        required
                        [(ngModel)]="new_username"
                        name="u_username"
                        #u_username="ngModel"
                        (input)="check_username()"
                      />
                    </div>
                    <small
                      ><b
                        >only a combination of letters and numbers are allowed,
                        any other character will be ignored.</b
                      ></small
                    >
                    <div
                      *ngIf="
                        u_username.invalid &&
                        (u_username.dirty || u_username.touched)
                      "
                      class="alert alert-danger alert-sm"
                    >
                      <div *ngIf="u_username.errors.minlength">
                        Username has to be 2 or more characters.
                      </div>
                    </div>
                    <div
                      class="alert alert-danger alert-sm"
                      *ngIf="taken && new_username"
                    >
                      {{ new_username }} has been taken, try another
                    </div>
                    <div
                      class="alert alert-danger alert-sm"
                      *ngIf="username === new_username"
                    >
                      You cannot use the same username, please try another.
                    </div>
                    <div
                      class="alert alert-success alert-sm"
                      *ngIf="!taken && new_username"
                    >
                      <small>
                        <b>{{ new_username }} is available</b>
                      </small>
                    </div>
                  </div>
                  <div class="form-group col-md-12 text-center">
                    <button
                      [disabled]="
                        usernameForm.form.invalid ||
                        taken ||
                        new_username === ''
                      "
                      type="submit"
                      class="btn btn-primary btn-sm mt-2"
                    >
                      Change Username
                    </button>
                  </div>
                </form> -->

                <h4 class="text-center">Change Password</h4>
                <hr />
                <form
                  (ngSubmit)="onChangePassword()"
                  #changePasswordForm="ngForm"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <input
                        type="password"
                        class="form-control form-control-sm"
                        id="old-password"
                        required
                        [(ngModel)]="password"
                        name="u_password"
                        #u_password="ngModel"
                        placeholder="Enter your current password"
                        autocomplete="current-password"
                        min=""
                      />
                      <span
                        id="old-eye"
                        class="fa fa-eye-slash field-icon"
                        (click)="show_old_password($event)"
                      ></span>
                      <div
                        [hidden]="u_password.valid || u_password.pristine"
                        class="alert alert-danger"
                      >
                        Old password is required.
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mt-1">
                      <input
                        type="password"
                        class="form-control form-control-sm"
                        required
                        (input)="check_password($event.target.value)"
                        [(ngModel)]="new_password"
                        name="u_new_password"
                        #u_new_password="ngModel"
                        placeholder="Enter your new password"
                        maxlength="20"
                        minlength="6"
                        id="new-password"
                        autocomplete="new-password"
                      />
                      <span
                        id="new-eye"
                        class="fa fa-eye-slash field-icon"
                        (click)="show_new_password($event)"
                      ></span>
                      <div
                        class="alert alert-danger mt-2"
                        *ngIf="
                          password && new_password && new_password === password
                        "
                      >
                        Please select a new password different from your current
                        password and try again.
                      </div>
                      <div
                        *ngIf="
                          u_new_password.invalid &&
                          (u_new_password.dirty || u_new_password.touched)
                        "
                        class="alert alert-danger"
                      >
                        <span *ngIf="u_new_password.errors.required">
                          New Password is required.
                        </span>
                        <span *ngIf="u_new_password.errors.maxlength">
                          Password must be 20 characters or less.
                        </span>
                        <span *ngIf="u_new_password.errors.minlength">
                          Password must be 6 characters or more.
                        </span>
                      </div>
                    </div>
                    <div class="col-md-6 col-sm-12 mt-1">
                      <input
                        type="password"
                        class="form-control form-control-sm"
                        required
                        [(ngModel)]="confirm_password"
                        name="u_confirm_password"
                        #u_confirm_password="ngModel"
                        placeholder="Confirm new password"
                        (keyup)="check($event.target.value)"
                        maxlength="20"
                        minlength="6"
                        id="con-password"
                        autocomplete="new-password"
                      />
                      <span
                        id="con-eye"
                        class="fa fa-eye-slash field-icon"
                        (click)="show_con_password($event)"
                      ></span>
                      <div
                        *ngIf="
                          u_confirm_password.invalid &&
                          (u_confirm_password.dirty ||
                            u_confirm_password.touched)
                        "
                        class="alert alert-danger"
                      >
                        <span *ngIf="u_confirm_password.errors.required">
                          Confirm Password is required.
                        </span>
                        <span *ngIf="u_confirm_password.errors.maxlength">
                          Password must be 20 characters or less.
                        </span>
                        <span *ngIf="u_confirm_password.errors.minlength">
                          Password must be 6 characters or more.
                        </span>
                      </div>
                      <div
                        class="alert alert-danger"
                        *ngIf="confirm_p === true"
                      >
                        Passwords doesn't match
                      </div>
                    </div>
                    <div class="form-group col-md-12 text-center">
                      <button
                        [disabled]="
                          !changePasswordForm.form.valid ||
                          confirm_p === true ||
                          disable === true
                        "
                        type="submit"
                        class="btn btn-primary btn-sm mt-3"
                      >
                        Change Password
                      </button>
                    </div>
                  </div>
                </form>

                <hr />
                <h4 class="text-center">Install shutlips.com manually</h4>
                <!-- <button id="installBtn" class="btn btn-primary btn-sm mt-3">
                  Install Now
                </button> -->
                <p class="mb-2">
                  Only use this option if you don't get the prompt to add
                  shutlips.com to your home screen
                </p>

                <small *ngIf="check_if_ios()">
                  For iPhone users only, tap the Bookmark icon at the bottom of
                  the screen (it resembles a box with an upward-pointing arrow).
                  Tap Add to Home Screen. Accept the suggested name for the
                  shortcut. At the top of the screen, tap Add to save the new
                  shortcut icon to the home screen.
                </small>

                <small *ngIf="check_if_android()">
                  Tap the menu icon (3 dots in upper right-hand corner) and tap
                  Add to homescreen. You’ll be able to enter a name for the
                  shortcut and then Chrome will add it to your home screen.
                </small>

                <hr />
                <h4 class="text-center">
                  Subscribe to shutlips notifications
                </h4>
                <small
                  class="text-center"
                  *ngIf="isEnabled && isGranted === 'granted'"
                >
                  You've already subscribed to shutlips.com notifications
                </small>
                <small class="text-center" *ngIf="!isEnabled">
                  Your current browser does not support notifications, please
                  update your browser or retry with a more recent browser.
                </small>
                <button
                  class="btn btn-primary btn-sm mt-3"
                  (click)="subscribe()"
                  *ngIf="isEnabled && isGranted !== 'granted'"
                >
                  Subscribe Now
                </button>
              </div>
            </div>
          </div>
          <!--END OF CONTENT-->
        </div>
      </div>
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
