import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
})
export class SignInComponent implements OnInit {
  username: string;
  password: string;
  remember_me: boolean = true;
  submitted: boolean = false;

  constructor(private _auth: AuthService, private _spinner: NgxSpinnerService) {
    // failsafe
    this._spinner.hide();
  }

  ngOnInit() {
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  onSubmit() {
    this._auth.sign_in(
      this.username.trim().toLowerCase(),
      this.password.trim(),
      this.remember_me
    );
  }

  show_password(e) {
    e.preventDefault();

    if (document.getElementById('eye').classList.contains('fa-eye-slash')) {
      document.getElementById('eye').classList.remove('fa-eye-slash');
      document.getElementById('eye').classList.add('fa-eye');
      document.getElementById('password').setAttribute('type', 'text');
    } else if (document.getElementById('eye').classList.contains('fa-eye')) {
      document.getElementById('eye').classList.remove('fa-eye');
      document.getElementById('eye').classList.add('fa-eye-slash');
      document.getElementById('password').setAttribute('type', 'password');
    }
  }

  remember(event) {
    var rem = <HTMLInputElement>document.getElementById('remember_me');
    this.remember_me = rem.checked;
  }
}
