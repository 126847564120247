<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate">
  <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <!-- End of col-lg-2-->
      <div class="col-lg-7 col-12 blog_wrap">
        <div class="single_blog" *ngFor="let p of post">
          <div class="blog-header text-center">
            <small class="bg-primary featured" *ngIf="p.featured">
              <i class="fa fa-bookmark"></i> featured
            </small>
            <h3 class="about-title text-primary" style="text-transform: capitalize;">
              {{ p.title }}
            </h3>
            <div class="blog-header">
              <small>
                Posted by
                <span class="post_aouther">Nonye</span>
                <span> - {{ wpm(p.content) }}</span>
              </small>
              |
              <small class="post-cat">
                <a class="green" title="{{ p.category }}" [routerLink]="['/blog-category', p.category]">
                  {{ category(p.category) }}
                </a>
              </small>
            </div>
          </div>
          <div class="blg-img text-center" *ngIf="!edit">
            <img src="{{ p.imgUrl }}" alt="{{ p.id }}-image" class="img-fluid" />
          </div>
          <div class="blog-paragrap" *ngIf="!edit">
            <p [innerHtml]="p.content"></p>
          </div>
        </div>
      </div>

      <!-- End of col-lg-7-->
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>