import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ForumService } from 'src/app/services/forum.service';
import * as moment from 'moment';

@Component({
  selector: 'app-archived',
  templateUrl: './archived.component.html',
  styleUrls: ['./archived.component.css'],
})
export class ArchivedComponent implements OnInit {
  sub: Subscription;
  deleted_posts: any[];
  deleted_topics: any[];
  deleted_comments: any[];
  deleted_comment_replies: any[];
  dt: number = 1;
  dp: number = 1;
  dtc: number = 1;
  dtcr: number = 1;
  role: string;

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _forum: ForumService
  ) {}

  ngOnInit() {
    this._auth.isAuthenticated();
    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];
    
    if (this.role !== 'admin') {
      this._router.navigate(['/']);
    }

    this.sub = this._forum.deleted_topics().subscribe((topics) => {
      this.deleted_topics = topics;
      console.log(topics);
    });

    this.sub = this._forum.deleted_posts().subscribe((posts) => {
      this.deleted_posts = posts;
      console.log(posts);
    });

    this.sub = this._forum
      .deleted_topic_comments()
      .subscribe((deleted_topic_comments) => {
        this.deleted_comments = deleted_topic_comments;
        console.log(deleted_topic_comments);
      });

    this.sub = this._forum
      .deleted_comment_replies()
      .subscribe((topic_comment_replies) => {
        this.deleted_comment_replies = topic_comment_replies;
        console.log(topic_comment_replies);
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  first50(content: string): string {
    if (content.length >= 150) {
      return `${content.substring(0, 150)}...`;
    } else {
      return content;
    }
  }

  dateTime(dt: string) {
    return moment(dt).fromNow();
  }

  category(category: string) {
    return category.split('-').join(' ');
  }

  delete_topic = (topic: any) => {
    let c = confirm('Are you sure you want to delete this discussion?');
    if (c) {
      this._forum.delete_archived_topic(topic.uid);
      this._forum.delete_topic(topic.uid, topic.author);
    }
  };

  delete_post = (uid: string) => {
    let c = confirm('Are you sure you want to delete this post?');
    if (c) {
      this._forum.delete_archived_post(uid);
    }
  };

  delete_comment = (uid: string) => {
    let c = confirm('Are you sure you want to delete this comment?');
    if (c) {
      this._forum.delete_archived_topic_comment(uid);
    }
  };

  delete_comment_reply = (uid: string) => {
    let c = confirm('Are you sure you want to delete this comment reply?');
    if (c) {
      this._forum.delete_archived_topic_comment_replies(uid);
    }
  };

  restore_topic = (topic: any) => {
    let c = confirm('Are you sure you want to restore this discussion?');
    if (c) {
      this._forum.restore_archived_topic(topic);
    }
  };

  restore_post = (post: any) => {
    let c = confirm('Are you sure you want to restore this post?');
    if (c) {
      this._forum.restore_archived_post(post);
    }
  };

  restore_comment = (comment: any) => {
    let c = confirm('Are you sure you want to restore this comment?');
    if (c) {
      this._forum.restore_archived_topic_comment(comment);
    }
  };

  restore_comment_reply = (comment: any) => {
    let c = confirm('Are you sure you want to restore this comment reply?');
    if (c) {
      this._forum.restore_archived_topic_comment_replies(comment);
    }
  };
}
