import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { FilterPipe } from 'ngx-filter-pipe';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFirestore } from '@angular/fire/firestore';
import * as read_time from 'word-per-minute';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PostsComponent implements OnInit, OnDestroy {
  username: string;
  role: string;
  posts = [];
  sub: Subscription;
  p: number = 1;
  userFilter: any;

  constructor(
    private _spinner: NgxSpinnerService,
    private _forum: ForumService,
    private filterPipe: FilterPipe
  ) {
    this._forum.changeMessage('');

    this.p =
      window.sessionStorage['prev-page'] !== undefined
        ? window.sessionStorage['prev-page']
        : this.p;
  }

  ngOnInit() {
    this._spinner.show();
    this.username = window.sessionStorage['slu_username']
      ? window.sessionStorage['slu_username']
      : window.localStorage['slu_username'];
    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];

    this.sub = this._forum.get_posts().subscribe((posts) => {
      this.posts = posts;
      this.filterPipe.transform(this.posts, { title: '' });
      this._spinner.hide();
    });

    this._forum.currentSearch.subscribe((message) => {
      this.userFilter = { title: message };
    });

    setTimeout((_) => {
      if (
        window.sessionStorage['prev-page'] &&
        window.sessionStorage['scroll-to'] !== undefined
      ) {
        let element = document.getElementById(
          window.sessionStorage['scroll-to']
        );
        element.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
    }, 1000);
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  dateTime(dt: string) {
    return moment(dt).fromNow();
  }

  category(title: string) {
    return title.split('-').join(' ');
  }

  first50(content: string): string {
    if (content.length >= 200) {
      return `${content.substring(0, 200)}...`;
    } else {
      return content;
    }
  }

  set_page_number(page: number, id: string) {
    window.sessionStorage['prev-page'] = page;
    window.sessionStorage['scroll-to'] = id;
  }

  wpm = (text: string) => {
    const options: any = {
      language: 'en-us',
      wordsPerMinute: 150,
    };
    const arr = read_time(text, options).text.split(' ');
    return `${arr[0]} ${+arr[0] > 1 ? arr[1] + 's' : arr[1]} ${arr[2]}`;
  };
}
