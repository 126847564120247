<div class="advice">
  <button class="btn btn-primary btn-sm" *ngIf="admin && quotes.length == 0" (click)="new_quote()">
    Add
  </button>
  <div *ngFor="let quote of quotes" class="text-center">
    <img src="./assets/images/quote.png" alt="{{ quote.username }}" style="width: 100px; height: 130px;"
      class="img-fluid" />
    <h5 class="text-capitalize mt-2">{{ quote.name }}</h5>
    <p class="mt-2">
      {{ quote.quote }}
    </p>
    <div class="text-center">
      <button class="btn btn-primary btn-sm" *ngIf="admin" (click)="change(quote)">
        Change
      </button>
    </div>
  </div>
  <form (ngSubmit)="onChangeQuote()" #changeQuoteForm="ngForm" *ngIf="edit">
    <div class="row">
      <div class="col-md-12 mb-3 mt-3">
        <input type="text" class="form-control form-control-sm" id="name" required [(ngModel)]="name" name="u_name"
          #u_name="ngModel" [class.is-valid]="u_name.valid" placeholder="Enter Name" />
        <div [hidden]="u_name.valid || u_name.pristine" class="alert alert-danger alert-sm">
          Name is required.
        </div>
        <textarea class="form-control form-control-sm" id="quote" required [(ngModel)]="quote" name="u_quote"
          #u_quote="ngModel" placeholder="Enter quote"></textarea>
        <div [hidden]="u_quote.valid || u_quote.pristine" class="alert alert-danger alert-sm">
          Quote is required.
        </div>
      </div>
      <div class="form-group col-md-12 text-center">
        <button [disabled]="!changeQuoteForm.form.valid" type="submit" class="btn btn-primary btn-sm mt-3">
          Save
        </button>
      </div>
    </div>
  </form>
</div>
<p style="color: #e1340f" *ngFor="let verse of verses" class="text-center hidden-xs hidden-sm">
  <!-- <small>{{ verse.verse }}</small> -->
  <!-- <small class="mb-2">{{ verse.text }}</small>
  <br /> -->
  <button class="btn btn-primary btn-sm mt-2" *ngIf="admin" (click)="change_verse(verse)">
    edit bible verse
  </button>
</p>

<form (ngSubmit)="onChangeVerse()" #changeVerseForm="ngForm" *ngIf="edit_verse">
  <div class="row">
    <div class="col-md-12 mb-3 mt-3">
      <input type="text" class="form-control form-control-sm" id="verse" required [(ngModel)]="verse" name="u_verse"
        #u_verse="ngModel" placeholder="Enter Verse" />
      <div [hidden]="u_verse.valid || u_verse.pristine" class="alert alert-danger alert-sm">
        Verse is required.
      </div>
      <!-- <textarea
        class="form-control form-control-sm"
        id="text"
        required
        [(ngModel)]="text"
        name="u_text"
        #u_text="ngModel"
        placeholder="Enter quote"
      ></textarea> -->
      <!-- <div
        [hidden]="u_text.valid || u_text.pristine"
        class="alert alert-danger alert-sm"
      >
        Text is required.
      </div> -->
    </div>
    <div class="form-group col-md-12 text-center">
      <button [disabled]="!changeVerseForm.form.valid" type="submit" class="btn btn-primary btn-sm mt-3">
        Save
      </button>
    </div>
  </div>
</form>

<div class="ads">
  <ng-adsense [adClient]="adClient" [adSlot]="slotID"></ng-adsense>
</div>

<div class="ads">
  <ng-adsense [adClient]="adClient" [adSlot]="slotID1"></ng-adsense>
</div>