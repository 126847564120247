import { Component, OnInit } from '@angular/core';
import { ForumService } from 'src/app/services/forum.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.css'],
})
export class CategoryListComponent implements OnInit {
  categories = [];
  blog = false;
  admin: boolean = false;
  adClient = environment.adsense_profile_preview.adClient;
  slotID = environment.adsense_categories.slotID;

  constructor(private _forum: ForumService) {}

  ngOnInit() {
    this._forum.get_forum_categories().subscribe((categories) => {
      this.categories = categories;
    });

    if (
      (window.location.pathname.includes('posts') &&
        !window.location.pathname.includes('topic')) ||
      (window.location.pathname.includes('blog-category') &&
        !window.location.pathname.includes('topic')) ||
      (window.location.pathname.includes('post') &&
        !window.location.pathname.includes('topic'))
    ) {
      this.blog = true;
      this._forum.get_blog_categories().subscribe((categories) => {
        this.categories = categories;
      });
    } else {
      this.blog = false;
      this._forum.get_forum_categories().subscribe((categories) => {
        this.categories = categories;
      });
    }

    if (
      window.sessionStorage['slu_role'] === 'admin' ||
      window.sessionStorage['slu_role'] === 'moderator' ||
      window.localStorage['slu_role'] === 'admin' ||
      window.localStorage['slu_role'] === 'moderator'
    ) {
      this.admin = true;
    } else {
      this.admin = false;
    }
  }

  add_show() {
    document.getElementById('list').classList.toggle('show');
  }
}
