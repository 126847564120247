<h6 class="discussion title text-capitalize mb-3 pb-2 cat-one" *ngIf="!blog" id="title" (click)="add_show()">
  <span>Discussion Categories</span>
</h6>
<h6 class="discussion title text-capitalize mb-3 pb-2 cat-one" *ngIf="blog" id="title" (click)="add_show()">
  <span>Blog Categories</span>
</h6>
<ul class="list-inline discussion" *ngIf="!blog" id="list">
  <li *ngFor="let c of categories" class="list-inline-item d-block">
    <a title="{{ c.title }}" class="grey" [routerLink]="['/categories', c.id]">
      {{ c.title }}
    </a>
  </li>
</ul>
<ul class="list-inline discussion" *ngIf="blog" id="list">
  <li *ngFor="let c of categories" class="list-inline-item d-block">
    <a title="{{ c.title }}" class="grey" [routerLink]="['/blog-category', c.id]">
      {{ c.title }}
    </a>
  </li>
</ul>

<div class="ads">
  <ng-adsense [adClient]="adClient" [adSlot]="slotID"></ng-adsense>
</div>