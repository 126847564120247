import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-username-changes',
  templateUrl: './username-changes.component.html',
  styleUrls: ['./username-changes.component.css'],
})
export class UsernameChangesComponent implements OnInit, OnDestroy {
  p: number = 1;
  sub: Subscription;
  username_changes = [];

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _router: Router
  ) {}

  ngOnInit() {
    this._auth.isAuthenticated();
    this.sub = this._forum
      .get_username_changes()
      .subscribe((ru) => (this.username_changes = ru));

    if (
      window.sessionStorage['slu_role'] == 'admin' ||
      window.sessionStorage['slu_role'] == 'moderator' ||
      window.localStorage['slu_role'] == 'admin' ||
      window.localStorage['slu_role'] == 'moderator'
    ) {
    } else {
      this._router.navigate(['/']);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  date(dt: string) {
    return moment(dt).fromNow(true);
  }
}
