<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
>
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <!-- End of col-lg-2-->
      <div class="col-lg-7 blog_wrap mb-3">
        <div *ngFor="let p of topics">
          <div class="row">
            <div class="col-lg-12">
              <small class="bg-primary featured" *ngIf="p.featured">
                <i class="fa fa-bookmark"></i> featured
              </small>
              <div class="single_blog">
                <div class="blog-header text-center">
                  <span class="text-center"
                    >Started by
                    <span class="post_aouther">
                      <a [routerLink]="['/user-profile', p.author]">{{
                        p.author
                      }}</a></span
                    ></span
                  >
                  <span class="post_time text-center">{{
                    dateTime(p.timeCreated)
                  }}</span>
                  <span *ngIf="p.edited">
                    | edited {{ dateTime(p.edited_time) }}</span
                  >
                  <span>|</span>
                  <span class="post-cat text-center"
                    ><a
                      title="{{ p.category }}"
                      [routerLink]="['/categories', p.category]"
                      >{{ category(p.category) }}</a
                    ></span
                  >
                </div>
                <div class="row blg-img text-center">
                  <div class="col-lg-12 bl_header about-title">
                    <img
                      src="{{ p.author_avatar }}"
                      class="img-fluid mr-3 rounded-circle"
                      alt="{{ p.author }}"
                      width="80px"
                      height="70px"
                    />
                    <h4>
                      {{ p.title }}
                    </h4>
                  </div>
                </div>
                <div
                  class="blog-paragrap text-center"
                  [innerHtml]="p.content"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- End of col-lg-7-->
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
