import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  logged_in = false;
  admin = false;
  moderator = false;
  display = true;
  search_field = '';
  placeholder = '';
  role = '';
  unread_messages = [];
  unopened_notifications = [];
  sub: Subscription;
  username: string;
  isLoggedIn: boolean;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _router: Router
  ) {
    this.username = window.localStorage['slu_username']
      ? window.localStorage['slu_username']
      : window.sessionStorage['slu_username'];

    this._router.events.subscribe((event) => {
      if (
        (event['url'] && event['url'] === '/auth/sign-up') ||
        (event['url'] && event['url'] === '/auth/sign-in') ||
        (event['url'] && event['url'] === '/auth/forgot-password') ||
        (event['url'] && event['url'] === '/auth/reset-password') ||
        (event['url'] && event['url'] === '/auth/recovery-questions')
      ) {
        this.display = false;
      }
    });
  }

  ngOnInit() {
    if (
      (window.sessionStorage['shutlips_session_id'] !== undefined &&
        window.sessionStorage['shutlips_username'] !== undefined &&
        window.sessionStorage['slu_username'] !== undefined &&
        window.sessionStorage['slu_avatarUrl'] !== undefined) ||
      (window.localStorage['shutlips_session_id'] !== undefined &&
        window.localStorage['shutlips_username'] !== undefined &&
        window.localStorage['slu_username'] !== undefined &&
        window.localStorage['slu_avatarUrl'] !== undefined)
    ) {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }

    this._forum.currentSearch.subscribe((not) => {
      this.search_field = not;
    });

    if (
      window.localStorage['shutlips_session_id'] ||
      window.sessionStorage['shutlips_session_id']
    ) {
      this.sub = this._forum
        .get_unread_user_messages(this.username)
        .subscribe((messages) => {
          this.unread_messages = messages;
        });

      this.sub = this._forum
        .get_unopened_notifications(this.username)
        .subscribe((notifications) => {
          this.unopened_notifications = notifications;
        });
    }

    if (window.location.pathname.includes('auth')) {
      this.display = false;
    } else {
      this.display = true;
    }

    if (window.location.pathname.includes('posts')) {
      this.placeholder = 'Blog Posts';
    } else {
      this.placeholder = 'Discussions';
    }

    if (
      window.sessionStorage['slu_role'] == 'admin' ||
      window.localStorage['slu_role'] == 'admin'
    ) {
      this.admin = true;
    } else if (
      window.sessionStorage['slu_role'] == 'moderator' ||
      window.localStorage['slu_role'] == 'moderator'
    ) {
      this.moderator = true;
    }

    this._forum.currentSearch.subscribe((message) => {
      this.search_field = message;
    });

    this._router.events.subscribe((_) => {
      if (this.search_field !== '') {
        this._forum.changeMessage('');
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  sign_out() {
    this._auth.sign_out();
  }

  emitSearch(text: string): void {
    this._forum.changeMessage(text);
  }

  clear_pn() {
    window.sessionStorage.removeItem('scroll-to');
    window.sessionStorage.removeItem('prev-page');
  }
}
