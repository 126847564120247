import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import * as CryptoJS from 'crypto-js';
import Swal from 'sweetalert2';
import { NgxSpinnerService } from 'ngx-spinner';

export class User {
  uid: string;
  username: string = '';

  constructor(auth: any, private _spinner: NgxSpinnerService) {
    this.uid = auth.uid;
  }
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  users = [];
  male = [
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F16.png?alt=media&token=44b4aaf8-b974-4566-975a-205151e9c5fc',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F17.png?alt=media&token=7d199bdc-091b-4b4b-b9d7-fe734514d548',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F19.png?alt=media&token=bf3ea81b-af80-45bf-bc6b-896a00a22999',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F20.png?alt=media&token=32095de5-ca6a-4949-b4c2-0032332613f7',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F21.png?alt=media&token=bbb81f5c-e12f-4575-81d7-dc2eb47992cc',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F22.png?alt=media&token=68eff6b2-0f0c-45b9-b2b3-60779f1a8c76',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F23.png?alt=media&token=9b754827-803f-4c2f-8d27-815ebb791164',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F24.png?alt=media&token=c5acdd0e-d1ec-46c7-bbf2-1dbfab5dfe95',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F25.png?alt=media&token=fe48d2dc-e825-4bbc-b53d-f298d500026e',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F26.png?alt=media&token=bd62bcd5-e4e5-4f00-b2db-8c5b5b619467',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F27.png?alt=media&token=ce74e279-6a56-47ef-85d1-2e0dd2afdd2c',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F28.png?alt=media&token=c41aeb33-bf39-45b4-80d1-565ab48e1501',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F29.png?alt=media&token=860b4cbe-18aa-488e-8ded-65fc6debbfdf',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F18.png?alt=media&token=76782102-9502-4e35-b992-06af8587a42e',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2Fmale-avatar.png?alt=media&token=6611c816-bbe9-46ce-b3d2-a2a0bd0e0267',
  ];
  female = [
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F1.png?alt=media&token=ee428864-8a18-4347-a12d-a803ff34f8a9',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F10.png?alt=media&token=9ca0b57e-7172-4b8f-a6a2-bb4ce73ccd36',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F11.png?alt=media&token=da246bce-8715-4d90-a393-d3f38b49e984',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F13.png?alt=media&token=c9dd820a-e5ce-41b7-8cd7-96db9e339605',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F14.png?alt=media&token=0e92b0ab-77c2-4c6d-a753-526be34baa71',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F15.png?alt=media&token=d3c51f67-ed37-48cc-b5b1-8d3a69016fc3',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F2.png?alt=media&token=dc609d44-72e5-4492-9cdd-5a5b447ee580',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F3.png?alt=media&token=6dcebd3d-9be3-4f44-a9d8-9043c67c2489',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F5.png?alt=media&token=03a65645-ef6a-41f1-9c2a-3214975ef1ca',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F7.png?alt=media&token=f37a6b9f-0e40-43e6-99b0-4e94bb5e5125',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F8.png?alt=media&token=cfc3cd54-ac87-4d97-b078-d99ed17ddb14',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F9.png?alt=media&token=d8515d0e-5fac-4a93-aa14-23b923bf2795',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F12.png?alt=media&token=167dfa7e-548f-441e-9878-2449ed18842d',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F4.png?alt=media&token=57425dc0-da87-419d-b66f-bf91f0ef9e8d',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F6.png?alt=media&token=4daad550-cafa-44f0-b6b5-323a6c963a16',
  ];
  other = [
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F12.png?alt=media&token=167dfa7e-548f-441e-9878-2449ed18842d',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F29.png?alt=media&token=860b4cbe-18aa-488e-8ded-65fc6debbfdf',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F4.png?alt=media&token=57425dc0-da87-419d-b66f-bf91f0ef9e8d',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F6.png?alt=media&token=4daad550-cafa-44f0-b6b5-323a6c963a16',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2F18.png?alt=media&token=76782102-9502-4e35-b992-06af8587a42e',
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/avatars%2Fmale-avatar.png?alt=media&token=6611c816-bbe9-46ce-b3d2-a2a0bd0e0267',
  ];

  isLoggedIn = false;
  shutlips_session_id;
  sign_in_errors = [];
  current_user = {};
  show_recovery_questions = false;
  username: string = '';

  constructor(
    private _afs: AngularFirestore,
    private _router: Router,
    private _spinner: NgxSpinnerService
  ) {}

  //The set method is use for encrypt the value.
  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  isAuthenticated(): boolean {
    if (
      (window.sessionStorage['shutlips_session_id'] !== undefined &&
        window.sessionStorage['shutlips_username'] !== undefined &&
        window.sessionStorage['slu_username'] !== undefined &&
        window.sessionStorage['slu_avatarUrl'] !== undefined) ||
      (window.localStorage['shutlips_session_id'] !== undefined &&
        window.localStorage['shutlips_username'] !== undefined &&
        window.localStorage['slu_username'] !== undefined &&
        window.localStorage['slu_avatarUrl'] !== undefined)
    ) {
      this.isLoggedIn = true;
      return this.isLoggedIn;
    } else {
      this.isLoggedIn = false;
      this._router.navigate(['/auth', 'sign-in']);
      return this.isLoggedIn;
    }
  }

  get_user(username) {
    return this._afs
      .collection('users', (ref) => ref.where('username', '==', `${username}`))
      .valueChanges();
  }

  check_username(username: string) {
    return this._afs.collection(`usernames`).doc(username).get();
  }

  sign_up(username: string, password: string, data) {
    Swal.fire({
      title: 'Sign up in progress!',
      text: `Please wait while we create your account.`,
      background: '#eeeeee',
      type: 'error',
    });

    this._router.navigate(['/auth', 'sign-in']);

    var p = this.set('123456$#@$^@1ERF', '' + password.trim());

    data.password = p;
    try {
      this._saveUser(username, data);
    } catch (e) {
      this._handleError(e);
    }
  }

  async sign_in(username: string, password: string, remember: boolean) {
    this._spinner.show();
    let p = this.set('123456$#@$^@1ERF', '' + password.trim());
    try {
      let usersRef = this._afs.collection('users');
      let usersRefC = await usersRef.ref
        .where('username', '==', username)
        .get();
      if (!usersRefC.empty) {
        if (usersRefC.docs[0].data().deactivated === true) {
          this._spinner.hide();
          Swal.fire({
            title: 'Account Deactivated!',
            text: `Your account has been disabled, please contact moderator@shutlips.com`,
            background: '#eeeeee',
            type: 'error',
          });
          this._router.navigate(['/auth', 'sign-in']);
        } else if (usersRefC.docs[0].data().suspended === true) {
          this._spinner.hide();
          Swal.fire({
            title: 'Account Suspended!',
            text: `Your account has been suspended, please contact moderator@shutlips.com`,
            background: '#eeeeee',
            type: 'warning',
          });
          if (remember === true) {
            this._setLocalStorage(usersRefC.docs[0].data());
          } else if (remember === false) {
            this._setSessionStorage(usersRefC.docs[0].data());
          }
          window.location.assign('/');
        } else if (
          usersRefC.docs[0].data().password !== p ||
          usersRefC.docs[0].data().username != username.trim().toLowerCase()
        ) {
          this._spinner.hide();
          Swal.fire({
            title: 'Oops!',
            text: `Your username or password is incorrect. Try again with correct information.`,
            background: '#eeeeee',
            type: 'error',
          });
          this._router.navigate(['/auth', 'sign-in']);
        } else if (
          usersRefC.docs[0].data().password === p &&
          usersRefC.docs[0].data().username == username
        ) {
          this._spinner.hide();
          if (remember === true) {
            this._setLocalStorage(usersRefC.docs[0].data());
          } else if (remember === false) {
            this._setSessionStorage(usersRefC.docs[0].data());
          }
          window.location.assign('/');
        }
      } else {
        this._spinner.hide();
        Swal.fire({
          title: 'OOps!',
          text: `Looks like you have a problem logging in. Please sign up to join us or connect to an active internet service.`,
          background: '#eeeeee',
          type: 'error',
        });
        this._router.navigate(['/auth', 'sign-in']);
      }
    } catch (error) {
      this._spinner.hide();
      this._handleError(error);
    }
    this._spinner.hide();
  }

  sign_out() {
    window.location.assign('/auth/sign-in');
    window.sessionStorage.clear();
    window.localStorage.clear();
  }

  forgot_password(username: string) {
    try {
      this._afs
        .collection('users', (ref) =>
          ref.where('username', '==', `${username.toLowerCase()}`)
        )
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              window.sessionStorage['slu_username_fp'] = username.toLowerCase();
              this._router.navigate(['/auth', 'recovery-questions']);
            } else {
              Swal.fire({
                title: 'Error!',
                text:
                  'The username you entered may be wrong or not created yet.',
                background: '#eeeeee',
                type: 'error',
              });
              this._router.navigate(['/auth', 'forgot-password']);
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  change_password(data) {
    let username: string = window.sessionStorage['slu_username_fp'];
    username = username.toLowerCase();
    try {
      this._afs
        .collection('users', (ref) =>
          ref.where('username', '==', `${username}`)
        )
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.update(data);
              Swal.fire({
                title: 'Success!',
                text: 'Your password has been updated',
                background: '#eeeeee',
                type: 'success',
              });
              this._router.navigate(['/auth', 'sign-in']);
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  disable_user(uid: string) {
    const data = {
      deactivated: true,
      deactivated_date: Date.now(),
    };
    try {
      this._afs
        .collection('users', (ref) => ref.where('uid', '==', uid))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.set(data, { merge: true });
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  enable_user(uid: string) {
    const data = {
      deactivated: false,
    };
    try {
      this._afs
        .collection('users', (ref) => ref.where('uid', '==', uid))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.set(data, { merge: true });
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  recovery_questions(q1: string, a1: string, q2: string, a2: string) {
    let username: string = window.sessionStorage['slu_username_fp'];
    username = username.toLowerCase();
    try {
      this._afs
        .collection('users', (ref) => ref.where('username', '==', username))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              let answer_one: string = doc.data().answer_one;
              let answer_two: string = doc.data().answer_two;
              if (
                doc.data().question_one == q1 &&
                answer_one.toLowerCase() == a1.toLowerCase() &&
                doc.data().question_two == q2 &&
                answer_two.toLowerCase() == a2.toLowerCase()
              ) {
                window.sessionStorage['slu_username_rp'] = 'reset_password';
                this._router.navigate(['/auth', 'reset-password']);
              } else {
                this._router.navigate(['/auth', 'recovery-questions']);
                Swal.fire({
                  title: 'Error!',
                  text: 'The answers provided does not match.',
                  background: '#eeeeee',
                  type: 'error',
                });
              }
            } else {
              this._router.navigate(['/auth', 'forgot-password']);
              Swal.fire({
                title: 'Error!',
                text:
                  'The username you entered may be wrong or not created yet.',
                background: '#eeeeee',
                type: 'error',
              });
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  save_vapid_to_user = (username: string, role: string, sub: any) => {
    this._spinner.show();
    let subscriptions = [];
    try {
      this._afs
        .collection('subscriptions')
        .get()
        .subscribe((snapshot) => {
          subscriptions = snapshot.docs;
        });

      if (subscriptions.length > 0) {
        subscriptions.forEach((u: any) => {
          if (
            u.vapid_subscription.endpoint !==
            JSON.parse(JSON.stringify(sub)).endpoint
          ) {
            this._afs
              .collection('subscriptions')
              .add({
                vapid_subscription: JSON.parse(JSON.stringify(sub)),
                username,
                role,
              })
              .then(() => {
                this._spinner.hide();
                window.location.reload();
              });
          }
        });
      } else {
        this._afs
          .collection('subscriptions')
          .add({
            vapid_subscription: JSON.parse(JSON.stringify(sub)),
            username,
            role,
          })
          .then(() => {
            this._spinner.hide();
            window.location.reload();
          });
      }
    } catch (e) {
      this._handleError(e);
    }
  };

  private _setSessionStorage(user) {
    const {
      username,
      gender,
      role,
      avatarUrl,
      password,
      suspended,
      deactivated,
      uid,
      joined,
    } = user;

    window.sessionStorage.setItem('slu_username', username);
    window.sessionStorage.setItem('slu_gender', gender);
    window.sessionStorage.setItem('slu_role', role);
    window.sessionStorage.setItem('slu_avatarUrl', avatarUrl);
    window.sessionStorage.setItem('slu_password', password);
    window.sessionStorage.setItem('slu_suspended', suspended);
    window.sessionStorage.setItem('slu_deactivated', deactivated);
    window.sessionStorage.setItem('slu_uid', uid);
    window.sessionStorage.setItem('slu_joined', joined);

    const shutlips_session_id = Date.now().toExponential;
    window.sessionStorage[
      'shutlips_session_id'
    ] = shutlips_session_id.toString();
    window.sessionStorage['shutlips_username'] =
      window.sessionStorage['slu_username'];
  }

  private _setLocalStorage(user) {
    const {
      username,
      gender,
      role,
      avatarUrl,
      password,
      suspended,
      deactivated,
      uid,
      joined,
    } = user;

    window.localStorage.setItem('slu_username', username);
    window.localStorage.setItem('slu_gender', gender);
    window.localStorage.setItem('slu_role', role);
    window.localStorage.setItem('slu_avatarUrl', avatarUrl);
    window.localStorage.setItem('slu_password', password);
    window.localStorage.setItem('slu_suspended', suspended);
    window.localStorage.setItem('slu_deactivated', deactivated);
    window.localStorage.setItem('slu_uid', uid);
    window.localStorage.setItem('slu_joined', joined);

    const shutlips_session_id = Date.now().toExponential;
    window.localStorage['shutlips_session_id'] = shutlips_session_id.toString();
    window.localStorage['shutlips_username'] =
      window.localStorage['slu_username'];
  }

  private async _saveUser(username: string, data) {
    const id = this._afs.createId();
    data.uid = id;
    try {
      let new_user = await this.check_username(
        username.trim().toLowerCase()
      ).toPromise();

      if (!new_user.exists) {
        username.toLowerCase();
        await this._afs
          .collection('usernames')
          .doc(username)
          .set({ username: id });

        await this._afs.collection('users').doc(id).set(data);

        Swal.fire({
          title: 'Success!',
          text:
            'Your account has been successfully created, please sign in to continue.',
          background: '#eeeeee',
          type: 'success',
        });

        window.sessionStorage['new_user'] = 'true';
      }
    } catch (e) {
      this._handleError(e);
    }
  }

  get_usernames() {
    return this._afs.collection('usernames').valueChanges();
  }

  // if error, console log and notify user
  private _handleError(error) {
    Swal.fire({
      title: 'Oops!',
      text: `Something went wrong: ${error.message}`,
      background: '#eeeeee',
      type: 'error',
    });
  }
}
