import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-add-topic',
  templateUrl: './add-topic.component.html',
  styleUrls: ['./add-topic.component.css'],
})
export class AddTopicComponent implements OnInit, OnDestroy {
  dcategory = 'Select Category';
  title: string;
  id: string;
  content: string;
  timeCreated: string;
  category: string;
  username: string;
  tag_user: string;
  avatar = '';
  categories = [];
  sub: Subscription;
  taken: boolean;

  constructor(private _auth: AuthService, private _forum: ForumService) {}

  ngOnInit() {
    this._auth.isAuthenticated();

    this.sub = this._forum.get_forum_categories().subscribe((categories) => {
      this.categories = categories;
    });
    this.username = window.sessionStorage['slu_username']
      ? window.sessionStorage['slu_username']
      : window.localStorage['slu_username'];
    this.avatar = window.sessionStorage['slu_avatarUrl']
      ? window.sessionStorage['slu_avatarUrl']
      : window.localStorage['slu_avatarUrl'];
    this.category =
      window.sessionStorage['set_category'] !== ''
        ? window.sessionStorage['set_category']
        : '';
    window.sessionStorage.removeItem('set_category');
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  onSubmit() {
    if (this.dcategory == 'Select Category') {
      alert('Please select a category');
    } else {
      let id = `${this.title
        .trim()
        .replace(/[^a-zA-Z0-9- ]/g, '')
        .split(' ')
        .join('-')
        .toLowerCase()}`;

      const data = {
        id: id.trim().replace(/[^a-zA-Z0-9- ]/g, ''),
        title: this.title.trim(),
        content: this.content.trim(),
        category: this.dcategory,
        timeCreated: Date.now(),
        author: this.username,
        author_avatar: this.avatar,
        likes: 0,
        comments: 0,
        featured: false,
      };

      this._forum.add_topic(data);

      this._resetForm();
    }
  }

  _resetForm() {
    this.title = '';
    this.content = '';
  }

  check_title() {
    if (this.title !== '') {
      this._forum
        .check_title(this.title.trim().split(' ').join('-').toLowerCase())
        .subscribe((doc) => {
          if (doc.exists) {
            this.taken = true;
          } else {
            this.taken = false;
          }
        });
    }
  }
}
