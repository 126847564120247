import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { ForumService } from 'src/app/services/forum.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import * as uuid from 'uuid';
import { AngularEditorConfig } from '@kolkov/angular-editor';

@Component({
  selector: 'app-new-post',
  templateUrl: './new-post.component.html',
  styleUrls: ['./new-post.component.css'],
})
export class NewPostComponent implements OnInit, OnDestroy {

  constructor(
    private _forum: ForumService,
    private _auth: AuthService,
    private _router: Router,
    private _afstorage: AngularFireStorage
  ) {}
  addPostForm: FormGroup;
  defImg =
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/article-image.jpg?alt=media&token=eba8b0bf-7a5b-4594-bccc-3894b01138e3';
  downloadUrl: string;
  categories = [];
  sub: Subscription;
  title: string;
  id: string;
  content = '';
  taken: boolean;
  previewPost = false;

  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };

  ngOnInit() {
    this.addPostForm = new FormGroup({
      title: new FormControl('', Validators.required),
      category: new FormControl('Select Category', Validators.required),
      // content: new FormControl("", Validators.required)
    });

    this._auth.isAuthenticated();
    this.sub = this._forum.get_blog_categories().subscribe((cat) => {
      this.categories = cat;
    });

    if (
      window.sessionStorage.slu_role === 'admin' ||
      window.sessionStorage.slu_role === 'moderator' ||
      window.localStorage.slu_role === 'admin' ||
      window.localStorage.slu_role === 'moderator'
    ) {
    } else {
      this._router.navigate(['/']);
    }
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  async onSubmit() {
    if (this.addPostForm.get('category').value == 'Select Category') {
      alert('Please select a category');
    } else if (!this.downloadUrl) {
      alert('Please a blog image');
    } else {
      const { title, category } = this.addPostForm.value;
      const content: string = this.content
        .trim()
        .split('<img ')
        .join('<img class=\'img-fluid\' ');
      const id = `${title
        .trim()
        .replace(/[^a-zA-Z0-9- ]/g, '')
        .split(' ')
        .join('-')
        .toLowerCase()}`;

      const data = {
        id: id.trim().replace(/[^a-zA-Z0-9- ]/g, ''),
        title,
        content,
        imgUrl: this.downloadUrl.toString(),
        category,
        likes: 0,
        comments: 0,
        timeCreated: Date.now(),
        active: true,
        featured: false,
      };
      this._forum.add_post(data);
      this.addPostForm.reset();
      this.downloadUrl = '';
    }
  }

  upload_blog_image(event) {
    const id = uuid.v4();
    const file = event.target.files[0];
    const filePath = `/posts/images/${id}`;
    const ref = this._afstorage.ref(filePath);
    const task = this._afstorage.upload(filePath, file);

    // get notified when the download URL is available
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe((url) => {
            this.downloadUrl = url;
            this.content += `<br><br><img class="img-fluid" 
              src="${this.downloadUrl}"
            /><br><br>`;
          });
        })
      )
      .subscribe();
  }

  cancel() {
    this.addPostForm.reset();
    this.content = '';
    this.downloadUrl = '';
    this._router.navigate(['/posts']);
  }

  check_title() {
    const id = this.addPostForm.get('title').value;
    if (this.id !== '') {
      this._forum
        .check_blog_title(id.trim().split(' ').join('-').toLowerCase())
        .subscribe((doc) => {
          if (doc.exists) {
            this.taken = true;
          } else {
            this.taken = false;
          }
        });
    }
  }

  category(title: string) {
    return title.split('-').join(' ');
  }
}
