import { BrowserModule, Title } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AngularEditorModule } from '@kolkov/angular-editor';

import { environment } from 'src/environments/environment';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireStorageModule } from '@angular/fire/storage';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ResetPasswordComponent } from './components/auth/reset-password.component';
import { SignInComponent } from './components/auth/sign-in.component';
import { SignUpComponent } from './components/auth/sign-up.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password.component';
import { RecoveryQuestionsComponent } from './components/auth/recovery-questions.component';
import { HeaderComponent } from './shared/header/header.component';
import { FooterComponent } from './shared/footer/footer.component';
import { LoginNavComponent } from './shared/login-nav/login-nav.component';
import { ProfilePreviewComponent } from './shared/profile-preview/profile-preview.component';
import { AuthComponent } from './components/auth/auth.component';
import { AuthService } from './services/auth.service';
import { ForumService } from './services/forum.service';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TopicsComponent } from './components/topics/topics.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { AddTopicComponent } from './components/add-topic/add-topic.component';
import { TopicComponent } from './components/topic/topic.component';
import { BlogCategoriesComponent } from './components/blog-categories/blog-categories.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TrendingComponent } from './components/trending/trending.component';
import { MessagesComponent } from './components/messages/messages.component';
import { ReportedTopicsComponent } from './components/reported-topics/reported-topics.component';
import { ReportedUsersComponent } from './components/reported-users/reported-users.component';
import { AboutComponent } from './components/about/about.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ContentPolicyComponent } from './components/content-policy/content-policy.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { GuidelinesComponent } from './components/guidelines/guidelines.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { PostsComponent } from './components/posts/posts.component';
import { PostComponent } from './components/post/post.component';
import { NewPostComponent } from './components/new-post/new-post.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { UsersComponent } from './components/users/users.component';
import { ManageCategoriesComponent } from './components/manage-categories/manage-categories.component';
import { ManageContactsComponent } from './components/manage-contacts/manage-contacts.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './services/auth.guard';
import { SendNotificationsComponent } from './components/send-notifications/send-notifications.component';
import { UsernameChangesComponent } from './components/username-changes/username-changes.component';
import { PushMessagesComponent } from './components/push-messages/push-messages.component';
import { ResolveCommentsComponent } from './components/resolve-comments/resolve-comments.component';
import { ArchivedComponent } from './components/archived/archived.component';
import { ArchivedContentComponent } from './components/archived-content/archived-content.component';
import { ArchivePostComponent } from './components/archive-post/archive-post.component';
import { AdsenseModule } from 'ng2-adsense';

@NgModule({
  declarations: [
    AppComponent,
    ResetPasswordComponent,
    SignInComponent,
    SignUpComponent,
    ForgotPasswordComponent,
    RecoveryQuestionsComponent,
    HeaderComponent,
    FooterComponent,
    LoginNavComponent,
    ProfilePreviewComponent,
    AuthComponent,
    TopicsComponent,
    CategoryListComponent,
    AddTopicComponent,
    TopicComponent,
    ArchivePostComponent,
    BlogCategoriesComponent,
    ProfileComponent,
    SettingsComponent,
    TrendingComponent,
    MessagesComponent,
    ReportedTopicsComponent,
    ReportedUsersComponent,
    AboutComponent,
    FaqsComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    ContentPolicyComponent,
    DisclaimerComponent,
    GuidelinesComponent,
    ContactsComponent,
    PostsComponent,
    PostComponent,
    NewPostComponent,
    CategoriesComponent,
    UserProfileComponent,
    NotificationsComponent,
    UsersComponent,
    ManageCategoriesComponent,
    ManageContactsComponent,
    NotFoundComponent,
    SendNotificationsComponent,
    UsernameChangesComponent,
    PushMessagesComponent,
    ResolveCommentsComponent,
    ArchivedComponent,
    ArchivedContentComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    NgxCaptchaModule,
    NgxSpinnerModule,
    HttpClientModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    NgxPaginationModule,
    FilterPipeModule,
    AngularEditorModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAuthModule,
    ServiceWorkerModule.register('shutlips-service-worker.js', {
      enabled: environment.production,
    }),
    AdsenseModule.forRoot({
      adClient: environment.adsense_profile_preview.adClient,
    }),
  ],
  providers: [Title, AuthService, ForumService, AuthGuard],
  bootstrap: [AppComponent],
})
export class AppModule {}
