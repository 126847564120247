<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
>
  <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>

<h4>Sign in here</h4>
<form (ngSubmit)="onSubmit()" #signInForm="ngForm">
  <div class="username_input">
    <label>Username</label>
    <i class="fa fa-user-o"></i>
    <input
      class="input_style"
      type="text"
      placeholder="Type your username"
      id="username"
      required
      [(ngModel)]="username"
      autocomplete="username"
      name="u_username"
      #u_username="ngModel"
    />
  </div>
  <div
    *ngIf="!u_username.valid && (u_username.dirty || u_username.touched)"
    class="alert alert-danger alert-sm"
  >
    Username is required.
  </div>
  <div class="password_input">
    <label>Password</label>
    <i class="fa fa-unlock"></i>
    <input
      class="input_style"
      type="password"
      placeholder="Type your password"
      name="email"
      required
      [(ngModel)]="password"
      autocomplete="current-password"
      name="u_password"
      #u_password="ngModel"
      id="password"
    />
    <span
      id="eye"
      class="fa fa-eye-slash field-icon"
      (click)="show_password($event)"
    ></span>
  </div>
  <div
    *ngIf="!u_password.valid && (u_password.dirty || u_password.touched)"
    class="alert alert-danger alert-sm"
  >
    Password is required.
  </div>
  <div class="password_input">
    <div
      class="form-check"
      data-toggle="tooltip"
      data-placement="bottom"
      data-delay="0"
      data-trigger="hover focus"
      title="Unticking this box will log you out after several minutes of inactivity."
      style="max-width: 40%;"
    >
      <input
        class="form-check-input"
        type="checkbox"
        id="remember_me"
        (change)="remember($event)"
        checked
      />
      <label
        class="form-check-label"
        for="defaultCheck1"
        style="margin-left: 0em;"
      >
        Remember me
      </label>
    </div>
  </div>
  <div class="flat-fogot clearfix">
    <div class="text-left">
      <label class="lost_pass">
        <a
          [routerLink]="['/auth', 'forgot-password']"
          title="Forgot Password"
          style="color: #007bff;"
        >
          Forgot password?
        </a>
      </label>
    </div>
  </div>
  <span class="wrap-button">
    <span class="wrap-button">
      <input
        class="submit_bt"
        type="submit"
        value="Sign me in"
        [disabled]="!signInForm.form.valid"
      />
    </span>
  </span>
</form>

<div class="sign_up_wrap text-center">
  <h6>or</h6>
  <div class="sign_up_btn">
    <a
      [routerLink]="['/auth', 'sign-up']"
      title="Sign Up"
      style="color: #007bff;"
    >
      Sign Up!
    </a>
  </div>
</div>
