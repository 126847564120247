<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <!-- End of col-lg-2-->
      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-12">
            <img
              src="./assets/images/1-5.jpg"
              alt="about-banner"
              class="img-fluid"
            />
          </div>
          <div class="col-lg-12 about-title">
            <h6>Frequently Asked Questions</h6>
          </div>
          <div class="col-lg-8 about-content">
            <div class="accordion" id="accordionExample">
              <div class="card">
                <div class="card-header" id="headingOne">
                  <a
                    href="javascript:void(0)"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    <h5 class="mb-0">
                      What is shutlips.com
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>

                <div
                  id="collapseOne"
                  class="collapse show"
                  aria-labelledby="headingOne"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    shutlips.com is an interactive intended platform that aims
                    to enable users be true to themselves by asking or answering
                    questions from other users without fear of being judged,
                    laughed at or shamed. We know there are issues or secrets we
                    all would rather not put a name or face to but have the need
                    to talk about. Well, we ‘gat’ you. This site provides a
                    much-needed anonymity for all subjects and topics to be
                    freely discussed by sharing your questions, fears, secrets
                    and humor while being yourself.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingTwo">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                  >
                    <h5 class="mb-0">
                      How do I post links, questions or start a thread for
                      discussions?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapseTwo"
                  class="collapse"
                  aria-labelledby="headingTwo"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Please utilize the ‘post a link’ box on this site to start
                    up a conversation or discussion with other users and wait
                    for responses from the comment section for further
                    interaction.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="headingThree">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                  >
                    <h5 class="mb-0">
                      Can I delete or edit my post?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapseThree"
                  class="collapse"
                  aria-labelledby="headingThree"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Yes. You can edit your post and comments. You can only
                    delete your posts or comments within one hour of posting.
                    Please use the edit or comment icons provided next to either
                    a post or comment.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading4">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse4"
                    aria-expanded="false"
                    aria-controls="collapse4"
                  >
                    <h5 class="mb-0">
                      Can I use my real name or nickname on this site?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse4"
                  class="collapse"
                  aria-labelledby="heading4"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    It is highly advisable not to use a real name or even a
                    nickname that your friends or families might recognize you
                    with. Create a totally funny or serious false name [whatever
                    floats your boat] to help us protect your identities.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading5">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse5"
                    aria-expanded="false"
                    aria-controls="collapse5"
                  >
                    <h5 class="mb-0">
                      What happens if I don’t remember my password or username?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse5"
                  class="collapse"
                  aria-labelledby="heading5"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    This site does not collect or ask for email addresses (to
                    help us protect your identity) and therefore we do not have
                    means to reset your password. It is highly advised that you
                    remember the security questions used upon creation of an
                    account; as they will be used as a ‘Recovery’ means to reset
                    your password and account. Failure to answer your security
                    questions means you won’t be able to gain access to that
                    account which will be deactivated permanently after 60 days.
                    You would need to create another account with a new username
                    and password to regain access back to the website.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading6">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse6"
                    aria-expanded="false"
                    aria-controls="collapse6"
                  >
                    <h5 class="mb-0">
                      Can I make group friends and be able to create my own
                      group chat forum?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse6"
                  class="collapse"
                  aria-labelledby="heading6"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    No, at least not on this current version.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading7">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse7"
                    aria-expanded="false"
                    aria-controls="collapse7"
                  >
                    <h5 class="mb-0">
                      Can I send private messages to my friends on this site?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse7"
                  class="collapse"
                  aria-labelledby="heading7"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Yes. Click on the “Envelope” icon located at the top right
                    of your home page. This enables you to use the direct
                    message inbox provided to send private messages to any
                    registered user on the platform. You can also use the same
                    function to receive and read your direct message replies.
                    When you have a message, the “envelope’ will have a red sign
                    attached to it. We strongly advise users not to post
                    sensitive or important information that could identify them
                    or have their anonymity cover blown as we can’t protect
                    anyone who decides to share personal or sensitive
                    information about themselves.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading8">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse8"
                    aria-expanded="false"
                    aria-controls="collapse8"
                  >
                    <h5 class="mb-0">
                      How do I keep up with replies from my old comments?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse8"
                  class="collapse"
                  aria-labelledby="heading8"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    The User notification which is the “Bell” will always have a
                    red signal indicating when you have been tagged or mentioned
                    on a post or comment. When the “Bell” is clicked, it will
                    show you excerpts of the comment which you can click for
                    re-direction to the forum category where you were mentioned
                    or tagged.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading9">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse9"
                    aria-expanded="false"
                    aria-controls="collapse9"
                  >
                    <h5 class="mb-0">
                      Why can’t I upload my picture as my profile pic?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse9"
                  class="collapse"
                  aria-labelledby="heading9"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Duhhh….am trying to protect your identity. Please manage our
                    gender avatars.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading10">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse10"
                    aria-expanded="false"
                    aria-controls="collapse10"
                  >
                    <h5 class="mb-0">
                      Why am I called a shutlipper?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse10"
                  class="collapse"
                  aria-labelledby="heading10"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Because of the anonymity associated with Shutlips…
                    everything we say or discuss, stays on this website. You get
                    to interact with users on this site and get to shut your lip
                    because no one knows you and you don’t know any user. Our
                    lips stay shut from learning about people’s secrets,
                    innermost fears or issues and can’t put a name or face to
                    any of them.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading11">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse11"
                    aria-expanded="false"
                    aria-controls="collapse11"
                  >
                    <h5 class="mb-0">
                      What is the meaning of shutlipping?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse11"
                  class="collapse"
                  aria-labelledby="heading11"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    It is the act of interacting anonymously on this website…
                    getting to know people’s secrets, stories and experiences;
                    be a part of it with your comments but can’t judge, shame,
                    laugh, gossip or even spread rumors about them in person
                    because you do not know who anyone else is.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading12">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse12"
                    aria-expanded="false"
                    aria-controls="collapse12"
                  >
                    <h5 class="mb-0">
                      Can I talk about any topic on this website?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse12"
                  class="collapse"
                  aria-labelledby="heading12"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Yes, no topics are off limits, but this site does not
                    promote or allow for pornographic uploads of pics, videos
                    and links. We also prohibit all and any kind of violent or
                    graphic/offensive related contents. Help us to help you by
                    abiding to our shutlips
                    <a href="javascript:void(0)" [routerLink]="['/guidelines']"
                      >moderator guidelines</a
                    >.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading13">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse13"
                    aria-expanded="false"
                    aria-controls="collapse13"
                  >
                    <h5 class="mb-0">
                      Why can’t I share my links or comments on any social media
                      platform?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse13"
                  class="collapse"
                  aria-labelledby="heading13"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Due to the sensitive nature of information on this site and
                    because most social media platforms are interconnected, we
                    can’t risk the reveal of any user’s identity by allowing you
                    to share content from this site. All you do and say on this
                    site, stays on this site. Also, because we do not have any
                    social media affiliations on this site, we implore you to
                    please spread the existence of this site by word of mouth to
                    your friends and families to help us grow.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading14">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse14"
                    aria-expanded="false"
                    aria-controls="collapse14"
                  >
                    <h5 class="mb-0">
                      How can I report abuse or violations?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse14"
                  class="collapse"
                  aria-labelledby="heading14"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Please use the report button found on the post or comment
                    you wish to report, and we will address such issues
                    promptly.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading15">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse15"
                    aria-expanded="false"
                    aria-controls="collapse15"
                  >
                    <h5 class="mb-0">
                      How can I win gifts, prizes, weekend getaways or even
                      holidays on shutlips.com?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse15"
                  class="collapse"
                  aria-labelledby="heading15"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    It’s our desire to reward our users on this platform, to be
                    of help and bring smiles into the lives of shutlippers. Once
                    the site begins to grow, we will update our site on what you
                    can do in order to be a winner of gifts or prizes on this
                    platform. So, make sure you tell your friends and families
                    to register and be a part of our interactive platform. When
                    we grow, we reward you.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading16">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse16"
                    aria-expanded="false"
                    aria-controls="collapse16"
                  >
                    <h5 class="mb-0">
                      Who can use shutlips.com?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse16"
                  class="collapse"
                  aria-labelledby="heading16"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    shutlips.com is a global website but it is geographically
                    targeted for Nigerians and Africans. Nigerians/Africans in
                    the Diaspora has a chance to be part of meaningful
                    conversations trending back here in their country/continent
                    and do have a platform to air their opinions as well. Our
                    ‘Streets are talking’ category allows you to weigh into
                    political, religious or socio-economic issues and have your
                    opinions aired.
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading17">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse17"
                    aria-expanded="false"
                    aria-controls="collapse17"
                  >
                    <h5 class="mb-0">
                      I have an issue, or I have an idea on how this site can be
                      improved?
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse17"
                  class="collapse"
                  aria-labelledby="heading17"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Please use our report button to tell us all about it or send
                    us an email to
                    <a
                      href="javascript:void(0)"
                      href="mailto:secrets@shutlips.com"
                      >secrets@shulips.com</a
                    >
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-header" id="heading18">
                  <a
                    href="javascript:void(0)"
                    class="collapsed"
                    data-toggle="collapse"
                    data-target="#collapse18"
                    aria-expanded="false"
                    aria-controls="collapse18"
                  >
                    <h5 class="mb-0">
                      I have inquiries on how to advertise with or on
                      shutlips.com
                      <span style="float: right"
                        ><i class="fa fa-plus text-primary"></i
                      ></span>
                    </h5>
                  </a>
                </div>
                <div
                  id="collapse18"
                  class="collapse"
                  aria-labelledby="heading18"
                  data-parent="#accordionExample"
                >
                  <div class="card-body">
                    Please click on our contact link button on the homepage and
                    use any of the provided information to get in touch.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <!-- <img src="./assets/images/add-2.png" alt="addsence" class="img-fluid"> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
