<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate">
  <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <!-- End of col-lg-2-->
      <div class="col-lg-7">
        <app-add-topic *ngIf="suspended === 'false'"></app-add-topic>
        <div class="alert alert-danger alert-sm" *ngIf="suspended === 'true'">
          Your account has been suspended, please contact the moderator at
          moderator@shutlips.com
        </div>

        <div class="single_blog text-center text-primary" *ngIf="intro">
          <img class="img-fluid mt-3 mb-3" src="./assets/images/logo-dark.png" alt="shutlips-logo" width="200"
            height="220" />
          <br />
          <small class="mt-2 mb-2">
            Dear {{ username }}, thank you for joining our site. You are now
            officially a shutlipper!!! We hope you enjoy interacting with other
            shutlippers by helping us create an interactive platform with your
            meaningful discussions and opinions. Remember, this is a safe and
            vulnerable environment and no topics are off limits.
          </small>
          <br />
          <br />
          <small>
            Our website has been designed in such a way that without or limited
            internet access you can have access to our website (navigate, read
            and browse through the site but can't submit a post) you will also
            enjoy faster page load-time and better user-experience only when you
            install shutlips on your homescreen. So click on the install
            shutlips button when prompted. Don't forget to click on "Edit
            profile" to subscribe and get notifications and stay up to date with
            responses to your posts and comments.
          </small>
          <br />
          <br />
          <small class="mt-3">
            Happy Shutlipping!!!
          </small>
          <br />
          <!-- PWA banner -->
          <!-- <section id="installBanner" class="pwa-banner text-center">
          </section> -->
          <div class="row">
            <!-- <div class="col-md-6">
              <button id="installBtn" class="btn btn-primary btn-sm">
                Install shutlips.com
              </button>
            </div> -->
            <div class="col-md-12">
              <button class="btn-primary btn-sm btn" (click)="close_intro()">
                Close and continue
              </button>
            </div>
          </div>
        </div>

        <div class="single_blog" *ngIf="!intro">
          <!-- in case you want to show empty message -->
          <div class="row text-center" *ngIf="(topics | filterBy: userFilter).length === 0">
            No matching discussions
          </div>
          <div class="row mb-2 pt-0 pr-0" *ngFor="
              let t of topics
                | filterBy: userFilter
                | paginate: { itemsPerPage: 15, currentPage: p }
            " [ngClass]="{ question: t.featured }">
            <div class="col-lg-2 pt-0 pr-0" id="{{ t.id }}">
              <small class="bg-primary featured d-block d-sm-block d-md-block d-lg-none d-xl-none" *ngIf="t.featured">
                <i class="fa fa-bookmark"></i> featured
              </small>
              <div class="blg-img pt-2">
                <img src="{{ t.author_avatar }}" alt="{{ t.author }}" class="img-fluid" width="80px" height="70px" />
              </div>
            </div>
            <div class="col-lg-10 pt-0 pr-0">
              <small class="bg-primary featured d-none d-sm-none d-md-none d-lg-block d-xl-block" *ngIf="t.featured">
                <i class="fa fa-bookmark"></i> featured
              </small>
              <div class="blog-header-2 pt-3" [ngClass]="{ 'mt-4': t.featured }">
                <h6>
                  <a title="{{ t.title }}" [routerLink]="['/topic', t.id]" (click)="set_page_number(p, t.id)">
                    {{ t.title }}
                  </a>
                </h6>
              </div>
              <div class="author_name_date">
                <small>
                  <span>
                    By
                    <a [routerLink]="['/user-profile', t.author]" style="color: #c154c1;">
                      {{ t.author }}
                    </a>
                    in
                    <a title="{{ t.category }}" [routerLink]="['/categories', t.category]"
                      style="color: #c154c1; text-transform: capitalize;">
                      {{ category(t.category) }}
                    </a>
                    <!-- - {{ dateTime(t.timeCreated) }} -->
                    <span *ngIf="t.edited">
                      | edited - {{ dateTime(t.edited_time) }}
                    </span>
                  </span>
                </small>
              </div>
              <div class="blog-paragrap pr-2">
                <span [innerHtml]="first50(t.content)"></span>
                <small>
                  <a title="{{ t.title }}" [routerLink]="['/topic', t.id]" (click)="set_page_number(p, t.id)">
                    more
                  </a>
                </small>
                <ul class="list-inline mt-2 text-capitalize">
                  <li class="list-inline-item">
                    <i class="fa fa-thumbs-up"></i>
                    {{ t.likes > 0 ? t.likes : "No" }} likes
                  </li>
                  <li class="list-inline-item">
                    <i class="fa fa-comments"></i>
                    {{ t.comments > 0 ? t.comments : "No" }} comments
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>

      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>

<!-- Button trigger modal -->
<button type="button" class="btn btn-primary" data-toggle="modal" data-target="#exampleModalCenter" id="modalbutton"
  style="display: none;">
  subscribe
</button>

<!-- Modal -->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
  aria-hidden="true">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" id="closemodal">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body text-center">
        <img src="./assets/images/favicomatic/favicon-96x96.png" alt="Shutlips Logo" class="img-fluid mb-3" />
        <br />
        <small>Subscribe to shutlips notifications and stay up to date with
          responses to your posts and comments.</small>
        <br />
        <button class="btn btn-primary btn-sm" (click)="subscribe()">
          Subscribe and tap allow
        </button>
      </div>
    </div>
  </div>
</div>