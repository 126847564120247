<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
>
  <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <!-- End of col-lg-2-->
      <div class="col-lg-7 col-12 blog_wrap">
        <div class="single_blog">
          <div class="blog-header text-center">
            <img class="img-fluid mt-3 mb-3" src="./assets/images/logo-dark.png" alt="shutlips-logo" width="200" height="220" />
            <br />
            <h3
              class="about-title text-primary"
              style="text-transform: capitalize;"
            >
              {{ message?.title }}
            </h3>
          </div>
          <div class="blog-paragrap text-center">
            <p>{{ message?.message }}</p>
            <button class="btn-primary btn-sm btn" (click)="close()">
              Close and continue
            </button>
          </div>
        </div>
      </div>

      <!-- End of col-lg-7-->
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
