<header>
  <nav class="navbar navbar-expand-lg navbar-light">
    <div class="container">
      <a class="navbar-brand" [routerLink]="['/']">
        <img
          src="./assets/images/logo-light.png"
          alt="shutlips-logo"
          class="img-fluid"
        />
      </a>
      <!-- <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <span style="font-size:30px;cursor:pointer">
            <ul class="icon-bar" onclick="$('.aside').asidebar('open')">
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </span>
        </li>
      </ul> -->
      <div class="aside">
        <div class="aside-header">
          <span class="close" data-dismiss="aside" aria-hidden="true">
            &times;
          </span>
        </div>
        <div class="aside-contents">
          <ul class="nav-list text-center">
            <li>
              <a [routerLink]="['/about']">About</a>
            </li>
            <li>
              <a [routerLink]="['/faqs']">FAQs</a>
            </li>
            <li>
              <a [routerLink]="['/terms']">Terms</a>
            </li>
            <li>
              <a [routerLink]="['/privacy']">Privacy</a>
            </li>
          </ul>
        </div>
        <div class="menu_social_icons">
          <ul>
            <li>
              <a href="#" target="_blank">
                <i class="fa fa-facebook"></i>
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="fa fa-instagram"></i>
              </a>
            </li>
            <li>
              <a href="#" target="_blank">
                <i class="fa fa-twitter"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </nav>
</header>
