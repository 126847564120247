<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
>
  <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="form-group">
          <input
            type="text"
            [(ngModel)]="post"
            placeholder="post title"
            class="form-control"
          />
        </div>
        <div class="form-group">
          <button (click)="search()" class="btn btn-primary">Search</button>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-3 Categories">
        <h4>All Parent Comments - {{ parent_comments.length }}</h4>
        <div class="media" *ngFor="let c of parent_comments">
          <img
            class="mr-3 rounded-circle"
            src="{{ c.avatarUrl }}"
            alt="{{ c.user }}"
          />
          <div class="media-body" id="{{ c.uid }}">
            <div>
              <div class="main_com">
                <p>{{ c.post }}</p>
                <span class="mt-0 auther_name">
                  <a [routerLink]="['/user-profile', c.user]">{{ c.user }}</a>
                  {{ c.comment }}
                </span>
              </div>
              <div class="comment_relly">
                <ul>
                  <li>
                    {{ dateTime(c.time)
                    }}<span *ngIf="c.edited_at">
                      - edited - {{ dateTime(c.edited_at) }}</span
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="select_parent_comment(c)"
                      ><i class="fa fa-eye"></i> View</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="get_comments(c.time)"
                      ><i class="fa fa-eye"></i> Load</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="load_topics(c.author)"
                      ><i class="fa fa-eye"></i> Load topics</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <h4>All Root Comments - {{ root_comments.length }}</h4>
        <div class="media" *ngFor="let c of root_comments">
          <img
            class="mr-3 rounded-circle"
            src="{{ c.avatarUrl }}"
            alt="{{ c.user }}"
          />
          <div class="media-body" id="{{ c.uid }}">
            <div>
              <div class="main_com">
                <p>{{ c.post }}</p>
                <span class="mt-0 auther_name">
                  <a [routerLink]="['/user-profile', c.user]">{{ c.user }}</a>
                  {{ c.comment }}
                </span>
              </div>
              <div class="comment_relly">
                <ul>
                  <li>
                    {{ dateTime(c.time)
                    }}<span *ngIf="c.edited_at">
                      - edited - {{ dateTime(c.edited_at) }}</span
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="selected_parent_comment = c"
                      ><i class="fa fa-eye"></i> View</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="get_comments(c.time)"
                      ><i class="fa fa-eye"></i> Load</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="load_topics(c.author)"
                      ><i class="fa fa-eye"></i> Load topics</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="update_root_comment(c)"
                      ><i class="fa fa-check"></i> Update</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <h4>All Topic Comments - {{ t_comments.length }}</h4>
        <div class="media" *ngFor="let c of t_comments">
          <img
            class="mr-3 rounded-circle"
            src="{{ c.avatarUrl }}"
            alt="{{ c.user }}"
          />
          <div class="media-body" id="{{ c.uid }}">
            <div>
              <div class="main_com">
                <p>{{ c.post }}</p>
                <span class="mt-0 auther_name">
                  <a [routerLink]="['/user-profile', c.user]">{{ c.user }}</a>
                  {{ c.comment }}
                </span>
              </div>
              <div class="comment_relly">
                <ul>
                  <li>
                    {{ dateTime(c.time)
                    }}<span *ngIf="c.edited_at">
                      - edited - {{ dateTime(c.edited_at) }}</span
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="selected_parent_comment = c"
                      ><i class="fa fa-eye"></i> View</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="load_topics(c.author)"
                      ><i class="fa fa-eye"></i> Load topics</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="update_root_comment(c)"
                      ><i class="fa fa-check"></i> Update</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <h4>All Author Topics - {{ author_topics.length }}</h4>
        <div class="media" *ngFor="let c of author_topics">
          <img
            class="mr-3 rounded-circle"
            src="{{ c.author_avatar }}"
            alt="{{ c.author }}"
          />
          <div class="media-body" id="{{ c.uid }}">
            <div>
              <div class="main_com">
                <p>{{ c.id }}</p>
              </div>
              <div class="comment_relly">
                <ul>
                  <li>
                    {{ dateTime(c.timeCreated) }}
                  </li>
                  <li>
                    <a (click)="set_id = c.id">set id</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 blog_wrap mb-3">
        <h4>Roots Comments</h4>
        <div class="media" *ngFor="let c of roots">
          <img
            class="mr-3 rounded-circle"
            src="{{ c.avatarUrl }}"
            alt="{{ c.user }}"
          />
          <div class="media-body" id="{{ c.uid }}">
            <div>
              <div class="main_com">
                <p>{{ c.post }}</p>
                <span class="mt-0 auther_name">
                  <a [routerLink]="['/user-profile', c.user]">{{ c.user }}</a>
                  {{ c.comment }}
                </span>
              </div>
              <div class="comment_relly">
                <ul>
                  <li>
                    {{ dateTime(c.time)
                    }}<span *ngIf="c.edited_at">
                      - edited - {{ dateTime(c.edited_at) }}</span
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="select_root_comment(c)"
                      ><i class="fa fa-eye"></i> View</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="update_parent_batch(c)"
                      ><i class="fa fa-eye"></i> update</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 blog_wrap mb-3">
        <h4>Parents Comment</h4>
        <div class="media" *ngFor="let c of parents">
          <img
            class="mr-3 rounded-circle"
            src="{{ c.avatarUrl }}"
            alt="{{ c.user }}"
          />
          <div class="media-body" id="{{ c.uid }}">
            <div>
              <div class="main_com">
                <p>{{ c.post }}</p>
                <span class="mt-0 auther_name">
                  <a [routerLink]="['/user-profile', c.user]">{{ c.user }}</a>
                  {{ c.comment }}
                </span>
              </div>
              <div class="comment_relly">
                <ul>
                  <li>
                    {{ dateTime(c.time)
                    }}<span *ngIf="c.edited_at">
                      - edited - {{ dateTime(c.edited_at) }}</span
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="selected_parent_comment = c"
                      ><i class="fa fa-eye"></i> View</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="update_parent_batch(c)"
                      ><i class="fa fa-eye"></i> update</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3 blog_wrap mb-3">
        <h4>Update Comment</h4>
        <button class="btn btn-primary btn-sm" (click)="update_comment()">
          Update
        </button>
        <pre>
          <p>selected parent comment</p>
          {{ selected_parent_comment | json }}
        </pre>
        <pre>
          <p>selected root comment</p>
          {{ selected_root_comment | json }}
        </pre>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 Categories">
        <h2>Comments - {{ topic_comments.length }}</h2>
        <div class="media" *ngFor="let c of topic_comments">
          <img
            class="mr-3 rounded-circle"
            src="{{ c.avatarUrl }}"
            alt="{{ c.user }}"
          />
          <div class="media-body" id="{{ c.uid }}">
            <div>
              <div class="main_com">
                <p>{{ c.post }}</p>
                <span class="mt-0 auther_name">
                  <a [routerLink]="['/user-profile', c.user]">{{ c.user }}</a>
                  {{ c.comment }}
                </span>
              </div>
              <div class="comment_relly">
                <ul>
                  <li>
                    {{ dateTime(c.time) }}
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="select_comment(c)"
                      ><i class="fa fa-eye"></i> View</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      (click)="delete_comment(c.comment, c.uid)"
                      class="ml-2 text-danger"
                      ><i class="fa fa-trash"></i> delete</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      (click)="update_tcpwsc(c)"
                      class="ml-2 text-danger"
                      ><i class="fa fa-check"></i> update</a
                    >
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4">
        <h2>Comment Replies {{ comment_replies.length }}</h2>
        <div *ngFor="let cr of comment_replies">
          <div class="media">
            <a class="pr-3" [routerLink]="['/user-profile', cr.user]">
              <img
                src="{{ cr.avatarUrl }}"
                alt="{{ cr.user }}"
                class="rounded-circle"
              />
            </a>
            <div class="media-body" id="{{ cr.uid }}">
              <div class="main_com">
                <p>{{ cr.post }}</p>
                <span class="mt-0 auther_name">
                  <a [routerLink]="['/user-profile', cr.user]">{{ cr.user }}</a>
                </span>
                <span
                  ><a [routerLink]="['/user-profile', cr.reply_to]"
                    >@{{ cr.reply_to }}</a
                  >
                  {{ cr.comment }}</span
                >
              </div>
              <div class="comment_relly">
                <ul>
                  <li>
                    {{ dateTime(cr.time)
                    }}<span *ngIf="cr.edited_at">
                      - edited -
                      {{ dateTime(cr.edited_at) }}</span
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      class="ml-2 text-danger"
                      (click)="select_comment(cr)"
                      ><i class="fa fa-eye"></i> View</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      (click)="delete_child_comment(cr.comment, cr.uid)"
                      class="ml-2 text-danger"
                      ><i class="fa fa-trash"></i> delete</a
                    >
                  </li>
                  <li>
                    <a
                      href="javascript:void(0)"
                      (click)="update_tcrpwsc(cr)"
                      class="ml-2 text-danger"
                      ><i class="fa fa-check"></i> update</a
                    >
                  </li>
                  <!-- <li>
                    <a
                      href="javascript:void(0)"
                      (click)="update_root_comment(cr)"
                      class="ml-2 text-danger"
                      ><i class="fa fa-check"></i> update</a
                    >
                  </li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-lg-4 blog_wrap mb-3">
        <h2>Selected Comment</h2>
        <pre>
          {{ selected_comment | json }}
        </pre>
      </div>
    </div>
  </div>
</section>
