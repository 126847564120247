<!-- FOOTER -->
<footer *ngIf="display === true">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <ul class="footer_menu">
          <li>
            <a [routerLink]="['/faqs']" (click)="clear_pn()">
              FAQs
            </a>
          </li>
          <li>
            <a [routerLink]="['/terms-of-service']" (click)="clear_pn()">
              Terms
            </a>
          </li>
          <li>
            <a [routerLink]="['/privacy-policy']" (click)="clear_pn()">
              Privacy
            </a>
          </li>
          <li>
            <a [routerLink]="['/content-policy']" (click)="clear_pn()">
              Content Policy
            </a>
          </li>
          <li>
            <a [routerLink]="['/disclaimer']" (click)="clear_pn()">
              Website Disclaimer
            </a>
          </li>
          <li>
            <a [routerLink]="['/guidelines']" (click)="clear_pn()">
              Moderator Guidelines
            </a>
          </li>
        </ul>
      </div>
      <div class="col-lg-8 footer_miffle_part">
        <p>
          &copy; {{ currentYear }} shutlips.com - {{ version }} - All Rights
          Reserved. See
          <a [routerLink]="['/trending']">today's trending topics</a>
          <span
            style="color: #e1340f;"
            *ngFor="let verse of verses"
            class="ml-3"
          >
            {{ verse.verse }}
          </span>
        </p>
      </div>
      <div class="col-lg-4 footer_social_icons">
        <ul>
          <li>
            <a href="https://www.facebook.com/shutlips" target="_blank">
              <i class="fa fa-facebook"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/Shutlips" target="_blank">
              <i class="fa fa-instagram"></i>
            </a>
          </li>
          <li>
            <a href="https://www.twitter.com/shutlips_brand" target="_blank">
              <i class="fa fa-twitter"></i>
            </a>
          </li>
          <!-- <li>
            <a
              href="https://www.linkedin.com/company/shutlips-com"
              target="_blank"
            >
              <i class="fa fa-linkedin"></i>
            </a>
          </li> -->
        </ul>
      </div>
    </div>
  </div>
</footer>
