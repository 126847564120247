<app-login-nav></app-login-nav>

<section id="banner">
  <div class="overlay">
    <div class="container">
      <div class="row">
        <div class="col-lg-8 col-12">
          <h2 class="bn_header">
            <small class="text-right bg-primary age_warning">
              18+
            </small>
            <br />
            Shutlips helps you anonymously connect, discuss and share vulnerable
            experiences and stories you would rather not put a face or name to.
            <br /><br />
            Visit the
            <a href="/posts" class="bg-primary blog_link"
              ><small class="text-white">shutlips blog</small></a
            >
            with no sign in required.
          </h2>
        </div>
        <div class="col-lg-4">
          <div class="login_form">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
