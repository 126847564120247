<ngx-spinner
  bdColor="rgba(51,51,51,0.8)"
  size="medium"
  color="#fff"
  type="ball-clip-rotate"
>
  <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <!-- End of col-lg-2-->
      <div class="col-lg-7 col-12 blog_wrap">
        <!-- banner -->
        <!-- <div class="single_blog banner text-center mb-3">
          <a [routerLink]="['/blog-category/tiny-love-stories']">
            <h3 class="about-title">
              Click to read the latest entries for #shutlipstinystories
            </h3>
            <p class="text-white p-2">
              the stories are short but the love is real
            </p>
            <img
              src="./assets/images/sts-banner.jpg"
              alt="tiny-stories"
              class="img-fluid"
              style="width: 100%;"
            />
          </a>
        </div> -->
        <!-- in case you want to show empty message -->
        <div
          class="single_blog text-center"
          *ngIf="(posts | filterBy: userFilter).length === 0"
        >
          No matching discussions
        </div>

        <div
          class="single_blog pt-0 pr-0"
          *ngFor="
            let post of posts
              | filterBy: userFilter
              | paginate: { itemsPerPage: 15, currentPage: p };
            let i = index
          "
        >
          <div class="row pt-0 pr-0">
            <div class="col-lg-4 col-sm-6">
              <small
                class="bg-primary featured d-block d-sm-block d-md-block d-lg-none d-xl-none"
                *ngIf="post.featured"
              >
                <i class="fa fa-bookmark"></i> featured
              </small>
              <div class="blg-img">
                <img
                  src="{{ post.imgUrl }}"
                  alt="{{ post.title }}"
                  class="img-fluid mt-3"
                />
              </div>
            </div>
            <div class="col-lg-8 col-sm-6 pt-0 pr-0">
              <small
                class="bg-primary featured d-none d-sm-none d-md-none d-lg-block d-xl-block"
                *ngIf="post.featured"
              >
                <i class="fa fa-bookmark"></i> featured
              </small>
              <div
                class="blog-header-2 pt-3 pr-3"
                [ngClass]="{ 'mt-4': post.featured }"
              >
                <h6>
                  <a
                    title="{{ post.title }}"
                    [routerLink]="['/post', post.id]"
                    (click)="set_page_number(p, post.id)"
                  >
                    {{ post.title }}
                  </a>
                </h6>
              </div>
              <div class="author_name_date pr-3">
                <small>
                  <span>
                    Posted by Nonye in
                    <a
                      title="{{ post.category }}"
                      [routerLink]="['/blog-category', post.category]"
                      style="color: #4183c4; text-transform: capitalize;"
                    >
                      {{ category(post.category) }}
                    </a>
                    <span> - {{ wpm(post.content) }}</span>
                    <!-- - {{ dateTime(post.timeCreated) }} -->
                  </span>
                  <!-- <span *ngIf="post.edited">
                    | edited
                  </span> -->
                </small>
              </div>
              <div class="blog-paragrap pr-3">
                <p [innerHtml]="first50(post.content)"></p>
              </div>
            </div>
          </div>
        </div>

        <div class="text-center">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
      <!-- End of col-lg-7-->
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>

<!-- <div class="articles-footer">
  <ul class="articles-footer-info">
    <li><a title="{{post.title}}" class="light-link" (click)="addLike(post.id)"><i
          [ngClass]="like ? 'pe-7s-like2' : 'danger'"></i> </a></li>
    <li><a title="{{post.title}}" class="light-link" (click)="addDislike(post.id)"><i
          [ngClass]="like ? 'pe-7s-close-circle' : 'danger'"></i> </a></li>
    <li><a title="{{post.title}}" class="light-link" (click)="addFavorite(post.id)"><i class="pe-7s-like"></i>
      </a></li>
    <li><a title="{{post.title}}" class="light-link"><i class="pe-7s-look"></i> {{post.viewcount}}</a></li>

  </ul>
  <a title="{{post.title}}" class="btn" [routerLink]="PostUrl(post.id)" style="margin-top: 0px;">Read</a>
</div> -->
