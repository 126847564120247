<div class="headercontent">
  <div class="circle sunset"></div>
  <div class="circle coolice"></div>
  <div class="circle timber"></div>
  <div class="circle pig"></div>
  <div class="circle friday"></div>
</div>

<div class="relative">
  <h1>404</h1>
  <h4>The page you are looking for may have been deleted or doesn't exist.</h4>
  <a class="btn btn-primary btn-sm mb-3" href="/">
    Go back to our Homepage
  </a>
</div>
