import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ForumService } from 'src/app/services/forum.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import * as uuid from 'uuid';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { AngularFirestore } from '@angular/fire/firestore';
import * as read_time from 'word-per-minute';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class PostComponent implements OnInit, OnDestroy {
  id: string;
  sub: Subscription;
  post = [];
  categories = [];
  edit: boolean;
  editPostForm: FormGroup;
  content: string;
  title: string;
  blog_category: string;
  active: boolean;
  featured: boolean;
  defImg =
    'https://firebasestorage.googleapis.com/v0/b/shutlips-c14d7.appspot.com/o/article-image.jpg?alt=media&token=eba8b0bf-7a5b-4594-bccc-3894b01138e3';
  downloadUrl: string;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [['bold']],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  post_uid: string;
  role: string;
  admin: boolean;
  adClient = environment.adsense_profile_preview.adClient;
  slotID = environment.adsense_blog_post.slotID;

  constructor(
    private _spinner: NgxSpinnerService,
    private _forum: ForumService,
    private _route: ActivatedRoute,
    private _afstorage: AngularFireStorage,
    private _share: NgNavigatorShareService,
    private _afs: AngularFirestore
  ) {
    this.editPostForm = new FormGroup({
      title: new FormControl('', Validators.required),
      category: new FormControl('Select Category', Validators.required),
    });
  }

  ngOnInit() {
    this._spinner.show();
    if (
      window.sessionStorage['slu_role'] == 'admin' ||
      window.sessionStorage['slu_role'] == 'moderator' ||
      window.localStorage['slu_role'] == 'admin' ||
      window.localStorage['slu_role'] == 'moderator'
    ) {
      this.admin = true;
    }
    this.id = this._route.snapshot.paramMap.get('id');

    this.sub = this._forum.get_post(this.id).subscribe((p: any) => {
      this.post = p;
      this.active = p[0].active ? p[0].active : true;
      this._spinner.hide();
    });
    this.sub = this._forum.get_blog_categories().subscribe((cat) => {
      this.categories = cat;
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  dateTime(dt: string) {
    return moment(dt).fromNow();
  }

  category(title: string) {
    return title.split('-').join(' ');
  }

  edit_post(p) {
    this.edit = !this.edit;
    this.content = p.content;
    this.title = p.title;
    this.blog_category = p.category;
    this.downloadUrl = p.imgUrl;
    this.post_uid = p.uid;
    this.featured = p.featured;
  }

  delete_post(p) {
    let con = confirm('Are you sure you want to delete this post?');
    if (con) {
      this._forum.delete_post(p.id);
    }
  }

  async onSubmit() {
    let content: string = this.content
      .trim()
      .split('<img ')
      .join("<img class='img-fluid' ");
    const data = {
      id: this.post[0].id,
      title: this.title.trim(),
      content,
      imgUrl: this.downloadUrl,
      category: this.blog_category,
      active: this.active,
      featured: this.featured,
    };
    this._forum.edit_post(data, this.post_uid);
    this.title = '';
    this.blog_category = '';
    this.content = '';
    this.edit = !this.edit;
  }

  upload_blog_image(event) {
    const id = uuid.v4();
    const file = event.target.files[0];
    const filePath = `/posts/images/${id}`;
    const ref = this._afstorage.ref(filePath);
    const task = this._afstorage.upload(filePath, file);

    // get notified when the download URL is available
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          ref.getDownloadURL().subscribe((url) => {
            this.downloadUrl = url;
          });
        })
      )
      .subscribe();
  }

  share = (title: string): void => {
    if (this.admin) {
      this._share
        .share({
          title,
          text: 'Check out this interesting blog post on shutlips - ',
          url: 'https://shutlips.com/post/' + this.id,
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else {
      this._share
        .share({
          title,
          text: '',
          url: 'https://shutlips.com/post/' + this.id,
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  check_if_mobile = (): boolean => {
    let bool: boolean;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  };

  add_to_featured = (uid: string) => {
    this._forum.add_post_to_featured(uid);
  };

  remove_from_featured = (uid: string) => {
    this._forum.remove_post_from_featured(uid);
  };

  onFileUploadRequest = (event: any) => {
    console.log(event);
  };

  onFileUploadResponse = (event: any) => {
    console.log(event);
  };

  onDrop = (event: any) => {
    console.log(event);
  };

  wpm = (text: string) => {
    let options: any = {
      language: 'en-us',
      wordsPerMinute: 150,
    };
    const arr = read_time(text, options).text.split(' ');
    return `${arr[0]} ${+arr[0] > 1 ? arr[1] + 's' : arr[1]} ${arr[2]}`;
  };
}
