import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import * as CryptoJS from "crypto-js";
import Swal from "sweetalert2";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.css"],
})
export class ResetPasswordComponent implements OnInit {
  new_password: string;
  confirm_password: string;
  errors = [];
  mismatch: boolean;

  constructor(private _auth: AuthService, private _router: Router) {}

  ngOnInit() {
    if (
      !window.sessionStorage["slu_username_fp"] &&
      !window.sessionStorage["slu_username_rp"] &&
      window.sessionStorage["slu_username_fp"] == "" &&
      window.sessionStorage["slu_username_rp"] != "reset_password"
    ) {
      this._router.navigate(["/auth", "forgot-password"]);
    }
  }

  //The set method is use for encrypt the value.
  set(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var encrypted = CryptoJS.AES.encrypt(
      CryptoJS.enc.Utf8.parse(value.toString()),
      key,
      {
        keySize: 128 / 8,
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    return encrypted.toString();
  }

  //The get method is use for decrypt the value.
  get(keys, value) {
    var key = CryptoJS.enc.Utf8.parse(keys);
    var iv = CryptoJS.enc.Utf8.parse(keys);
    var decrypted = CryptoJS.AES.decrypt(value, key, {
      keySize: 128 / 8,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    });

    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  onResetPassword() {
    var p = this.set("123456$#@$^@1ERF", "" + this.new_password.trim());

    const data = {
      password: p.toString(),
    };

    if (this.new_password.trim() === this.confirm_password.trim()) {
      this._auth.change_password(data);
    } else {
      Swal.fire({
        title: "Error!",
        text: `The passwords you entered does not match`,
        type: "error",
        confirmButtonText: "Cool",
      });
      this.new_password = "";
      this.confirm_password = "";
    }

    // this.new_password = "";
    // this.confirm_password = "";
  }

  check_password_match() {
    if (this.new_password.trim() !== this.confirm_password.trim()) {
      this.mismatch = true;
    } else {
      this.mismatch = false;
    }
  }
}
