<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <!-- End of col-lg-2-->
      <div class="col-lg-7">
        <div class="single_blog">
          <div class="row">
            <div class="col-lg-12 text-center mb-3">
              <h4>Reported Users</h4>
              <hr />
            </div>
            <!-- in case you want to show empty message -->
            <div
              class="col-lg-12 text-center"
              *ngIf="reported_users.length === 0"
            >
              There are no reported discussions at the moment
            </div>
            <div
              class="col-lg-12 mb-5"
              *ngFor="
                let rt of reported_users
                  | paginate: { itemsPerPage: 15, currentPage: p }
              "
            >
              <div class="blog-header-2">
                <h6>
                  <a
                    [routerLink]="['/user-profile', rt.reported_by]"
                    style="color: #c154c1"
                  >
                    {{ rt.reported_by }}
                  </a>
                  reported
                  <a
                    [routerLink]="['/user-profile', rt.user]"
                    style="color: #c154c1"
                  >
                    {{ rt.user }}
                  </a>
                </h6>
              </div>
              <div class="author_name_date">
                <small>Reasons being - {{ rt.reason }}</small>
              </div>
            </div>
          </div>
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>

      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
