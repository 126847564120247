<h4>Recovery Questions</h4>
<form (ngSubmit)="onSubmit()" #recoveryQForm="ngForm" class="login">
  <div class="username_input form-group">
    <label for="question_one">Security Question 1&nbsp;*</label>
    <select
      class="form-control"
      id="question_one"
      required
      [(ngModel)]="q_one"
      name="question_one"
      #u_question_one="ngModel"
    >
      <option selected="" disabled>
        Select a security question
      </option>
      <option *ngFor="let q of questions1" [value]="q">
        {{ q }}
      </option>
    </select>
  </div>
  <div
    *ngIf="
      u_question_one.invalid && (u_question_one.dirty || u_question_one.touched)
    "
    class="alert alert-danger"
  >
    Security question is required.
  </div>
  <div class="username_input form-group">
    <label for="answer_one">Answer&nbsp;*</label>
    <input
      type="text"
      class="form-control"
      id="answer_one"
      required
      [(ngModel)]="answer_one"
      name="u_answer_one"
      #u_answer_one="ngModel"
      [class.is-valid]="u_answer_one.valid"
      placeholder="Answer to security question 1"
    />
  </div>
  <div
    *ngIf="!u_answer_one.valid && (u_answer_one.dirty || u_answer_one.touched)"
    class="invalid-feedback"
  >
    Answer is required.
  </div>
  <div class="username_input form-group">
    <label for="question_two">Security Question 2&nbsp;*</label>
    <select
      class="form-control"
      id="question_two"
      required
      [(ngModel)]="q_two"
      name="question_two"
      #u_question_two="ngModel"
    >
      <option selected="" disabled>
        Select a security question
      </option>
      <option *ngFor="let q of questions2" [value]="q">
        {{ q }}
      </option>
    </select>
  </div>
  <div
    *ngIf="
      u_question_two.invalid && (u_question_two.dirty || u_question_two.touched)
    "
    class="alert alert-danger"
  >
    Security question is required.
  </div>
  <div class="username_input form-group">
    <label for="answer_two">Answer&nbsp;*</label>
    <input
      type="text"
      class="form-control"
      id="answer_two"
      required
      [(ngModel)]="answer_two"
      name="u_answer_two"
      #u_answer_two="ngModel"
      [class.is-valid]="u_answer_two.valid"
      placeholder="Answer to security question two"
    />
  </div>
  <div
    *ngIf="!u_answer_two.valid && (u_answer_two.dirty || u_answer_two.touched)"
    class="invalid-feedback"
  >
    Answer is required.
  </div>

  <span class="wrap-button">
    <span class="wrap-button">
      <input
        class="submit_bt"
        type="submit"
        value="Proceed"
        [disabled]="!recoveryQForm.form.valid"
      />
    </span>
  </span>
</form>

<div class="sign_up_wrap text-center">
  <h6>or</h6>
  <div class="sign_up_btn">
    <a [routerLink]="['/auth', 'sign-up']" title="Sign Up" style="color: #007bff;">
      Sign Up!
    </a>
  </div>
</div>