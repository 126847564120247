import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { ForumService } from "src/app/services/forum.service";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { Router } from "@angular/router";

@Component({
  selector: "app-reported-users",
  templateUrl: "./reported-users.component.html",
  styleUrls: ["./reported-users.component.css"],
  encapsulation: ViewEncapsulation.None,
})
export class ReportedUsersComponent implements OnInit, OnDestroy {
  reported_users = [];
  sub: Subscription;
  p: number = 1;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _router: Router
  ) {}

  ngOnInit() {
    this._auth.isAuthenticated();
    this.sub = this._forum
      .get_reported_users()
      .subscribe((ru) => (this.reported_users = ru));

    if (
      window.sessionStorage["slu_role"] == "admin" ||
      window.sessionStorage["slu_role"] == "moderator" ||
      window.localStorage["slu_role"] == "admin" ||
      window.localStorage["slu_role"] == "moderator"
    ) {
    } else {
      this._router.navigate(["/"]);
    }
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  dateTime(dt: string) {
    return moment(dt).fromNow(true);
  }
}
