<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <!-- End of col-lg-2-->
      <div class="col-lg-7">
        <div class="single_blog">
          <div class="row">
            <div class="col-lg-12 text-center mb-3">
              <h4>Reported Discussions</h4>
              <hr />
            </div>
            <!-- in case you want to show empty message -->
            <div
              class="col-lg-12 text-center"
              *ngIf="reported_topics.length === 0"
            >
              There are no reported discussions at the moment
            </div>
            <div
              class="col-lg-12 mb-5"
              *ngFor="
                let rt of reported_topics
                  | paginate: { itemsPerPage: 15, currentPage: p }
              "
            >
              <div class="blog-header-2">
                <h6>
                  Discussion -
                  <a
                    [routerLink]="['/topic', rt.topic]"
                    style="color: #c154c1"
                    >{{ rt.title }}</a
                  >
                </h6>
              </div>
              <div class="author_name_date">
                <small>
                  <span
                    >Author -
                    <a
                      [routerLink]="['/user-profile', rt.author]"
                      style="color: #c154c1"
                      >{{ rt.author }}</a
                    >
                    | Reported by -
                    <a
                      [routerLink]="['/user-profile', rt.reported_by]"
                      style="color: #c154c1"
                      >{{ rt.reported_by }}</a
                    >
                  </span>
                </small>
              </div>
              <div class="blog-paragrap">
                <small
                  ><b>{{ rt.reason }}</b></small
                >
              </div>
            </div>
          </div>
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>

      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
