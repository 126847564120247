import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { FilterPipe } from 'ngx-filter-pipe';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-blog-categories',
  templateUrl: './blog-categories.component.html',
  styleUrls: ['./blog-categories.component.css'],
})
export class BlogCategoriesComponent implements OnInit, OnDestroy {
  id: string;
  new_category: string;
  category_topics = [];
  categories = [];
  blog_categories = [];
  show_delete_category = false;
  sub: Subscription;
  admin: boolean = false;
  p: number = 1;
  userFilter: any;

  constructor(
    private _spinner: NgxSpinnerService,
    private _forum: ForumService,
    private _route: ActivatedRoute,
    private filterPipe: FilterPipe
  ) {
    this._forum.changeMessage('');

    this.p =
      window.sessionStorage['prev-page'] !== undefined
        ? window.sessionStorage['prev-page']
        : this.p;
  }

  ngOnInit() {
    this._spinner.show();
    this.sub = this._route.paramMap.subscribe((d) => {
      this.id = d.get('id');

      this.sub = this._forum
        .get_blog_categories()
        .subscribe((bc) => (this.blog_categories = bc));

      this.sub = this._forum
        .get_post_category(this.id)
        .subscribe((c) => (this.categories = c));

      this.sub = this._forum
        .get_blog_category(d.get('id'))
        .subscribe((topics) => {
          this.category_topics = topics;

          this.filterPipe.transform(this.category_topics, { title: '' });
        });

      this._spinner.hide();

      this.sub = this._forum.currentSearch.subscribe((m) => (m = ''));
    });

    if (
      window.sessionStorage['slu_role'] === 'admin' ||
      window.sessionStorage['slu_role'] === 'moderator' ||
      window.localStorage['slu_role'] === 'admin' ||
      window.localStorage['slu_role'] === 'moderator'
    ) {
      this.admin = true;
    }

    this._forum.currentSearch.subscribe((message) => {
      this.userFilter = { title: message };
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();

    this._forum.search = '';
  }

  category(category: string) {
    return category.split('-').join(' ');
  }

  set_category() {
    window.sessionStorage['set_category'] = this.id;
  }

  dateTime(dt: string) {
    return moment(dt).fromNow();
  }

  first30(content: string) {
    return `${content.substring(0, 50)}...`;
  }

  first50(content: string): string {
    if (content.length >= 200) {
      return `${content.substring(0, 200)}...`;
    } else {
      return content;
    }
  }

  move_category() {
    this.show_delete_category = !this.show_delete_category;
  }

  onSubmit() {
    let data = {
      title: this.new_category.trim(),
    };

    this._forum.move_category(this.id, data);
  }

  set_page_number(page: number, id: string) {
    window.sessionStorage['prev-page'] = page;
    window.sessionStorage['scroll-to'] = id;
  }
}
