import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: ["./forgot-password.component.css"],
})
export class ForgotPasswordComponent implements OnInit {
  errors = [];
  username: string;

  constructor(private _auth: AuthService) {}

  ngOnInit() {}

  onSubmit() {
    this._auth.forgot_password(this.username.trim());
  }
}
