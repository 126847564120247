<h4>Create a free account</h4>
<form (ngSubmit)="onSubmit()" #registerForm="ngForm" class="login">
  <div class="username_input form-group">
    <label>Username</label>
    <i class="fa fa-user-o"></i>
    <input
      class="input_style"
      type="text"
      placeholder="type your username"
      id="username"
      minlength="2"
      required
      [(ngModel)]="username"
      name="u_username"
      #u_username="ngModel"
      (input)="check_username()"
    />
  </div>
  <div
    *ngIf="u_username.invalid && (u_username.dirty || u_username.touched)"
    class="alert alert-danger alert-sm"
  >
    <div *ngIf="u_username.errors.required">
      Username is required.
    </div>
    <div *ngIf="u_username.errors.minlength">
      Username has to be 2 or more characters.
    </div>
  </div>
  <div class="alert alert-danger alert-sm" *ngIf="taken && username">
    {{ username }} has been taken, try another
  </div>
  <div class="alert alert-success alert-sm" *ngIf="!taken && username">
    <small>
      <b>{{ username }} is available</b>
    </small>
  </div>

  <div class="username_input form-group">
    <label>Age</label>
    <select
      class="form-control"
      id="age"
      required
      [(ngModel)]="age_range"
      name="age"
      #u_age="ngModel"
    >
      <option selected="" disabled>Select age range</option>
      <option value="16 - 20">16 - 20</option>
      <option value="21 - 30">21 - 30</option>
      <option value="31 - 40">31 - 40</option>
      <option value="41 - 50">41 - 50</option>
      <option value="51 - 60">51 - 60</option>
      <option value="60 and above">60 and above</option>
    </select>
  </div>
  <div
    *ngIf="u_age.invalid && (u_age.dirty || u_age.touched)"
    class="alert alert-danger alert-sm"
  >
    Age is required.
  </div>

  <small class="text-primary"><b>- passwords are case sensitive</b></small>
  <div class="username_input form-group">
    <label>Password</label>
    <i class="fa fa-unlock"></i>
    <input
      class="input_style"
      type="password"
      placeholder="Type your password"
      name="password"
      id="password"
      required
      [(ngModel)]="password"
      name="u_password"
      #u_password="ngModel"
      minlength="6"
      maxlength="20"
    />
    <span
      id="eye"
      class="fa fa-eye-slash field-icon"
      (click)="show_password($event)"
    ></span>
  </div>

  <div
    *ngIf="u_password.invalid && (u_password.dirty || u_password.touched)"
    class="alert alert-danger alert-sm"
  >
    <span *ngIf="u_password.errors.required">
      Password is required.
    </span>
    <span *ngIf="u_password.errors.minlength">
      Password must be 6 characters or more.
    </span>
    <span *ngIf="u_password.errors.maxlength">
      Password must be 20 characters or less.
    </span>
  </div>

  <div class="username_input form-group">
    <label>Confirm Password</label>
    <i class="fa fa-unlock"></i>
    <input
      class="input_style"
      type="password"
      name="confirm_password"
      required
      minlength="6"
      maxlength="20"
      [(ngModel)]="confirm_password"
      name="u_confirm_password"
      #u_confirm_password="ngModel"
      placeholder="Confirm your password"
      (input)="check_password_match()"
    />
  </div>
  <div
    *ngIf="
      u_confirm_password.invalid &&
      (u_confirm_password.dirty || u_confirm_password.touched)
    "
    class="alert alert-danger alert-sm"
  >
    <span *ngIf="u_confirm_password.errors.required">
      Password is required.
    </span>
    <span *ngIf="u_confirm_password.errors.minlength">
      Password must be 6 characters or more.
    </span>
    <span *ngIf="u_confirm_password.errors.maxlength">
      Password must be 20 characters or less.
    </span>
  </div>
  <div class="alert alert-sm alert-danger" *ngIf="mismatch">
    Confirm password doesn't match
  </div>

  <div class="username_input form-group">
    <label for="gender">Gender&nbsp;*</label>
    <select
      class="form-control"
      id="gender"
      required
      [(ngModel)]="select_gender"
      (change)="setAvatar()"
      name="gender"
      #u_gender="ngModel"
    >
      <option selected="" disabled>Select gender</option>
      <option *ngFor="let g of genders" [value]="g">{{ g }}</option>
    </select>
  </div>
  <div
    *ngIf="u_gender.invalid && (u_gender.dirty || u_gender.touched)"
    class="alert alert-danger alert-sm"
  >
    Gender is required.
  </div>

  <span class="wrap-button">
    <span class="wrap-button">
      <input
        class="submit_bt"
        type="button"
        value="Choose Avatar"
        (click)="show_avatar()"
        style="margin-bottom: 1em;"
        required
      />
    </span>
  </span>
  <img
    src="{{ avatarUrl }}"
    alt="user_avatar"
    class="img-fluid img-rounded"
    style="display:inline-block;width: 100px;height:100px"
    *ngIf="selected"
  />
  <div
    class="card card-body"
    *ngIf="display_avatar"
    style="display:inline-block"
  >
    <div
      class="card card-body"
      *ngIf="display_avatar"
      style="display: inline-block"
    >
      <img
        *ngFor="let img of imgUrl; let i = index"
        src="{{ img }}"
        alt="user_avatar"
        class="img-fluid img-rounded mr-2"
        style="display:inline-block !important;width: 43px;height:45px;"
        (click)="select_avatar(img, i)"
        id="image-{{ i }}"
      />
    </div>
  </div>

  <div class="username_input form-group">
    <label for="question_one">Security Question 1&nbsp;*</label>
    <select
      class="form-control"
      id="question_one"
      required
      [(ngModel)]="q_one"
      name="question_one"
      #u_question_one="ngModel"
    >
      <option selected="" disabled>
        Select a security question
      </option>
      <option *ngFor="let q of questions1" [value]="q">
        {{ q }}
      </option>
    </select>
  </div>
  <div
    *ngIf="
      u_question_one.invalid && (u_question_one.dirty || u_question_one.touched)
    "
    class="alert alert-danger alert-sm"
  >
    Security question is required.
  </div>

  <div class="username_input form-group">
    <label for="answer_one">Answer&nbsp;*</label>
    <input
      type="text"
      class="form-control"
      id="answer_one"
      required
      [(ngModel)]="answer_one"
      name="u_answer_one"
      #u_answer_one="ngModel"
      placeholder="Answer to security question 1"
      autocomplete="false"
    />
  </div>
  <div
    *ngIf="u_answer_one.invalid && (u_answer_one.dirty || u_answer_one.touched)"
    class="alert alert-danger alert-sm"
  >
    Answer is required.
  </div>

  <div class="username_input form-group">
    <label for="question_two">Security Question 2&nbsp;*</label>
    <select
      class="form-control"
      id="question_two"
      required
      [(ngModel)]="q_two"
      name="question_two"
      #u_question_two="ngModel"
    >
      <option selected="" disabled>
        Select a security question
      </option>
      <option *ngFor="let q of questions2" [value]="q">
        {{ q }}
      </option>
    </select>
  </div>
  <div
    *ngIf="
      u_question_two.invalid && (u_question_two.dirty || u_question_two.touched)
    "
    class="alert alert-danger alert-sm"
  >
    Security question is required.
  </div>

  <div class="username_input form-group">
    <label for="answer_two">Answer&nbsp;*</label>
    <input
      type="text"
      class="form-control"
      id="answer_two"
      required
      [(ngModel)]="answer_two"
      name="u_answer_two"
      #u_answer_two="ngModel"
      placeholder="Answer to security question two"
      autocomplete="false"
    />
  </div>
  <div
    *ngIf="u_answer_two.invalid && (u_answer_two.dirty || u_answer_two.touched)"
    class="alert alert-danger alert-sm"
  >
    Answer is required.
  </div>

  <div class="form-group mb-3 text-center">
    <ngx-recaptcha2
      #captchaElem
      [siteKey]="'6LfSKboUAAAAACmvGUvrki4QkvC99k4sYsrNBnFq'"
      (success)="handleSuccess($event)"
      [useGlobalDomain]="false"
      [ngModel]="recaptcha"
      [ngModelOptions]="{ standalone: true }"
      [theme]="theme"
      [size]="size"
    ></ngx-recaptcha2>
  </div>

  <span class="wrap-button">
    <span class="wrap-button">
      <input
        class="submit_bt"
        type="submit"
        value="Create My Account"
        [disabled]="
          registerForm.form.invalid || taken || mismatch || recaptcha === ''
        "
      />
    </span>
  </span>
</form>
<div class="sign_up_wrap text-center">
  <h6>or</h6>
  <div class="sign_up_btn">
    <a
      [routerLink]="['/auth', 'sign-in']"
      title="Sign In"
      style="color: #007bff;"
    >
      Sign In!
    </a>
  </div>
</div>
