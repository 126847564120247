<!-- HEADER -->
<nav class="navbar navbar-expand-lg navbar-light" *ngIf="display === true">
  <div class="container">
    <a class="navbar-brand" [routerLink]="['/']" title="Logo">
      <img src="./assets/images/logo-dark.png" alt="Shutlips Logo" class="img-fluid" />
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <i class="fa fa-bars"></i>
    </button>

    <div class="collapse navbar-collapse text-center" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li class="nav-item">
          <a class="nav-link text-capitalize" [routerLink]="['']" routerLinkActive="active"
            [routerLinkActiveOptions]="{ exact: true }" title="Shutlips Forum">
            Forum
          </a>
        </li>
        <li class="nav-item">
          <a [routerLink]="['/about']" (click)="clear_pn()" class="nav-link text-capitalize" routerLinkActive="active"
            title="About Shutlips">
            About
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" [routerLink]="['/trending']" routerLinkActive="active"
            title="Trending Topics" (click)="clear_pn()">
            Trending
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" [routerLink]="['/posts']" routerLinkActive="active" title="Shutlips Blog"
            (click)="clear_pn()">
            Blog
          </a>
        </li>
        <li class="nav-item">
          <a class="nav-link text-capitalize" [routerLink]="['/contact']" routerLinkActive="active"
            title="Shutlips Contacts" (click)="clear_pn()">
            Contact
          </a>
        </li>
        <li class="nav-item dropdown" *ngIf="admin || moderator">
          <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown"
            aria-haspopup="true" aria-expanded="false">
            manage
          </a>
          <div class="dropdown-menu" aria-labelledby="navbarDropdown">
            <a class="dropdown-item" [routerLink]="['/add-post']" (click)="clear_pn()">
              New Blog Post
            </a>
            <a class="dropdown-item" [routerLink]="['/manage-categories']" *ngIf="admin" (click)="clear_pn()">
              Manage Categories
            </a>
            <a class="dropdown-item" [routerLink]="['/reported-users']" (click)="clear_pn()">
              Reported Users
            </a>
            <a class="dropdown-item" [routerLink]="['/reported-discussions']" (click)="clear_pn()">
              Reported Discussions
            </a>
            <a class="dropdown-item" [routerLink]="['/users']" (click)="clear_pn()">
              Shutlips Users
            </a>
            <a class="dropdown-item" [routerLink]="['/username-changes']" (click)="clear_pn()">
              Username Changes
            </a>
            <a class="dropdown-item" [routerLink]="['/send-notification']" (click)="clear_pn()"
              *ngIf="admin || moderator">
              Send Notification
            </a>
            <a class="dropdown-item" [routerLink]="['/archive']" (click)="clear_pn()" *ngIf="admin">
              Shutlips Archive
            </a>
          </div>
        </li>
      </ul>

      <form class="form-inline" #searchForm="ngForm">
        <div class="input-group">
          <input type="search" placeholder="Search by title" required [(ngModel)]="search_field" name="search_forum"
            class="form-control form-control-sm" (input)="emitSearch($event.target.value)" />
          <div class="input-group-append">
            <span class="input-group-text"><i class="fa fa-search"></i></span>
          </div>
        </div>
      </form>

      <!-- <form class="form-inline" (ngSubmit)="onSubmit()" #searchForm="ngForm">
        <input
          type="text"
          placeholder="Search..."
          required
          [(ngModel)]="search_field"
          name="search_forum"
          #search="ngModel"
        />
        <button type="submit" [disabled]="!searchForm.form.valid">
          <i class="fa fa-search"></i>
        </button>
      </form> -->

      <span class="navbar-text" *ngIf="isLoggedIn">
        <ul class="navbar-nav responsive-menu">
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/notifications']" title="Notifications" (click)="clear_pn()">
              <div class="notify" *ngIf="unopened_notifications.length > 0">
                <b>{{ unopened_notifications.length }}</b>
              </div>
              <i class="fa fa-bell" aria-hidden="true"></i>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" [routerLink]="['/messages']" title="Messages" (click)="clear_pn()">
              <div class="notify" *ngIf="unread_messages.length > 0">
                <b>{{ unread_messages.length }}</b>
              </div>
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </a>
          </li>
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown"
              aria-haspopup="true" aria-expanded="false">
              <i class="fa fa-user" aria-hidden="true"></i>
            </a>
            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
              <a [routerLink]="['/profile']" title="My Profile" class="dropdown-item" (click)="clear_pn()">
                My Profile
              </a>
              <a [routerLink]="['/settings']" title="Settings" class="dropdown-item" (click)="clear_pn()">
                Edit Profile
              </a>
              <a class="dropdown-item" (click)="sign_out()" title="Sign Out" style="cursor: pointer;"
                (click)="clear_pn()">
                Sign Out
              </a>
            </div>
          </li>
        </ul>
      </span>
    </div>
  </div>
</nav>