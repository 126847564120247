import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthComponent } from './components/auth/auth.component';
import { SignUpComponent } from './components/auth/sign-up.component';
import { SignInComponent } from './components/auth/sign-in.component';
import { ForgotPasswordComponent } from './components/auth/forgot-password.component';
import { RecoveryQuestionsComponent } from './components/auth/recovery-questions.component';
import { ResetPasswordComponent } from './components/auth/reset-password.component';
import { TopicsComponent } from './components/topics/topics.component';
import { TopicComponent } from './components/topic/topic.component';
import { BlogCategoriesComponent } from './components/blog-categories/blog-categories.component';
import { SettingsComponent } from './components/settings/settings.component';
import { TrendingComponent } from './components/trending/trending.component';
import { MessagesComponent } from './components/messages/messages.component';
import { AboutComponent } from './components/about/about.component';
import { ContactsComponent } from './components/contacts/contacts.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ContentPolicyComponent } from './components/content-policy/content-policy.component';
import { PostsComponent } from './components/posts/posts.component';
import { PostComponent } from './components/post/post.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { TermsOfServiceComponent } from './components/terms-of-service/terms-of-service.component';
import { GuidelinesComponent } from './components/guidelines/guidelines.component';
import { FaqsComponent } from './components/faqs/faqs.component';
import { ReportedTopicsComponent } from './components/reported-topics/reported-topics.component';
import { ReportedUsersComponent } from './components/reported-users/reported-users.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { NewPostComponent } from './components/new-post/new-post.component';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { UsersComponent } from './components/users/users.component';
import { ManageCategoriesComponent } from './components/manage-categories/manage-categories.component';
import { ManageContactsComponent } from './components/manage-contacts/manage-contacts.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { AuthGuard } from './services/auth.guard';
import { SendNotificationsComponent } from './components/send-notifications/send-notifications.component';
import { UsernameChangesComponent } from './components/username-changes/username-changes.component';
import { PushMessagesComponent } from './components/push-messages/push-messages.component';
import { ResolveCommentsComponent } from './components/resolve-comments/resolve-comments.component';
import { ArchivedComponent } from './components/archived/archived.component';
import { ArchivedContentComponent } from './components/archived-content/archived-content.component';
import { ArchivePostComponent } from './components/archive-post/archive-post.component';

const routes: Routes = [
  { path: '', redirectTo: 'discussions', pathMatch: 'full' },
  { path: 'discussions', component: TopicsComponent },
  { path: '404', component: NotFoundComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactsComponent },
  { path: 'disclaimer', component: DisclaimerComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'guidelines', component: GuidelinesComponent },
  { path: 'faqs', component: FaqsComponent },
  { path: 'content-policy', component: ContentPolicyComponent },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: 'sign-up', component: SignUpComponent },
      { path: 'sign-in', component: SignInComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reset-password', component: ResetPasswordComponent },
      { path: 'recovery-questions', component: RecoveryQuestionsComponent },
    ],
  },
  { path: 'profile', component: ProfileComponent },
  { path: 'user-profile/:id', component: UserProfileComponent },
  { path: 'topic/:id', component: TopicComponent },
  { path: 'posts', component: PostsComponent },
  { path: 'post/:id', component: PostComponent },
  { path: 'archive-post/:id', component: ArchivePostComponent },
  { path: 'manage-categories', component: ManageCategoriesComponent },
  { path: 'add-post', component: NewPostComponent },
  { path: 'categories/:id', component: CategoriesComponent },
  { path: 'blog-category/:id', component: BlogCategoriesComponent },
  { path: 'settings', component: SettingsComponent },
  { path: 'trending', component: TrendingComponent },
  { path: 'messages', component: MessagesComponent },
  { path: 'message/:id', component: PushMessagesComponent },
  { path: 'notifications', component: NotificationsComponent },
  { path: 'reported-discussions', component: ReportedTopicsComponent },
  { path: 'reported-users', component: ReportedUsersComponent },
  { path: 'manage-contacts', component: ManageContactsComponent },
  { path: 'send-notification', component: SendNotificationsComponent },
  { path: 'username-changes', component: UsernameChangesComponent },
  { path: 'users', component: UsersComponent },
  { path: 'archive', component: ArchivedComponent },
  { path: 'archive/:id', component: ArchivedContentComponent },
  { path: 'resolve', component: ResolveCommentsComponent },
  { path: '**', redirectTo: 'discussions' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
