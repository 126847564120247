<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-7 hm-3-bg">
        <div class="bg-white hm-3-p">
          <div class="content" style="margin-top: 2em">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item">
                <a class="nav-link active" id="forum-tab" data-toggle="tab" href="#forum" role="tab"
                  aria-controls="forum" aria-selected="true">
                  Forum Categories
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" id="blog-tab" data-toggle="tab" href="#blog" role="tab" aria-controls="blog"
                  aria-selected="false">
                  Blog Categories
                </a>
              </li>
            </ul>
            <div class="tab-content" id="myTabContent">
              <div class="tab-pane fade show active" id="forum" role="tabpanel" aria-labelledby="forum-tab">
                <div class="card-body">
                  <button class="btn btn-sm btn-primary mb-3" (click)="new_forum_cat = true">
                    <i class="fa fa-plus"></i>
                    New Forum Category
                  </button>

                  <form (ngSubmit)="addForumCategory()" #addCategoryForm="ngForm" *ngIf="new_forum_cat" id="addForum">
                    <h4 class="mb-2">New Forum Category</h4>
                    <div class="form-group">
                      <input type="file" id="post-image" (change)="upload_category_image($event)" name="imgUrl"
                        class="form-control-sm" />
                    </div>

                    <div class="form-group">
                      <input type="text" class="form-control form-control-sm" id="title" required
                        [(ngModel)]="forum_title" name="title" #ctitle="ngModel" autofocus
                        placeholder="Enter new category title" />
                    </div>
                    <div class="form-group">
                      <angular-editor [(ngModel)]="forum_description" [ngModelOptions]="{ standalone: true }"
                        [config]="config">
                      </angular-editor>
                    </div>
                    <div class="form-group" style="margin-top: .5rem">
                      <button [disabled]="!addCategoryForm.form.valid" type="submit" class="btn btn-sm btn-primary">
                        Add Category
                      </button>

                      <button type="button" class="btn btn-sm btn-default ml-3" (click)="cancel()">
                        Cancel
                      </button>
                    </div>
                  </form>

                  <div *ngFor="let c of forum_categories" class="mt-2">
                    <img src="{{c.category_image}}" alt="{{c.title}}" *ngIf="c.category_image" class="image-fluid mb-2">
                    <h6 class="text-capitalize name">
                      <a [routerLink]="['/categories', c.id]">{{ c.title }}</a>
                    </h6>
                    <small class="text-capitalize mt-2" [innerHtml]="c.description"></small>
                    <ul class="list-inline mt-2 text-capitalize">
                      <li class="list-inline-item text-primary pointer mr-3" (click)="edit_forum_category(c)">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                        Edit
                      </li>
                      <li class="list-inline-item text-danger pointer" (click)="move_forum_category(c)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </li>
                    </ul>

                    <!-- move category -->
                    <article *ngIf="
                        show_delete_forum_category &&
                        c.uid == forum_category_uid
                      ">
                      <div class="articles-header">
                        <small>
                          This category has discussions attached to it, you will
                          have to move the discussions to new category
                        </small>
                      </div>
                      <div class="articles-content">
                        <form (ngSubmit)="onSubmit(c.id)" #moveCategoryForm="ngForm">
                          <div class="form-group">
                            <select class="form-control form-control-sm" id="new_category" required
                              [(ngModel)]="new_category" name="tcategory" #tcategory="ngModel">
                              <option selected>Select new category *</option>
                              <option *ngFor="let c of forum_categories" [value]="c.id">
                                {{ c.title }}
                              </option>
                            </select>
                            <div [hidden]="tcategory.valid || tcategory.pristine" class="invalid-feedback">
                              Category is required
                            </div>
                          </div>
                          <div class="form-group" style="margin-top: .5em">
                            <button [disabled]="!moveCategoryForm.form.valid" type="submit"
                              class="btn btn-primary btn-sm">
                              Move and Delete Category
                            </button>

                            <button type="button" class="btn btn-sm btn-default ml-3"
                              (click)="show_delete_forum_category = false">
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                      <div class="articles-footer"></div>
                    </article>

                    <form (ngSubmit)="editForumCategory()" #editForumCategoryForm="ngForm" *ngIf="
                        edit_forum_cat == true &&
                        c.uid == forum_category_uid
                      " id="editForum">
                      <h4 class="mb-2">Edit Forum Category</h4>
                      <div class="form-group">
                        <input type="file" id="post-image" (change)="upload_category_image($event)" name="imgUrl"
                          class="form-control-sm" />
                      </div>
                      <div class="form-group">
                        <input type="text" class="form-control form-control-sm" id="title" required
                          [(ngModel)]="forum_title" name="title" #ctitle="ngModel" autofocus
                          placeholder="Enter new category title" />
                      </div>
                      <div class="form-group">
                        <angular-editor [(ngModel)]="forum_description" [ngModelOptions]="{ standalone: true }"
                          [config]="config">
                        </angular-editor>
                      </div>
                      <div class="form-group" style="margin-top: .5rem">
                        <button [disabled]="editForumCategoryForm.invalid" type="submit" class="btn btn-sm btn-primary">
                          Save
                        </button>

                        <button type="button" class="btn btn-sm btn-default ml-3" (click)="cancel()">
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div class="tab-pane fade" id="blog" role="tabpanel" aria-labelledby="blog-tab">
                <div class="card-body">
                  <button class="btn btn-sm btn-primary mb-3" (click)="new_blog_cat = true">
                    <i class="fa fa-plus"></i>
                    New Blog Category
                  </button>

                  <form (ngSubmit)="addBlogCategory()" #addBlogCategoryForm="ngForm" *ngIf="new_blog_cat" id="addBlog"
                    class="mt-3">
                    <h4 class="mb-2">New Blog Category</h4>
                    <div class="form-group">
                      <input type="text" class="form-control form-control-sm" id="title" required
                        [(ngModel)]="blog_title" name="title" #ctitle="ngModel" autofocus
                        placeholder="Enter new category title" />
                    </div>
                    <div class="form-group">
                      <input type="number" class="form-control form-control-sm" id="position" required
                        [(ngModel)]="blog_position" name="position" #cposition="ngModel" autofocus
                        placeholder="Enter new position category" />
                    </div>
                    <div class="form-group">
                      <angular-editor [(ngModel)]="blog_description" [ngModelOptions]="{ standalone: true }"
                        [config]="config">
                      </angular-editor>
                    </div>
                    <div class="form-group" style="margin-top: .5rem">
                      <button [disabled]="!addBlogCategoryForm.form.valid" type="submit" class="btn btn-sm btn-primary">
                        Add Category
                      </button>

                      <button type="button" class="btn btn-sm btn-default ml-3" (click)="cancel()">
                        Cancel
                      </button>
                    </div>
                  </form>

                  <div *ngFor="let c of blog_categories" class="mt-2">
                    <h6 class="text-capitalize name">
                      <a [routerLink]="['/blog-categories', c.id]">
                        {{ c.title }}
                      </a>
                    </h6>
                    <small class="text-capitalize mt-2" [innerHtml]="c.description"></small>
                    <br>
                    <small class="text-capitalize mt-2"><b>Position: {{c.position}}</b></small>
                    <ul class="list-inline mt-2 text-capitalize">
                      <li class="list-inline-item text-primary pointer mr-3" (click)="edit_blog_category(c)">
                        <i class="fa fa-pencil" aria-hidden="true"></i>
                        Edit
                      </li>
                      <li class="list-inline-item text-danger pointer" (click)="move_blog_category(c)">
                        <i class="fa fa-trash" aria-hidden="true"></i>
                        Delete
                      </li>
                    </ul>

                    <!-- move category -->
                    <article *ngIf="show_delete_blog_category &&
                                            c.uid == blog_category_uid
                                          ">
                      <div class="articles-header">
                        <small>
                          This category has posts attached to it, you will
                          have to move the posts to new category
                        </small>
                      </div>
                      <div class="articles-content">
                        <form (ngSubmit)="onMoveBC(c.id)" #moveCategoryForm="ngForm">
                          <div class="form-group">
                            <select class="form-control form-control-sm" id="new_category" required
                              [(ngModel)]="new_category" name="tcategory" #tcategory="ngModel">
                              <option selected>Select new category *</option>
                              <option *ngFor="let c of blog_categories" [value]="c.id">
                                {{ c.title }}
                              </option>
                            </select>
                            <div [hidden]="tcategory.valid || tcategory.pristine" class="invalid-feedback">
                              Category is required
                            </div>
                          </div>
                          <div class="form-group" style="margin-top: .5em">
                            <button [disabled]="moveCategoryForm.invalid" type="submit" class="btn btn-primary btn-sm">
                              Move and Delete Category
                            </button>

                            <button type="button" class="btn btn-sm btn-default ml-3"
                              (click)="show_delete_blog_category = false">
                              Cancel
                            </button>
                          </div>
                        </form>
                      </div>
                      <div class="articles-footer"></div>
                    </article>

                    <form (ngSubmit)="editBlogCategory()" #editBlogCategoryForm="ngForm" *ngIf="
                        edit_blog_cat == true &&
                        c.uid == blog_category_uid
                      " id="editBlog">
                      <h4 class="mb-2">Edit Blog Category</h4>
                      <div class="form-group">
                        <input type="text" class="form-control form-control-sm" id="title" required
                          [(ngModel)]="blog_title" name="title" #ctitle="ngModel" autofocus
                          placeholder="Enter new category title" />
                      </div>
                      <div class="form-group">
                        <input type="number" class="form-control form-control-sm" id="position" required
                          [(ngModel)]="blog_position" name="position" #cposition="ngModel" autofocus
                          placeholder="Enter new category position" />
                      </div>
                      <div class="form-group">
                        <angular-editor [(ngModel)]="blog_description" [ngModelOptions]="{ standalone: true }"
                          [config]="config">
                        </angular-editor>
                      </div>
                      <div class="form-group" style="margin-top: .5rem">
                        <button [disabled]="editBlogCategoryForm.invalid" type="submit" class="btn btn-sm btn-primary">
                          Save
                        </button>

                        <button type="button" class="btn btn-sm btn-default ml-3" (click)="cancel()">
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!--END OF CONTENT-->
        </div>
      </div>
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>