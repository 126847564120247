<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <div class="col-md-7 hm-3-bg" *ngIf="suspended === 'true'">
        <div class="alert alert-danger alert-sm">
          Your account has been suspended, please contact the moderator at
          moderator@shutlips.com
        </div>
      </div>

      <div class="col-md-7 hm-3-bg" *ngIf="suspended === 'false'">
        <div class="bg-white hm-3-p">
          <div class="text-center">
            <button
              class="btn btn-primary btn-sm mb-3 mt-3"
              (click)="new_message()"
            >
              <i class="fa fa-envelope"></i>
              New Message
            </button>
            <div class="pb-3 text-center" *ngIf="compose">
              <button
                class="btn btn-default btn-sm mb-2 mt-2"
                (click)="cancel()"
              >
                Cancel
              </button>
              <br />
              <img
                class="rounded-circle"
                src="{{ recipient_data.image }}"
                alt="{{ recipient_data.username }}"
                *ngIf="recipient_data.image !== ''"
                height="70"
                width="80"
              />
              <h5 class="mb-2" *ngIf="recipient_data.username !== ''">
                <b>{{ recipient_data.username }}</b>
              </h5>

              <div class="media">
                <div class="media-body">
                  <form
                    (ngSubmit)="onSubmit()"
                    #chatForm="ngForm"
                    class="col-md-8 offset-md-2"
                  >
                    <div class="mb-1">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Enter recipients username"
                        id="message_input"
                        [(ngModel)]="recipient"
                        name="recipient"
                        #m_recipient="ngModel"
                        autofocus
                        (input)="check_username()"
                      />
                      <div
                        class="alert alert-sm alert-danger"
                        *ngIf="self && recipient"
                      >
                        <small>
                          <b>You can't send a message to yourself</b>
                        </small>
                      </div>
                      <div
                        class="alert alert-sm alert-danger"
                        *ngIf="conversation_exist && recipient"
                      >
                        <small>
                          <b>
                            Conversation with this user already exist, please
                            use the message search continue
                          </b>
                        </small>
                      </div>
                      <div
                        class="alert alert-sm alert-danger"
                        *ngIf="!available && recipient"
                      >
                        <small><b>User not found</b></small>
                      </div>
                    </div>
                    <div class="input-group">
                      <textarea
                        rows="1"
                        class="form-control form-control-sm"
                        placeholder="Enter message"
                        id="message"
                        [(ngModel)]="message"
                        name="message"
                        #m_message="ngModel"
                        spellcheck="true"
                      ></textarea>
                      <div class="input-group-prepend">
                        <button
                          class="btn btn-outline-secondary"
                          type="submit"
                          [disabled]="
                            chatForm.invalid ||
                            !available ||
                            self ||
                            conversation_exist
                          "
                        >
                          <small>
                            <b>send</b>
                          </small>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div class="text-center" *ngIf="messages.length == 0">
            <i class="fa fa-envelope"></i>
            <br />
            <small>
              You have no direct messages yet and you haven't sent any either
            </small>
            <br />
          </div>

          <!-- Search messages -->
          <input
            type="search"
            [(ngModel)]="search_user"
            *ngIf="!compose && !chat_window"
            name="search_messages"
            (input)="search($event.target.value)"
            class="form-control form-control-sm mb-3"
            placeholder="Search user messages here "
          />

          <ul
            class="list-group"
            *ngIf="!compose && !chat_window"
            id="message-ul"
          >
            <li
              class="list-group-item"
              *ngFor="let m of messages; let i = index"
              style="cursor: pointer;"
              (click)="open(m, m.uid)"
              id="{{ m.uid }}"
            >
              <div class="media">
                <img
                  class="mr-2"
                  src="{{ m.recipient_avatar }}"
                  alt="{{ m.recipient }}"
                  *ngIf="m.recipient != username"
                />
                <img
                  class="mr-2"
                  src="{{ m.sender_avatar }}"
                  alt="{{ m.sender }}"
                  *ngIf="m.sender != username"
                />
                <div class="media-body">
                  <h5
                    class="mt-0"
                    *ngIf="m.sender != username"
                    [ngClass]="{ 'text-bold': m.status == 'unread' }"
                  >
                    <a [routerLink]="['/user-profile', m.sender]">
                      {{ m.sender }}
                    </a>
                  </h5>
                  <h5
                    class="mt-0"
                    *ngIf="m.recipient != username"
                    [ngClass]="{ 'text-bold': m.status == 'unread' }"
                  >
                    <a [routerLink]="['/user-profile', m.recipient]">
                      {{ m.recipient }}
                    </a>
                  </h5>
                  <small
                    style="color: #c154c1;"
                    *ngIf="m.message"
                    [ngClass]="{ 'text-bold': m.status == 'unread' }"
                  >
                    {{ first10(m?.message) }}
                  </small>
                  <small [ngClass]="{ 'text-bold': m.status == 'unread' }">
                    <b>- {{ dateTime(m.time) }}</b>
                    <span *ngIf="m.status == 'read'">
                      - read
                    </span>
                    <span *ngIf="m.status == 'unread'">
                      - unread
                    </span>
                    <span *ngIf="m.status == 'sent'">
                      - sent
                    </span>
                  </small>
                </div>
              </div>
            </li>
            <!-- <span id="span" style="display: none;">User not found</span> -->
          </ul>

          <!-- Chat window -->
          <div
            *ngIf="chat_window"
            style="max-height: 90% !important; overflow: auto !important;"
          >
            <div class="media text-center mb-3">
              <button
                class="btn btn-sm btn-primary mr-3"
                (click)="chat_window = !chat_window"
              >
                <i class="fa fa-angle-left"></i>
                back
              </button>
              <div class="media-body">
                <img
                  class="mb-2"
                  src="{{ chat.recipient_avatar }}"
                  alt="{{ chat.recipient }}"
                  *ngIf="chat.recipient != username"
                />
                <img
                  class="mb-2"
                  src="{{ chat.sender_avatar }}"
                  alt="{{ chat.sender }}"
                  *ngIf="chat.sender != username"
                />
                <h5 class="mt-0" *ngIf="chat.sender != username">
                  <a [routerLink]="['/user-profile', chat.sender]">
                    {{ chat.sender }}
                  </a>
                </h5>
                <h5 class="mt-0" *ngIf="chat.recipient != username">
                  <a [routerLink]="['/user-profile', chat.recipient]">
                    {{ chat.recipient }}
                  </a>
                </h5>
              </div>
            </div>
            <div *ngFor="let mt of message_threads">
              <div
                class="mb-3 border-bottom"
                [ngClass]="{
                  nothing: mt.recipient !== username,
                  right: mt.sender === username
                }"
              >
                <small
                  class="card-title text-primary"
                  *ngIf="mt.sender != username"
                >
                  <b>
                    <a [routerLink]="['/user-profile', mt.sender]">
                      {{ mt.sender }}
                    </a>
                  </b>
                </small>
                <small
                  class="card-title text-primary"
                  *ngIf="mt.sender == username"
                >
                  <b>you</b>
                </small>
                <p class="card-text">
                  <small>
                    <b>{{ mt.message }}</b>
                  </small>
                  <br />
                  <small class="text-primary">
                    <b>
                      <i class="fa fa-check"></i>
                      - {{ dateTime(mt.time) }}
                    </b>
                  </small>
                </p>
              </div>
            </div>
            <form
              (ngSubmit)="onReply()"
              #chatReplyForm="ngForm"
              class="col-md-12"
            >
              <div class="input-group mb-3">
                <textarea
                  rows="1"
                  class="form-control form-control-sm"
                  placeholder="Reply message here..."
                  id="message_reply"
                  required
                  [(ngModel)]="message_reply"
                  name="message_reply"
                  #m_message="ngModel"
                  spellcheck="true"
                ></textarea>
                <div class="input-group-prepend">
                  <button
                    class="btn btn-outline-secondary"
                    type="submit"
                    [disabled]="chatReplyForm.invalid || message_reply === ''"
                  >
                    send
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
