// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyBNTjJc0m4BuS0jF1G6KKttfsgCrUOd_HA',
    authDomain: 'shutlips-c14d7.firebaseapp.com',
    databaseURL: 'https://shutlips-c14d7.firebaseio.com',
    projectId: 'shutlips-c14d7',
    storageBucket: 'shutlips-c14d7.appspot.com',
    messagingSenderId: '238737593566',
    appId: '1:238737593566:web:fffb35bf3c44336d',
  },
  vapid_public_key:
    'BAUo4OoESGbwf0AP0i0rJQnSge3BwNSszmjBv4xhRJKgA56foqFUoRTElHjY7roynakvw7yym6PL2BT1kHWR_ek',
  vapid_private_key: 'rKdZa6OXK4yt5BllDaA6aMWISLdD3eFlUt4YqbnBZZ4',
  adsense_profile_preview: {
    adClient: 'ca-pub-7172375136185143',
    slotID: 3914755705,
  },
  adsense_profile_preview_1: {
    slotID: 9265921209,
  },
  adsense_categories: {
    slotID: 8062997663,
  },
  adsense_blog_post: {
    slotID: 1953874568,
  },
  adsense_disscussion: {
    slotID: 7355209209,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
