import { Component, OnInit } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { Subscription } from 'rxjs';
import { ForumService } from 'src/app/services/forum.service';

@Component({
  selector: 'app-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.css'],
})
export class ContactsComponent implements OnInit {
  role: string;
  content: string;
  uid: string;
  edit: boolean = false;
  page_content = [];
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '15rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold']
      ],
    customClasses: [
      {
        name: "quote",
        class: "quote",
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: "titleText",
        class: "titleText",
        tag: "h1",
      },
    ]
  };
  sub: Subscription;

  constructor(private _forum: ForumService) {}

  ngOnInit() {
    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];

    this.sub = this._forum
      .get_contacts_page()
      .subscribe((page) => (this.page_content = page));
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  show_edit(content) {
    this.content = content.content;
    this.uid = content.uid;
    this.edit = !this.edit;
  }

  edit_page() {
    const data = {
      content: this.content.trim(),
    };

    this._forum.edit_contacts(data, this.uid);
    this.edit = !this.edit;
    this.content = '';
    this.uid = '';
  }
}
