import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ForumService } from 'src/app/services/forum.service';

@Component({
  selector: 'app-resolve-comments',
  templateUrl: './resolve-comments.component.html',
  styleUrls: ['./resolve-comments.component.css'],
})
export class ResolveCommentsComponent implements OnInit, OnDestroy {
  username: string;
  role: string;
  topic_comments: any[] = [];
  comment_replies: any[] = [];
  parent_comments: any[] = [];
  root_comments: any[] = [];
  t_comments: any[] = [];
  parents: any[] = [];
  roots: any[] = [];
  post: string = '';
  sub: Subscription;
  topics: any[] = [];
  author_topics: any[] = [];
  selected_comment: any;
  selected_parent_comment: any;
  selected_root_comment: any;
  set_id: string;
  posts: any[];

  constructor(
    private _auth: AuthService,
    private _router: Router,
    private _afs: AngularFirestore,
    private _spinner: NgxSpinnerService,
    private _forum: ForumService
  ) {}

  ngOnInit(): void {
    this._auth.isAuthenticated();
    this.username = window.sessionStorage['slu_username']
      ? window.sessionStorage['slu_username']
      : window.localStorage['slu_username'];
    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];

    if (this.username !== 'romio') {
      this._router.navigate(['/']);
    }
    this.loadPosts();
  }

  search = async () => {
    if (this.post !== '') {
      this._afs
        .collection('topics', (ref) => ref.where('id', '==', this.post))
        .get()
        .subscribe((topic) => topic.forEach((doc) => console.log(doc.data())));
      this._spinner.show();
      this.sub = this._afs
        .collection('topic-comments', (ref) =>
          ref.where('post', '==', this.post)
        )
        .valueChanges()
        .subscribe((comments: any[]) => (this.topic_comments = comments));
      this.sub = this._afs
        .collection('topic-comment-replies', (ref) =>
          ref.where('post', '==', this.post)
        )
        .valueChanges()
        .subscribe((comments: any[]) => (this.comment_replies = comments));
      this.sub = this._afs
        .collection('posts', (ref) => ref.where('title', '==', this.post))
        .valueChanges()
        .subscribe((topics: any[]) => {
          this.topics = topics;
          console.log(topics[0]);
          this._spinner.hide();
        });
    }
  };

  ngOnDestroy(): void {}

  dateTime(dt: string) {
    return moment(dt).fromNow();
  }

  select_comment = (comment: any) => {
    this.parent_comments = [];
    this.root_comments = [];
    this.t_comments = [];
    this.selected_comment = comment;
    if (comment.root_comment_time) {
      console.log(comment.root_comment_time);
      this.get_root_comments(comment.root_comment_time);
    }
    if (comment.parent_comment_time) {
      this.get_parent_comments(comment.parent_comment_time);
      console.log(comment.parent_comment_time);
    }
    if (comment.time) {
      console.log(comment.time);
      this.get_t_comments(comment.time);
    }
  };

  load_topics = (author: string) => {
    this.sub = this._afs
      .collection('topics', (ref) => ref.where('author', '==', author))
      .valueChanges()
      .subscribe((comments: any[]) => {
        this.author_topics = comments;
      });
  };

  update = (comment) => {
    comment.post = this.set_id;
    console.log(comment);
    let c = confirm('update comment?');
    if (c) {
      // this._afs
      //   .collection('topic-comments')
      //   .doc(comment.uid)
      //   .set(comment)
      //   .then(() => console.log('saved parent comment'));
      this._afs
        .collection('topic-comment-replies')
        .doc(comment.uid)
        .set(comment)
        .then(() => console.log('saved reply comment'));
    }
  };

  get_parent_comments = (time) => {
    this.sub = this._afs
      .collection('topic-comments', (ref) => ref.where('time', '==', time))
      .valueChanges()
      .subscribe((comments: any[]) => {
        this.parent_comments = comments;
      });
  };

  get_root_comments = (time) => {
    this.sub = this._afs
      .collection('topic-comments', (ref) => ref.where('time', '==', time))
      .valueChanges()
      .subscribe((comments: any[]) => {
        this.root_comments = comments;
      });
  };

  get_t_comments = (time) => {
    this.sub = this._afs
      .collection('topic-comments', (ref) => ref.where('time', '==', time))
      .valueChanges()
      .subscribe((comments: any[]) => {
        this.t_comments = comments;
      });
  };

  get_comments = (time: any) => {
    this.sub = this._afs
      .collection('topic-comment-replies', (ref) =>
        ref.where('parent_comment_time', '==', time)
      )
      .valueChanges()
      .subscribe((comments: any[]) => {
        this.parents = comments;
      });
    this.sub = this._afs
      .collection('topic-comment-replies', (ref) =>
        ref.where('root_comment_time', '==', time)
      )
      .valueChanges()
      .subscribe((comments: any[]) => {
        this.roots = comments;
      });
  };

  select_parent_comment = (comment: any) => {
    this.selected_parent_comment = comment;
  };

  select_root_comment = (comment: any) => {
    this.selected_root_comment = comment;
  };

  update_root_comment = (comment: any) => {
    comment.post = this.selected_parent_comment.post;
    console.log(comment);
    let c = confirm('update?');
    if (c) {
      this._afs
        .collection('topic-comment-replies')
        .doc(comment.uid)
        .set(comment)
        .then(() => console.log('saved root comment'));
    }
  };

  update_comment = () => {
    this.selected_comment.post = this.selected_parent_comment.post;
    console.log(this.selected_comment);
    let c = confirm('update?');
    if (c) {
      this._afs
        .collection('topic-comment-replies')
        .doc(this.selected_comment.uid)
        .set({ post: this.selected_comment }, { merge: true })
        .then(() => console.log('saved'));
    }
  };

  update_tcpwsc = (comment: any) => {
    let c = confirm(
      `update ${comment.post} to ${comment.post.replace(/[^a-zA-Z0-9- ]/g, '')}`
    );
    if (c) {
      this._afs
        .collection('topic-comments')
        .doc(comment.uid)
        .set(
          { post: comment.post.replace(/[^a-zA-Z0-9- ]/g, '') },
          { merge: true }
        )
        .then(() => console.log('saved'));
    }
  };

  update_tcrpwsc = (comment: any) => {
    let c = confirm(
      `update ${comment.post} to ${comment.post.replace(/[^a-zA-Z0-9- ]/g, '')}`
    );
    if (c) {
      this._afs
        .collection('topic-comment-replies')
        .doc(comment.uid)
        .set(
          { post: comment.post.replace(/[^a-zA-Z0-9- ]/g, '') },
          { merge: true }
        )
        .then(() => console.log('saved'));
    }
  };

  update_parent_batch = (comment: any) => {
    comment.post = this.selected_parent_comment.post;
    console.log(comment);
    let c = confirm('update');
    if (c) {
      this._afs
        .collection('topic-comment-replies')
        .doc(comment.uid)
        .set(comment)
        .then(() => console.log('saved'));
    }
  };

  delete_comment(comment, uid) {
    let con = confirm('Are you sure you want to delete this comment?');
    if (con) {
      this._forum.delete_comment(comment, uid, this.post);
    }
  }

  delete_child_comment(comment, uid) {
    let con = confirm('Are you sure you want to delete this comment?');
    if (con) {
      this._forum.delete_child_comment(comment, uid, this.post);
    }
  }

  loadPosts = () => {
    this.sub = this._afs
      .collection('posts', (ref) => ref.where('active', '==', false))
      .valueChanges()
      .subscribe((posts: any[]) => {
        this.posts = posts;
      });
  };
}
