import { Component, OnInit } from "@angular/core";
import { Subscription } from "rxjs";
import { AuthService } from "src/app/services/auth.service";
import { ForumService } from "src/app/services/forum.service";
import { Router } from "@angular/router";
import * as moment from "moment";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.css"],
})
export class UsersComponent implements OnInit {
  p: number = 1;
  sub: Subscription;
  users = [];
  suspended_users = [];
  deactivated_users = [];
  active_users = [];
  male_users = [];
  female_users = [];
  total_users = [];
  avatar: string;
  username: string;
  role: string;
  userFilter: any;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _router: Router
  ) {
    this._auth.isAuthenticated();
    this.username = window.sessionStorage["slu_username"]
      ? window.sessionStorage["slu_username"]
      : window.localStorage["slu_username"];
    this.role = window.sessionStorage["slu_role"]
      ? window.sessionStorage["slu_role"]
      : window.localStorage["slu_role"];
    this.avatar = window.sessionStorage["slu_avatarUrl"]
      ? window.sessionStorage["slu_avatarUrl"]
      : window.localStorage["slu_avatarUrl"];

    this._forum.changeMessage("");
  }

  ngOnInit() {
    this.sub = this._forum.get_users().subscribe(users => {
      this.users = users;
      this.total_users = users;
    });

    if (
      window.sessionStorage["slu_role"] == "admin" ||
      window.sessionStorage["slu_role"] == "moderator" ||
      window.localStorage["slu_role"] == "admin" ||
      window.localStorage["slu_role"] == "moderator"
    ) {
    } else {
      this._router.navigate(["/"]);
    }

    this.sub = this._forum
      .get_active_users()
      .subscribe(users => (this.active_users = users));
    this.sub = this._forum
      .get_suspended_users()
      .subscribe(users => (this.suspended_users = users));
    this.sub = this._forum
      .get_deactivated_users()
      .subscribe(users => (this.deactivated_users = users));
    this.sub = this._forum
      .get_female_users()
      .subscribe(users => (this.female_users = users));
    this.sub = this._forum
      .get_male_users()
      .subscribe(users => (this.male_users = users));

    this.sub = this._forum.currentSearch.subscribe(message => {
      this.userFilter = { username: message };
    });
  }

  set_user(username: string) {
    window.sessionStorage["user_to_message"] = username;
    this._router.navigate(["/messages"]);
  }

  suspend_user(uid: string) {
    this._forum.suspend_user(uid);
  }

  reactivate_user(uid: string) {
    this._forum.reactivate_user(uid);
  }

  make_admin(uid: string) {
    this._forum.make_admin(uid);
  }

  revoke_admin(uid: string) {
    this._forum.revoke_admin(uid);
  }

  make_moderator(uid: string) {
    this._forum.make_moderator(uid);
  }

  revoke_moderator(uid: string) {
    this._forum.revoke_moderator(uid);
  }

  disable_user(uid: string) {
    this._auth.disable_user(uid);
  }

  enable_user(uid: string) {
    this._auth.enable_user(uid);
  }

  date(date: any) {
    return moment(date).fromNow();
  }
}
