<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <!-- End of col-lg-2-->

      <div class="col-lg-7 text-center">
        <h4 class="mb-3 mt-3">Shutlips username changes</h4>

        <small class="text-center" *ngIf="username_changes.length === 0">
          No username changed at the moment.
        </small>
        <div class="list-group">
          <div
            class="list-group-item list-group-item-action"
            *ngFor="
              let n of username_changes
                | paginate: { itemsPerPage: 20, currentPage: p }
            "
          >
            <div class="row">
              <div class="col-md-12">
                <div class="w-100 text-left">
                  <a [routerLink]="['/user-profile', n.profile]">
                    <small>{{ n.notification }}</small>
                  </a>
                  <br />
                  <small>
                    <b>
                      {{ date(n.time) }}
                    </b>
                  </small>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>

      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
