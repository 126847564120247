<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-7 hm-3-bg" *ngFor="let u of users">
        <div class="bg-white hm-3-p">
          <div class="row cat-h3-top" style="border-bottom: 5px solid #f2f2f2">
            <div class="col-md-3">
              <img
                src="{{ u.avatarUrl }}"
                alt="{{ u.username }}"
                class="img-fluid img-circle"
                style="max-width: 80px; max-height: 70px;"
              />
            </div>
            <div class="col-lg-9 bl_header">
              <h5>
                {{ u.username }}
              </h5>
              <small>
                <b>
                  Shutlips {{ u.role }} | Joined {{ dateTime(u.joined) }} |
                  {{
                    user_topics.length > 0
                      ? "Started " + user_topics.length + " Discussions"
                      : "No Article Yet"
                  }}
                </b>
              </small>
              <br />
              <ul>
                <li *ngIf="u.username !== username">
                  <a
                    [routerLink]="['/messages']"
                    [state]="{ recipient: u.username }"
                    title="Send Message"
                  >
                    <i class="fa fa-envelope"></i>
                    Send message
                  </a>
                </li>
                <li *ngIf="u.deactivated === false && admin">
                  <a
                    (click)="disable_user(u.uid)"
                    title="Disable This User"
                    class="text-danger"
                  >
                    <i class="fa fa-times"></i>
                    Disable User
                  </a>
                </li>
                <li *ngIf="u.deactivated === true && admin">
                  <a (click)="enable_user(u.uid)" title="Enable This User">
                    <i class="fa fa-check"></i>
                    Enable User
                  </a>
                </li>
                <li *ngIf="u.suspended === false && (admin || moderator)">
                  <a
                    (click)="suspend_user(u.uid)"
                    title="Suspend This User"
                    class="text-danger"
                  >
                    <i class="fa fa-times"></i>
                    Suspend User
                  </a>
                </li>
                <li *ngIf="u.suspended === true && (admin || moderator)">
                  <a
                    (click)="unsuspend_user(u.uid)"
                    title="Unsuspend This User"
                  >
                    <i class="fa fa-check"></i>
                    Unsuspend User
                  </a>
                </li>
                <li *ngIf="u.username !== username">
                  <a
                    title="Block User"
                    (click)="block_user(u)"
                    *ngIf="!blocked"
                  >
                    <i class="fa fa-shield"></i>
                    Block this user
                  </a>
                  <a
                    title="Block User"
                    (click)="unblock_user(u)"
                    *ngIf="blocked"
                  >
                    <i class="fa fa-shield"></i>
                    Unblock this user
                  </a>
                </li>
                <li *ngIf="u.username !== username">
                  <a (click)="show_report_form()" title="Report User">
                    <i class="fa fa-ban"></i>
                    Report {{ id }}
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <section
            class="articles"
            style="margin-top: 2em"
            *ngIf="report_user_form"
          >
            <article>
              <form (ngSubmit)="report()" #reportUserForm="ngForm">
                <textarea
                  class="form-control form-control-sm"
                  rows="1"
                  placeholder="Tell us your reason for reporting {{ id }}"
                  id="report_user"
                  required
                  [(ngModel)]="report_user"
                  name="report_user"
                  #c_report_user="ngModel"
                ></textarea>
                <div
                  *ngIf="
                    !c_report_user.valid &&
                    (c_report_user.dirty || c_report_user.touched)
                  "
                >
                  You need to a reason to report {{ id }}
                </div>
                <button
                  type="submit"
                  class="btn btn-primary btn-sm mt-2"
                  [disabled]="!reportUserForm.form.valid"
                >
                  Report {{ id }}
                </button>
                <button
                  type="button"
                  class="btn btn-default btn-sm mt-2"
                  (click)="cancel_report()"
                >
                  Changed my mind!
                </button>
              </form>
            </article>
          </section>

          <div
            class="content"
            style="margin-top: 2em"
            *ngIf="user_topics.length == 0"
          >
            <span>
              <strong><em>This user has no article yet.</em></strong>
            </span>
          </div>

          <div class="content" *ngFor="let ct of user_topics">
            <img
              src="{{ ct.author_avatar }}"
              alt="{{ ct.author }}"
              width="80px"
              height="80px"
              class="img-fluid"
            />
            <div class="detail">
              <h6 class="text-capitalize name">
                <a [routerLink]="['/topic', ct.id]">{{ ct.title }}</a>
              </h6>
              <small class="text-capitalize mt-2">
                By
                <span class="blg_auther">
                  {{ ct.author }} in {{ ct.category }} -
                  {{ dateTime(ct.timeCreated) }}
                </span>
              </small>
              <small class="text-capitalize mt-2">
                {{ first30(ct.content) }}
              </small>
              <ul class="list-inline mt-2 text-capitalize">
                <li class="list-inline-item">
                  <i class="fa fa-thumbs-up" aria-hidden="true"></i>
                  likes [{{ ct.likes != 0 ? ct.likes : 0 }}]
                </li>
                <li class="list-inline-item">
                  <i class="fa fa-comments"></i>
                  {{ ct.comments > 0 ? ct.comments : "No" }} comments
                </li>
              </ul>
            </div>
          </div>

          <!--END OF CONTENT-->
        </div>
      </div>
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
