<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-clip-rotate">
  <p style="font-size: 20px; color: white;">Loading...</p>
</ngx-spinner>

<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>

      <!-- End of col-lg-2-->
      <div class="col-lg-7 col-12 blog_wrap">
        <div class="single_blog" *ngFor="let p of post">
          <div class="blog-header text-center">
            <small class="bg-primary featured" *ngIf="p.featured">
              <i class="fa fa-bookmark"></i> featured
            </small>
            <h3 class="about-title text-primary" style="text-transform: capitalize;">
              {{ p.title }}
            </h3>
            <div class="blog-header">
              <small>
                Posted by
                <span class="post_aouther">Nonye</span>
                <span> - {{ wpm(p.content) }}</span>
              </small>
              <!-- <small class="post_time">{{ dateTime(p.timeCreated) }}</small> -->
              |
              <small class="post-cat">
                <a class="green" title="{{ p.category }}" [routerLink]="['/blog-category', p.category]">
                  {{ category(p.category) }}
                </a>
              </small>
              <br />
              <button class="btn btn-sm btn-primary mr-3 mt-2" (click)="add_to_featured(p.uid)"
                *ngIf="!p.featured && admin">
                Add to featured
              </button>
              <button class="btn btn-sm btn-primary mr-3 mt-2" (click)="remove_from_featured(p.uid)"
                *ngIf="p.featured && admin">
                Remove from featured
              </button>
              <button class="btn btn-sm btn-primary mr-3 mt-2" (click)="share(p.title)" *ngIf="check_if_mobile()">
                <i class="fa fa-share"></i>
                Share post
              </button>
              <button class="btn btn-sm btn-primary mr-3 mt-2" (click)="edit_post(p)" *ngIf="admin">
                <i class="fa fa-pencil"></i>
                Edit
              </button>
              <button class="btn btn-sm btn-danger mt-2" (click)="delete_post(p)" *ngIf="admin">
                <i class="fa fa-trash"></i>
                Delete
              </button>
            </div>
          </div>
          <div class="col-lg-12" *ngIf="edit">
            <form (ngSubmit)="onSubmit()" #editPostForm="ngForm">
              <div class="form-group">
                <input type="file" id="post-image" (change)="upload_blog_image($event)" name="imgUrl"
                  class="form-control-sm" />
              </div>

              <div class="form-group">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="title" required name="ttitle"
                  #ttitle="ngModel" autofocus placeholder="Enter post title" />
              </div>

              <div class="form-group">
                <select class="form-control form-control-sm" required [(ngModel)]="blog_category" name="tcategory"
                  #tcategory="ngModel">
                  <option selected="">Select Category</option>
                  <option *ngFor="let c of categories" [value]="c.id">
                    {{ c.title }}
                  </option>
                </select>
              </div>

              <div class="form-group">
                <angular-editor [(ngModel)]="content" [ngModelOptions]="{ standalone: true }" [config]="config">
                </angular-editor>
              </div>

              <div class="form-group mt-2">
                Active
                <input type="checkbox" name="tactive" #tactive="ngModel" [(ngModel)]="active" class="ml-2"
                  [checked]="active" />
              </div>

              <div class="form-group text-center">
                <button [disabled]="editPostForm.invalid" type="submit" class="btn btn-primary mt-3 btn-sm">
                  Save Post
                </button>
              </div>
            </form>
          </div>
          <div class="blg-img text-center" *ngIf="!edit">
            <img src="{{ p.imgUrl }}" alt="{{ p.id }}-image" class="img-fluid" />
          </div>
          <div class="blog-paragrap" *ngIf="!edit">
            <p [innerHtml]="p.content"></p>
          </div>
          <div class="ads">
            <ng-adsense [adClient]="adClient" [adSlot]="slotID"></ng-adsense>
          </div>
        </div>
      </div>

      <!-- End of col-lg-7-->
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>