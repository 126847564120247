import { Component, OnInit, OnDestroy } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ForumService } from 'src/app/services/forum.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

interface IPushMessage {
  title: string;
  message: string;
  time: number;
  uid?: string;
}

@Component({
  selector: 'app-push-messages',
  templateUrl: './push-messages.component.html',
  styleUrls: ['./push-messages.component.css'],
})
export class PushMessagesComponent implements OnInit, OnDestroy {
  id: string;
  sub: Subscription;
  message: any;

  constructor(
    private _spinner: NgxSpinnerService,
    private _forum: ForumService,
    private _route: ActivatedRoute,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this._spinner.show();
    this.id = this._route.snapshot.paramMap.get('id');
    this.sub = this._forum
      .get_push_notification_message(this.id)
      .subscribe((message: any) => {
        this.message = message;
        this._spinner.hide();
      });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  close = () => {
    this._router.navigate(['/']);
  };
}
