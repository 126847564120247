<h4>Forgot Password</h4>
<form (ngSubmit)="onSubmit()" #resetPasswordForm="ngForm">
  <div class="username_input">
    <label>Username</label>
    <i class="fa fa-user-o"></i>
    <input
      class="input_style"
      type="text"
      placeholder="Type your username"
      required
      [(ngModel)]="username"
      name="u_username"
      #u_username="ngModel"
      autofocus
    />
  </div>
  <div
    *ngIf="!u_username.valid && (u_username.dirty || u_username.touched)"
    class="invalid-feedback"
  >
    Username is required.
  </div>
  <div class="flat-fogot clearfix text-right">
    <label class="lost_pass">
      <a
        [routerLink]="['/auth', 'sign-in']"
        title="Sign in"
        style="color: #007bff;"
      >
        Sign in here if you already have an account
      </a>
    </label>
  </div>
  <span class="wrap-button">
    <span class="wrap-button">
      <input
        class="submit_bt"
        type="submit"
        value="Continue"
        [disabled]="!resetPasswordForm.form.valid"
      />
    </span>
  </span>
</form>

<div class="sign_up_wrap text-center">
  <h6>or</h6>
  <div class="sign_up_btn">
    <a
      [routerLink]="['/auth', 'sign-up']"
      title="Sign Up"
      style="color: #007bff;"
    >
      Sign Up!
    </a>
  </div>
</div>
