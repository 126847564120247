<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-7 hm-3-bg">
        <div class="bg-white hm-3-p">
          <div class="col-lg-12" *ngIf="!previewPost">
            <button type="button" class="btn btn-primary mt-3 mb-5 btn-sm" (click)="previewPost = true">
              Preview Post
            </button>
            <form [formGroup]="addPostForm" (ngSubmit)="onSubmit()">
              <div class="form-group">
                <input type="file" id="post-image" (change)="upload_blog_image($event)" name="imgUrl"
                  class="form-control-sm" />
              </div>

              <div class="form-group">
                <input type="text" class="form-control form-control-sm" formControlName="title" autofocus
                  placeholder="Enter post title" (input)="check_title()" />
              </div>
              <div class="alert alert-danger alert-sm" *ngIf="taken">
                A post with this title already exist
              </div>
              <div class="form-group">
                <select class="form-control form-control-sm" required formControlName="category">
                  <option selected="">Select Category</option>
                  <option *ngFor="let c of categories" [value]="c.id">
                    {{ c.title }}
                  </option>
                </select>
              </div>

              <div class="form-group mt-3">
                <angular-editor [(ngModel)]="content" [ngModelOptions]="{ standalone: true }" [config]="config">
                </angular-editor>
              </div>

              <div class="form-group text-center">
                <button [disabled]="addPostForm.invalid || taken" type="submit" class="btn btn-primary mt-3 btn-sm">
                  Save Post
                </button>

                <button type="button" class="btn btn-sm btn-default mt-3 ml-3" (click)="cancel()">
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-12" *ngIf="previewPost">
            <button type="button" class="btn btn-primary mt-3 mb-5 btn-sm" (click)="previewPost = false">
              Back
            </button>
            <div class="blog-header text-center">
              <h3 class="about-title text-primary" style="text-transform: capitalize;">
                {{ this.addPostForm.get("title").value }}
              </h3>
              <div class="blog-header">
                <small>
                  Posted by
                  <span class="post_aouther">Nonye</span>
                </small>
                <!-- <small class="post_time">{{ dateTime(p.timeCreated) }}</small> -->
                |
                <small class="post-cat">
                  <a class="green" title="{{ this.addPostForm.get('category').value }}"
                    [routerLink]="['/blog-category', this.addPostForm.get('category').value]">
                    {{ category(this.addPostForm.get('category').value) }}
                  </a>
                </small>
              </div>
            </div>
            <div class="blg-img text-center">
              <img src="{{ downloadUrl }}" alt="{{ downloadUrl }}-image" class="img-fluid" />
            </div>
            <div class="blog-paragrap">
              <p [innerHtml]="content"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>