import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import Swal from 'sweetalert2';
import { BehaviorSubject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root',
})
export class ForumService {
  avatarUrl;
  user;
  forum_user = {};
  search: string = '';
  private messageSource = new BehaviorSubject('');
  currentSearch = this.messageSource.asObservable();

  constructor(
    private _router: Router,
    private _afs: AngularFirestore,
    private _spinner: NgxSpinnerService
  ) {
    // this._auth.isAuthenticated()
  }

  changeMessage(message: string) {
    this.messageSource.next(message);
  }

  async add_category(data) {
    let id = this._afs.createId();
    data.uid = id;
    try {
      await this._afs.collection('categories').doc(id).set(data);
      Swal.fire({
        title: 'Success!',
        text: `Category added successfully`,
        background: '#eeeeee',
        type: 'success',
      });
    } catch (error) {
      this._handleError(error);
    }
  }

  async add_blog_category(data) {
    let id = this._afs.createId();
    data.uid = id;
    try {
      await this._afs.collection('blog-categories').doc(id).set(data);
      Swal.fire({
        title: 'Success!',
        text: `Category added successfully`,
        background: '#eeeeee',
        type: 'success',
      });
    } catch (error) {
      this._handleError(error);
    }
  }

  async delete_forum_category(id: string) {
    await this._afs
      .collection(`categories`, (ref) => ref.where('id', '==', id))
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            doc.ref.delete();
          }
        });
      });

    Swal.fire({
      title: 'Success!',
      text: `Category deleted successfully`,
      background: '#eeeeee',
      type: 'success',
    });
  }

  async delete_blog_category(id: string) {
    await this._afs
      .collection(`blog-categories`, (ref) => ref.where('id', '==', id))
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            doc.ref.delete();
          }
        });
      });

    Swal.fire({
      title: 'Success!',
      text: `Category deleted successfully`,
      background: '#eeeeee',
      type: 'success',
    });
  }

  deleted_posts = () => {
    return this._afs.collection('deleted-posts').valueChanges();
  };

  delete_archived_post = (uid: string) => {
    this._afs.collection('deleted-posts').doc(uid).ref.delete();
  };

  restore_archived_post = (post: any) => {
    this._afs
      .collection('posts')
      .doc(post.uid)
      .ref.set(post, { merge: true })
      .then(() => {
        this._router.navigate(['/post/' + post.id]);
        Swal.fire({
          title: 'Success!',
          text: `Post has been restored`,
          background: '#eeeeee',
          type: 'success',
        });
      });

    this._afs.collection('post-titles').doc(post.id).set({
      title: post.id,
      uid: post.uid,
    });

    this.delete_archived_post(post.uid);
  };

  deleted_topics = () => {
    return this._afs.collection('deleted-topics').valueChanges();
  };

  delete_archived_topic = (uid: string) => {
    this._afs.collection('deleted-topics').doc(uid).ref.delete();
  };

  restore_archived_topic = (topic: any) => {
    this._afs
      .collection('topics')
      .doc(topic.uid)
      .ref.set(topic, { merge: true })
      .then(() => {
        this._router.navigate(['/topic/' + topic.id]);
        Swal.fire({
          title: 'Success!',
          text: `Discussion has been restored`,
          background: '#eeeeee',
          type: 'success',
        });
      });

    this._afs.collection('titles').doc(topic.id).set({ title: topic.id });

    this.delete_archived_topic(topic.uid);
  };

  deleted_topic_comments = () => {
    return this._afs.collection('deleted-topic-comments').valueChanges();
  };

  delete_archived_topic_comment = (uid: string) => {
    this._afs.collection('deleted-topic-comments').doc(uid).ref.delete();
  };

  restore_archived_topic_comment = (comment: any) => {
    this._afs
      .collection('topic-comments')
      .doc(comment.uid)
      .ref.set(comment, { merge: true })
      .then(() => {
        this._router.navigate(['/topic/' + comment.post]);
        Swal.fire({
          title: 'Success!',
          text: `Comment has been restored`,
          background: '#eeeeee',
          type: 'success',
        });
      });

    this.delete_archived_topic_comment(comment.uid);
  };

  deleted_comment_replies = () => {
    return this._afs.collection('deleted-topic-comment-replies').valueChanges();
  };

  delete_archived_topic_comment_replies = (uid: string) => {
    this._afs.collection('deleted-topic-comment-replies').doc(uid).ref.delete();
  };

  restore_archived_topic_comment_replies = (comment: any) => {
    this._afs
      .collection('topic-comment-replies')
      .doc(comment.uid)
      .ref.set(comment, { merge: true })
      .then(() => {
        this._router.navigate(['/topic/' + comment.post]);
        Swal.fire({
          title: 'Success!',
          text: `Comment has been restored`,
          background: '#eeeeee',
          type: 'success',
        });
      });

    this.delete_archived_topic_comment_replies(comment.uid);
  };

  async move_category(id: string, data) {
    await this._afs
      .collection(`topics`, (ref) => ref.where('category', '==', id))
      .get()
      .forEach((docs) => {
        docs.docs.map((doc) => {
          doc.ref.update(data);
        });
      });

    this.delete_forum_category(id);
  }

  async move_blog_category(id: string, data) {
    await this._afs
      .collection(`posts`, (ref) => ref.where('category', '==', id))
      .get()
      .forEach((docs) => {
        docs.docs.map((doc) => {
          doc.ref.update(data);
        });
      });

    this.delete_blog_category(id);
  }

  edit_about(data, uid: string) {
    this._afs.collection('about').doc(uid).set(data, { merge: true });
  }

  add_to_featured = (topic: any) => {
    this._afs
      .collection('topics')
      .doc(topic)
      .set({ featured: true }, { merge: true });
  };

  remove_from_featured = (uid: string) => {
    this._afs
      .collection('topics')
      .doc(uid)
      .set({ featured: false }, { merge: true });
  };

  add_post_to_featured = (topic: any) => {
    this._afs
      .collection('posts')
      .doc(topic)
      .set({ featured: true }, { merge: true });
  };

  remove_post_from_featured = (uid: string) => {
    this._afs
      .collection('posts')
      .doc(uid)
      .set({ featured: false }, { merge: true });
  };

  get_about_page() {
    return this._afs.collection('about').valueChanges();
  }

  edit_terms(data, uid: string) {
    this._afs.collection('terms').doc(uid).set(data, { merge: true });
  }

  get_terms_page() {
    return this._afs.collection('terms').valueChanges();
  }

  edit_privacy_policy(data, uid: string) {
    this._afs.collection('privacy-policy').doc(uid).set(data, { merge: true });
  }

  get_privacy_page() {
    return this._afs.collection('privacy-policy').valueChanges();
  }

  edit_content_policy(data, uid: string) {
    this._afs.collection('content-policy').doc(uid).set(data, { merge: true });
  }

  get_content_policy_page() {
    return this._afs.collection('content-policy').valueChanges();
  }

  edit_disclaimer(data, uid: string) {
    this._afs.collection('disclaimer').doc(uid).set(data, { merge: true });
  }

  get_disclaimer_page() {
    return this._afs.collection('disclaimer').valueChanges();
  }

  edit_moderator_guidelines(data, uid: string) {
    this._afs
      .collection('moderator-guidelines')
      .doc(uid)
      .set(data, { merge: true });
  }

  get_moderator_guidlines_page() {
    return this._afs.collection('moderator-guidelines').valueChanges();
  }

  edit_contacts(data, uid: string) {
    this._afs.collection('contacts').doc(uid).set(data, { merge: true });
  }

  get_contacts_page() {
    return this._afs.collection('contacts').valueChanges();
  }

  get_forum_categories() {
    return this._afs
      .collection('categories', (ref) => ref.orderBy('title', 'asc'))
      .valueChanges();
  }

  get_blog_categories() {
    return this._afs
      .collection('blog-categories', (ref) => ref.orderBy('position', 'asc'))
      .valueChanges();
  }

  get_post_category(id: string) {
    return this._afs
      .collection('blog-categories', (ref) => ref.where('id', '==', id))
      .valueChanges();
  }

  get_forum_category(id: string) {
    return this._afs
      .collection('categories', (ref) => ref.where('id', '==', id))
      .valueChanges();
  }

  get_blog_category(category: string) {
    return this._afs
      .collection('posts', (ref) =>
        ref
          .where('category', '==', category)
          .where('active', '==', true)
          .orderBy('featured', 'desc')
          .orderBy('timeCreated', 'desc')
      )
      .valueChanges();
  }

  get_category_topics(category: string) {
    return this._afs
      .collection('topics', (ref) =>
        ref
          .where('category', '==', category)
          .orderBy('featured', 'desc')
          .orderBy('timeCreated', 'desc')
      )
      .valueChanges();
  }

  get_username_changes = () => {
    return this._afs
      .collection('username-changes', (ref) => ref.orderBy('time', 'desc'))
      .valueChanges();
  };

  add_topic(data) {
    const uid = this._afs.createId();
    data.uid = uid;
    try {
      this._afs.collection('topics').doc(uid).set(data);
      Swal.fire({
        title: 'Success!',
        text: 'Topic added successfully',
        background: '#eeeeee',
        type: 'success',
      });

      this._afs.collection('titles').doc(data.id).set({ title: data.id });

      this._router.navigate(['']);
    } catch (e) {
      this._handleError(e);
    }
  }

  check_title(title: string) {
    return this._afs.collection(`titles`).doc(title).get();
  }

  async add_post(data) {
    const uid = this._afs.createId();
    data.uid = uid;
    try {
      this._afs
        .collection('posts')
        .doc(uid)
        .set(data)
        .then(() => {
          Swal.fire({
            title: 'Success!',
            text: 'Post added successfully',
            background: '#eeeeee',
            type: 'success',
          });
          this._router.navigate(['/post', data.id]);
        });
    } catch (e) {
      this._handleError(e);
    }

    this._afs.collection('post-titles').doc(data.id).set({
      title: data.id,
      uid,
    });
  }

  check_blog_title(title: string) {
    return this._afs.collection(`post-titles`).doc(title).get();
  }

  async edit_post(data, id) {
    await this._afs.collection(`posts`).doc(id).set(data, { merge: true });
  }

  async edit_topic(data, id) {
    await this._afs.collection(`topics`).doc(id).set(data, { merge: true });
  }

  async edit_forum_category(data, id) {
    await this._afs.collection(`categories`).doc(id).set(data, { merge: true });
  }

  async edit_blog_category(data, id) {
    await this._afs
      .collection(`blog-categories`)
      .doc(id)
      .set(data, { merge: true });
  }

  get_topics() {
    try {
      return this._afs
        .collection('topics', (ref) =>
          ref.orderBy('featured', 'desc').orderBy('timeCreated', 'desc')
        )
        .valueChanges();
    } catch (e) {
      this._handleError(e);
    }
  }

  get_posts() {
    return this._afs
      .collection('posts', (ref) =>
        ref
          .orderBy('featured', 'desc')
          .where('active', '==', true)
          .orderBy('timeCreated', 'desc')
      )
      .valueChanges();
  }

  get_post(id: string) {
    return this._afs
      .collection(`posts`, (ref) => ref.where('id', '==', id))
      .valueChanges();
  }
  
  get_archived_post(id: string) {
    return this._afs
      .collection(`deleted-posts`, (ref) => ref.where('id', '==', id))
      .valueChanges();
  }

  async report_topic(data) {
    let id = this._afs.createId();
    data.uid = id;
    return await this._afs
      .collection('reported-topics')
      .doc(id)
      .set(data)
      .then((_) => {
        Swal.fire({
          title: 'Success!',
          text:
            'This post has been reported, Appropriate actions will be taken promptly',
          background: '#eeeeee',
          type: 'success',
        });
      });
  }

  async report_user(data) {
    let id = this._afs.createId();
    data.uid = id;
    await this._afs
      .collection('reported-users')
      .doc(id)
      .set(data)
      .then((_) => {
        Swal.fire({
          title: 'Success!',
          text:
            'This user has been reported, Appropriate actions will be taken promptly',
          background: '#eeeeee',
          type: 'success',
        });
      });
  }

  get_reported_topics() {
    return this._afs
      .collection('reported-topics', (ref) => ref.orderBy('date', 'desc'))
      .valueChanges();
  }

  get_reported_users() {
    return this._afs
      .collection('reported-users', (ref) => ref.orderBy('date', 'desc'))
      .valueChanges();
  }

  async add_favorite_topic(data) {
    let id = this._afs.createId();
    data.uid = id;

    await this._afs.collection('favorite-topics').doc(id).set(data);
  }

  async delete_favorite(topic: string, owner: string) {
    await this._afs
      .collection('favorite-topics', (ref) =>
        ref.where('topic', '==', topic).where('owner', '==', owner)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          doc.ref.delete();
        });
      });
  }

  async delete_comment(comment: string, uid: string, id: string) {
    await this._afs
      .collection('topic-comments', (ref) =>
        ref.where('comment', '==', comment).where('uid', '==', uid)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          // add deleted topic-comments
          this._afs
            .collection('deleted-topic-comments')
            .doc(doc.id)
            .set(doc.data())
            .then(() => doc.ref.delete());
        });
      });
  }

  change_password(data, username: string) {
    try {
      this._afs
        .collection('users', (ref) =>
          ref.where('username', '==', `${username}`)
        )
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.update(data);
              window.sessionStorage['slu_password'] = data.password;
              Swal.fire({
                title: 'Success!',
                text: 'Your password has been updated',
                background: '#eeeeee',
                type: 'success',
              });
              setTimeout(() => {
                window.location.assign('/settings');
              }, 2000);
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  change_username = async (username: string, new_username: string) => {
    this._spinner.show();
    try {
      // change username in user collection
      let usersRef = this._afs.collection('users');
      let usersRefC = await usersRef.ref
        .where('username', '==', username)
        .get();
      usersRefC.forEach((doc) => {
        if (doc.exists) {
          doc.ref
            .set(
              {
                username: new_username,
                username_changed_at: Date.now(),
                old_username: username,
              },
              {
                merge: true,
              }
            )
            .then(() => {
              window.sessionStorage.setItem('slu_username', new_username);
              window.localStorage['shutlips_username'] =
                window.localStorage['slu_username'];
              console.log('username changed!');
            });
        }
      });

      // change username in favorite topics
      let ftRef = this._afs.collection('favorite-topics');
      let ftRefC = await ftRef.ref.where('owner', '==', username).get();
      ftRefC.forEach((doc) => {
        if (doc.data().owner === username) {
          doc.ref
            .set(
              {
                owner: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in favorite-topics'));
          if (doc.data().post.author === username) {
            let post = doc.data().post;
            post.author = new_username;
            doc.ref
              .set(
                {
                  post,
                },
                {
                  merge: true,
                }
              )
              .then(() => console.log('username changed in favorite-topics'));
          }
        }
      });

      // change username in liked-comments
      let lcRef = this._afs.collection('liked-comments');
      let lcRefC = await lcRef.ref.get();
      lcRefC.forEach((doc) => {
        if (doc.data().liked_by === username) {
          doc.ref
            .set(
              {
                liked_by: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in liked-comments'));
        }
      });

      // change username in liked-topics
      const ltRef = this._afs.collection('liked-topics');
      let ltRefC = await ltRef.ref.get();
      ltRefC.forEach((doc) => {
        if (doc.data().liked_by === username) {
          doc.ref
            .set(
              {
                liked_by: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in liked-topics'));
        }
      });

      // change username in reported-topics
      const rtRef = this._afs.collection('reported-topics');
      const rtRefC = await rtRef.ref.get();
      rtRefC.forEach((doc) => {
        if (doc.data().reported_by === username) {
          doc.ref
            .set(
              {
                reported_by: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in reported-topics'));
        } else if (doc.data().author === username) {
          doc.ref
            .set(
              {
                author: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in reported-topics'));
        }
      });

      // change username in reported-users
      const ruRef = this._afs.collection('reported-users');
      const ruRefC = await ruRef.ref.get();
      ruRefC.forEach((doc) => {
        if (doc.data().reported_by === username) {
          doc.ref
            .set(
              {
                reported_by: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in reported-users'));
        } else if (doc.data().user === username) {
          doc.ref
            .set(
              {
                user: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in reported-users'));
        }
      });

      // change username in subscriptions
      const subRef = this._afs.collection('subscriptions');
      const subRefC = await subRef.ref.where('username', '==', username).get();
      subRefC.forEach((doc) => {
        if (doc.data().username === username) {
          doc.ref
            .set(
              {
                username: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in subscriptions'));
        }
      });

      // change username in topic-comment-replies
      const tcr_ref = this._afs.collection('topic-comment-replies');
      const tcr_refc = await tcr_ref.ref.get();
      tcr_refc.forEach((doc) => {
        if (doc.data().user === username) {
          doc.ref
            .set(
              {
                user: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() =>
              console.log('username changed in topic-comment-replies')
            );
        } else if (doc.data().author === username) {
          doc.ref
            .set(
              {
                author: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() =>
              console.log('username changed in topic-comment-replies')
            );
        } else if (doc.data().reply_to === username) {
          doc.ref
            .set(
              {
                reply_to: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() =>
              console.log('username changed in topic-comment-replies')
            );
        }
      });

      // change username in topic-comments
      const tc_ref = this._afs.collection('topic-comments');
      const tc_refc = await tc_ref.ref.get();
      tc_refc.forEach((doc) => {
        if (doc.data().user === username) {
          doc.ref
            .set(
              {
                user: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in topic-comments'));
        } else if (doc.data().author === username) {
          doc.ref
            .set(
              {
                author: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in topic-comments'));
        }
      });

      // change username in topics
      const tref = this._afs.collection('topics');
      const trefc = await tref.ref.where('author', '==', username).get();
      trefc.forEach((doc) => {
        if (doc.data().author === username) {
          doc.ref
            .set(
              {
                author: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in topics'));
        }
      });

      // change username in user-messages
      const umref = this._afs.collection('user-messages');
      const umrefc = await umref.ref.get();
      umrefc.forEach((doc) => {
        if (doc.data().sender === username) {
          doc.ref
            .set(
              {
                sender: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in user-messages'));
        } else if (doc.data().recipient === username) {
          doc.ref
            .set(
              {
                recipient: new_username,
              },
              {
                merge: true,
              }
            )
            .then(() => console.log('username changed in user-messages'));
        }
      });

      // change username in user-messages
      let username_uid: string;
      const uref = this._afs.collection('usernames');
      const urefd = await uref.ref.doc(username).get();
      username_uid = await urefd.get('username');
      uref
        .doc(new_username)
        .set({
          username: username_uid,
        })
        .then(() => console.log('added new username in usernames'));

      // delete username in user-messages
      const uref1 = this._afs.collection('usernames');
      await uref1.ref
        .doc(username)
        .delete()
        .then(() => console.log('deleted old username in usernames'));

      // add change username opperation to database
      const unref = this._afs.collection('username-changes');
      await unref.ref
        .add({
          notification: `Change of username from ${username} to ${new_username}`,
          profile: new_username,
          time: Date.now(),
        })
        .then(() =>
          console.log('changed username operation has been saved to database.')
        );

      // get user notifications
      let notifications = [];
      const nref = this._afs
        .collection('notifications')
        .doc(username)
        .collection(username);
      const nrefc = await nref.ref.get();
      nrefc.docs.forEach((doc) => {
        notifications.push(doc.data());
      });
      // recreate new_username with old notifications
      notifications.forEach((not) => {
        this._afs
          .collection('notifications')
          .doc(new_username)
          .collection(new_username)
          .add(not)
          .then(() =>
            console.log('recreated ' + new_username + ' notifications')
          );
      });

      // ==== create and change username in messages
      let message_collections = [];
      let message_collections1 = [];
      let message_collections2 = [];
      let message_collections3 = [];
      // get all collections
      const mref = this._afs
        .collection('messages')
        .doc(username)
        .collection(username);
      const mrefc = await mref.ref.get();
      mrefc.docs.forEach((doc) => {
        message_collections.push(doc.data());
        message_collections1.push(doc.data());
        message_collections2.push(doc.data());
        message_collections3.push(doc.data());
        console.log('gotten message collections');
      });

      // create new doc and collection with new username
      console.log('message_collections start');
      message_collections.forEach((mc) => {
        if (mc.recipient === username) {
          mc.recipient = new_username;
        } else if (mc.sender === username) {
          mc.sender = new_username;
        }
        // recreate messages
        this._afs
          .collection('messages')
          .doc(new_username)
          .collection(new_username)
          .doc(mc.uid)
          .set(mc)
          .then(() => console.log('recreated message uid: ', mc.uid));
      });
      console.log('message_collections end');
      // update messages from sender/recipient messages
      console.log('message_collections1 start');
      message_collections1.forEach((mc) => {
        if (mc.recipient !== username) {
          if (mc.recipient === username) {
            mc.recipient = new_username;
          } else if (mc.sender === username) {
            mc.sender = new_username;
          }
          this._afs
            .collection('messages')
            .doc(mc.recipient)
            .collection(mc.recipient)
            .doc(mc.uid)
            .set(mc)
            .then(() =>
              console.log('updated sender/recipient message uid 1: ', mc.uid)
            );
        }
      });
      console.log('message_collections1 end');
      console.log('message_collections2 start');
      message_collections2.forEach((mc) => {
        if (mc.sender !== username) {
          if (mc.recipient === username) {
            mc.recipient = new_username;
          } else if (mc.sender === username) {
            mc.sender = new_username;
          }
          this._afs
            .collection('messages')
            .doc(mc.sender)
            .collection(mc.sender)
            .doc(mc.uid)
            .set(mc)
            .then(() =>
              console.log('updated sender/recipient message uid 2: ', mc.uid)
            );
        }
      });
      console.log('message_collections2 end');

      // update message-thread
      console.log('message_collections3 start');
      message_collections3.forEach((mc) => {
        this._afs
          .collection('message-thread')
          .doc(mc.uid)
          .collection('thread')
          .get()
          .subscribe((data) => {
            data.forEach((doc) => {
              if (doc.data().recipient === username) {
                doc.ref
                  .set(
                    {
                      recipient: new_username,
                    },
                    {
                      merge: true,
                    }
                  )
                  .then(() =>
                    console.log('changed username in message-thread')
                  );
              } else if (doc.data().sender === username) {
                doc.ref
                  .set(
                    {
                      sender: new_username,
                    },
                    {
                      merge: true,
                    }
                  )
                  .then(() =>
                    console.log('changed username in message-thread')
                  );
              }
            });
          });
      });
      console.log('message_collections3 end');

      // delete the message collection
      const mrefd = this._afs
        .collection('messages')
        .doc(username)
        .collection(username);
      const mrefdc = await mrefd.ref.get();
      mrefdc.forEach((doc) => {
        doc.ref.delete().then(() => {
          console.log('deleted old username messages.');
        });
      });

      // delete the notifications collection
      const nrefd = this._afs
        .collection('notifications')
        .doc(username)
        .collection(username);
      const nrefdc = await nrefd.ref.get();
      nrefdc.forEach((doc) => {
        doc.ref.delete().then(() => {
          console.log('deleted old username notifications.');
        });
      });
      this._spinner.hide();
      Swal.fire({
        title: 'Success!',
        text: `Your username has been successfully changed from ${username} to ${new_username}`,
        background: '#eeeeee',
        type: 'success',
      });
      window.location.reload();
    } catch (error) {
      console.log('error occured while changing username: ', error);
      this._spinner.hide();
    }
  };

  sync_comment_count = (id: string, counts: number) => {
    this._afs
      .collection('topics', (ref) => ref.where('id', '==', id))
      .get()
      .subscribe((snapshot) => {
        if (!snapshot.empty) {
          let comments: number = snapshot.docs[0].data().comments;
          comments = counts;
          snapshot.docs[0].ref.set({ comments }, { merge: true });
        }
      });
  };

  async delete_child_comment(comment: string, uid: string, id: string) {
    await this._afs
      .collection('topic-comment-replies', (ref) =>
        ref.where('comment', '==', comment).where('uid', '==', uid)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          // add deleted topic-comment-replies
          this._afs
            .collection('deleted-topic-comment-replies')
            .doc(doc.id)
            .set(doc.data())
            .then(() => doc.ref.delete());
        });
      });
  }

  check_if_blocked(user: string, username: string) {
    return this._afs
      .collection('blocked-users', (ref) =>
        ref.where('username', '==', user).where('blocked_by', '==', username)
      )
      .valueChanges();
  }

  check_if_favorite(topic: string, owner: string) {
    return this._afs
      .collection('favorite-topics', (ref) =>
        ref.where('topic', '==', topic).where('owner', '==', owner)
      )
      .valueChanges();
  }

  get_favorite_topics(owner: string) {
    return this._afs
      .collection('favorite-topics', (ref) => ref.where('owner', '==', owner))
      .valueChanges();
  }

  edit_comment = async (data: any, uid: string, old_comment: string) => {
    this._afs.collection('topic-comments').doc(uid).set(data, { merge: true });

    this._afs
      .collection('topic-comment-replies', (ref) =>
        ref.where('root_comment', '==', old_comment)
      )
      .get()
      .subscribe((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            doc.ref.set({ root_comment: data.comment }, { merge: true });
          });
        }
      });

    this._afs
      .collection('topic-comment-replies', (ref) =>
        ref.where('parent_comment', '==', old_comment)
      )
      .get()
      .subscribe((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            doc.ref.set({ parent_comment: data.comment }, { merge: true });
          });
        }
      });
  };

  async add_topic_comment(data, username: string) {
    let comment: string = data.comment;
    const message = `${data.user} just commented "${comment.substring(
      0,
      10
    )}..." on your discussion`;
    const link = `/topic/${data.post}`;
    const link_text = `${data.post.split('_').join(' ')}`;
    const time = Date.now();

    let id = this._afs.createId();
    data.uid = id;

    this._afs
      .collection('topic-comments')
      .doc(id)
      .set(data)
      .then((_) => {
        this._afs
          .collection('notifications')
          .doc(data.author)
          .collection(data.author)
          .add({
            message,
            link,
            link_text,
            time,
            comment_id: id,
            opened: false,
          });
      });

    await this._afs
      .collection('topics', (ref) => ref.where('id', '==', data.post))
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            let comments: number;
            comments = doc.data().comments;
            comments += 1;
            const data = { comments: comments };
            doc.ref.update(data);
          }
        });
      });
  }

  edit_child_comment(data: any, uid: string, old_comment: string) {
    this._afs
      .collection('topic-comment-replies')
      .doc(uid)
      .set(data, { merge: true });

    this._afs
      .collection('topic-comment-replies', (ref) =>
        ref.where('parent_comment', '==', old_comment)
      )
      .get()
      .subscribe((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            doc.ref.set({ parent_comment: data.comment }, { merge: true });
          });
        }
      });

    this._afs
      .collection('topic-comment-replies', (ref) =>
        ref.where('root_comment', '==', old_comment)
      )
      .get()
      .subscribe((snapshot) => {
        if (!snapshot.empty) {
          snapshot.forEach((doc) => {
            doc.ref.set({ root_comment: data.comment }, { merge: true });
          });
        }
      });
  }

  async add_topic_comment_reply(data, username: string) {
    let id = this._afs.createId();
    data.uid = id;
    let comment: string = data.comment;

    const message = `${data.user} just replied "${comment.substring(
      0,
      10
    )}..." on your discussion`;
    const link = `/topic/${data.post}`;
    const link_text = `${data.post.split('_').join(' ')}`;
    const time = Date.now();

    const message1 = `${data.user} just replied "${comment.substring(
      0,
      10
    )}..." to your comment "${data.root_comment.substring(0, 10)}..." in`;
    const link1 = `/topic/${data.post}`;
    const link_text1 = `${data.post.split('_').join(' ')}`;
    const time1 = Date.now();

    const message2 = `${data.user} just replied "${comment.substring(
      0,
      10
    )}..." to ${data.reply_to} on your comment "${data.parent_comment.substring(
      0,
      10
    )}..." in`;
    const link2 = `/topic/${data.post}`;
    const link_text2 = `${data.post.split('_').join(' ')}`;
    const time2 = Date.now();

    this._afs
      .collection('topic-comment-replies')
      .doc(id)
      .set(data)
      .then((_) => {
        this.add_user_notification(data.author, {
          message,
          link,
          link_text,
          time,
          comment_id: id,
          opened: false,
        });
        this.add_user_notification(data.reply_to, {
          message: message1,
          link: link1,
          link_text: link_text1,
          time: time1,
          comment_id: id,
          opened: false,
        });
        this.add_user_notification(data.root_user, {
          message: message2,
          link: link2,
          link_text: link_text2,
          time: time2,
          comment_id: id,
          opened: false,
        });
      });

    await this._afs
      .collection('topics', (ref) => ref.where('id', '==', data.post))
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            let comments: number;
            comments = doc.data().comments;
            comments += 1;
            const data = { comments };

            doc.ref.update(data);
          }
        });
      });
  }

  get_trending() {
    return this._afs
      .collection('topics', (ref) =>
        ref.orderBy('comments', 'desc').orderBy('likes', 'desc').limit(10)
      )
      .valueChanges();
  }

  get_topic_comments(id: string) {
    return this._afs
      .collection('topic-comments', (ref) =>
        ref.where('post', '==', id).orderBy('time', 'desc')
      )
      .valueChanges();
  }

  get_topic_comment_replies(id: string) {
    return this._afs
      .collection('topic-comment-replies', (ref) =>
        ref.where('post', '==', id).orderBy('time', 'asc')
      )
      .valueChanges();
  }

  get_topic(id: string) {
    return this._afs
      .collection('topics', (ref) =>
        ref.where('id', '==', id).orderBy('timeCreated', 'desc')
      )
      .valueChanges();
  }

  get_deleted_topic(id: string) {
    return this._afs
      .collection('deleted-topics', (ref) =>
        ref.where('id', '==', id).orderBy('timeCreated', 'desc')
      )
      .valueChanges();
  }

  async like_topic(id: string, author: string, user: string) {
    const message = `${
      user === author ? 'You' : user
    } just liked your discussion`;
    const link = `/topic/${id}`;
    const link_text = `${id.split('_').join(' ')}`;
    const time = Date.now();

    await this._afs
      .collection('topics', (ref) =>
        ref.where('id', '==', id).where('author', '==', author)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            let likes = doc.data().likes;
            likes++;
            const data = { likes: likes };

            doc.ref.update(data);

            this.add_user_notification(author, {
              message,
              link,
              link_text,
              time,
              opened: false,
            });
          }
        });
      });

    await this._afs
      .collection('liked-topics')
      .add({ topic_id: id, topic_author: author, liked_by: user });
  }

  unlike_topic(id: string, author: string, user: string) {
    this._afs
      .collection('liked-topics', (ref) =>
        ref
          .where('topic_id', '==', id)
          .where('topic_author', '==', author)
          .where('liked_by', '==', user)
      )
      .get()
      .subscribe((lt) => {
        lt.forEach((docs) => docs.ref.delete());
      });

    this._afs
      .collection('topics', (ref) =>
        ref.where('id', '==', id).where('author', '==', author)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            let likes = doc.data().likes;
            likes--;
            const data = { likes: likes };

            doc.ref.update(data);
          }
        });
      });
  }

  get_liked_topics(id: string, username: string) {
    return this._afs
      .collection('liked-topics', (ref) =>
        ref.where('topic_id', '==', id).where('liked_by', '==', username)
      )
      .valueChanges();
  }

  async like_comment(
    post: string,
    time: string,
    comment: string,
    owner: string,
    uid: string,
    user: string
  ) {
    const message = `${
      user === owner ? 'You' : user
    } just liked your comment "${comment.trim().substring(0, 10)}" in `;
    const link = `/topic/${post}`;
    const link_text = `${post.split('_').join(' ')}`;
    const ntime = Date.now();
    const comment_id = uid;

    await this._afs
      .collection('topic-comments', (ref) =>
        ref
          .where('post', '==', post)
          .where('time', '==', time)
          .where('user', '==', owner)
      )
      .get()
      .forEach((docs) => {
        docs.docs.map((doc) => {
          let likes = doc.data().likes;
          likes++;
          const data = { likes: likes };

          doc.ref.update(data);

          this.add_user_notification(owner, {
            message,
            link,
            link_text,
            time: ntime,
            comment_id,
            opened: false,
          });
        });
      });

    // add liked comments
    this._afs
      .collection('liked-comments')
      .add({ liked_by: user, post, comment, uid });
  }

  unlike_comment(
    post: string,
    time: string,
    comment: string,
    owner: string,
    uid: string,
    user: string
  ) {
    // remove liked comments
    this._afs
      .collection('liked-comments', (ref) =>
        ref
          .where('liked_by', '==', user)
          .where('post', '==', post)
          .where('comment', '==', comment)
          .where('uid', '==', uid)
      )
      .get()
      .subscribe((lc) => {
        lc.forEach((docs) => docs.ref.delete());
      });

    this._afs
      .collection('topic-comments', (ref) =>
        ref
          .where('post', '==', post)
          .where('time', '==', time)
          .where('user', '==', owner)
      )
      .get()
      .forEach((docs) => {
        docs.docs.map((doc) => {
          let likes = doc.data().likes;
          likes--;
          const data = { likes: likes };

          doc.ref.update(data);
        });
      });
  }

  async like_child_comment(
    post: string,
    time: string,
    comment: string,
    owner: string,
    uid: string,
    user: string
  ) {
    const message = `${
      user === owner ? 'You' : user
    } just liked your comment "${comment.trim().substring(0, 10)}" in `;
    const link = `/topic/${post}`;
    const link_text = `${post.split('_').join(' ')}`;
    const ntime = Date.now();
    const comment_id = uid;

    await this._afs
      .collection('topic-comment-replies', (ref) =>
        ref
          .where('post', '==', post)
          .where('time', '==', time)
          .where('user', '==', owner)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            let likes = doc.data().likes;
            likes++;
            const data = { likes: likes };
            doc.ref.update(data);

            this.add_user_notification(owner, {
              message,
              link,
              link_text,
              time: ntime,
              comment_id,
              opened: false,
            });
          }
        });
      });

    // add liked comments
    this._afs
      .collection('liked-comments')
      .add({ liked_by: user, post, comment, uid });
  }

  async unlike_child_comment(
    post: string,
    time: string,
    comment: string,
    owner: string,
    uid: string,
    user: string
  ) {
    await this._afs
      .collection('topic-comment-replies', (ref) =>
        ref
          .where('post', '==', post)
          .where('time', '==', time)
          .where('user', '==', owner)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            let likes = doc.data().likes;
            likes--;
            const data = { likes: likes };
            doc.ref.update(data);
          }
        });
      });

    // add liked comments
    this._afs
      .collection('liked-comments', (ref) =>
        ref
          .where('liked_by', '==', user)
          .where('post', '==', post)
          .where('comment', '==', comment)
          .where('uid', '==', uid)
      )
      .get()
      .subscribe((lc) => {
        lc.forEach((docs) => docs.ref.delete());
      });
  }

  get_liked_comments(id, username) {
    return this._afs
      .collection('liked-comments', (ref) =>
        ref.where('post', '==', id).where('liked_by', '==', username)
      )
      .valueChanges();
  }

  async dislike_topic(id: string, author: string) {
    await this._afs
      .collection('topics', (ref) =>
        ref.where('id', '==', id).where('author', '==', author)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            let dislikes = doc.data().dislikes;
            dislikes++;
            const data = { dislikes: dislikes };
            doc.ref.update(data);
          }
        });
      });
  }

  async dislike_comment(post: string, time: string, user: string) {
    await this._afs
      .collection('topic-comments', (ref) =>
        ref
          .where('post', '==', post)
          .where('time', '==', time)
          .where('user', '==', user)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            let dislikes = doc.data().dislikes;
            dislikes++;
            const data = { dislikes: dislikes };
            doc.ref.update(data);
          }
        });
      });
  }

  async dislike_child_comment(post: string, time: string, user: string) {
    await this._afs
      .collection('topic-comment-replies', (ref) =>
        ref
          .where('post', '==', post)
          .where('time', '==', time)
          .where('user', '==', user)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            let dislikes = doc.data().dislikes;
            dislikes++;
            const data = { dislikes: dislikes };
            doc.ref.update(data);
          }
        });
      });
  }

  async add_quote(data) {
    const id = this._afs.createId();
    data.uid = id;
    await this._afs
      .collection('quotes')
      .doc(id)
      .set(data, { merge: true })
      .then((_) => {
        Swal.fire({
          title: 'Success!',
          text: 'Quote has been added',
          background: '#eeeeee',
          type: 'success',
        });
      });
  }

  async add_verse(data) {
    const id = this._afs.createId();
    data.uid = id;
    await this._afs
      .collection('verse')
      .doc(id)
      .set(data, { merge: true })
      .then((_) => {
        Swal.fire({
          title: 'Success!',
          text: 'Verse has been added',
          background: '#eeeeee',
          type: 'success',
        });
      });
  }

  async change_quote(data, uid) {
    await this._afs
      .collection('quotes')
      .doc(uid)
      .set(data, { merge: true })
      .then((_) => {
        Swal.fire({
          title: 'Success!',
          text: 'Quote has been added',
          background: '#eeeeee',
          type: 'success',
        });
      });
  }

  async change_verse(data, uid) {
    await this._afs
      .collection('verse')
      .doc(uid)
      .set(data, { merge: true })
      .then((_) => {
        Swal.fire({
          title: 'Success!',
          text: 'Verse has been added',
          background: '#eeeeee',
          type: 'success',
        });
      });
  }

  get_quotes() {
    return this._afs.collection('quotes').valueChanges();
  }

  get_verses() {
    return this._afs.collection('verse').valueChanges();
  }

  getTotalComments(title) {
    return this._afs
      .collection('topic-comments', (ref) => ref.where('post', '==', title))
      .valueChanges();
  }

  getTotalCommentReplies(title) {
    return this._afs
      .collection('topic-comment-replies', (ref) =>
        ref.where('post', '==', title)
      )
      .valueChanges();
  }

  delete_user_topic(id: string, author: string) {
    this._afs
      .collection('topics', (ref) =>
        ref.where('id', '==', id).where('author', '==', author)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            this._router.navigate(['/']);
            // add deleted topics
            this._afs
              .collection('deleted-topics')
              .doc(doc.id)
              .set(doc.data())
              .then(() =>
                doc.ref.delete().then((_) => {
                  Swal.fire({
                    title: 'Success!',
                    text: 'This topic has been deleted',
                    background: '#eeeeee',
                    type: 'success',
                  });
                })
              );
          }
        });
      });
  }

  delete_topic(id: string, author: string) {
    this._afs
      .collection('topics', (ref) =>
        ref.where('id', '==', id).where('author', '==', author)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            this._router.navigate(['/']);
            doc.ref.delete().then((_) => {
              Swal.fire({
                title: 'Success!',
                text: 'This topic has been deleted',
                background: '#eeeeee',
                type: 'success',
              });
            });
          }
        });
      });

    this._afs
      .collection('titles')
      .get()
      .subscribe((snapshot) => {
        snapshot.forEach((doc) => {
          let title: string = doc.data().title;
          if (title === id) {
            doc.ref.delete();
          }
        });
      });

    this._afs
      .collection('favorite-topics')
      .get()
      .subscribe((snapshot) => {
        snapshot.forEach((doc) => {
          let title: string = doc.data().topic;
          if (title === id) {
            doc.ref.delete();
          }
        });
      });

    this._afs
      .collection('liked-comments')
      .get()
      .subscribe((snapshot) => {
        snapshot.forEach((doc) => {
          let title: string = doc.data().post;
          if (title === id) {
            doc.ref.delete();
          }
        });
      });

    this._afs
      .collection('liked-topics')
      .get()
      .subscribe((snapshot) => {
        snapshot.forEach((doc) => {
          let title: string = doc.data().topic_id;
          if (title === id) {
            doc.ref.delete();
          }
        });
      });

    this._afs
      .collection('reported-topics')
      .get()
      .subscribe((snapshot) => {
        snapshot.forEach((doc) => {
          let title: string = doc.data().topic;
          if (title === id) {
            doc.ref.delete();
          }
        });
      });

    this._afs
      .collection('topic-comment-replies')
      .get()
      .subscribe((snapshot) => {
        snapshot.forEach((doc) => {
          let title: string = doc.data().post;
          if (title === id) {
            doc.ref.delete();
          }
        });
      });

    this._afs
      .collection('topic-comments')
      .get()
      .subscribe((snapshot) => {
        snapshot.forEach((doc) => {
          let title: string = doc.data().post;
          if (title === id) {
            doc.ref.delete();
          }
        });
      });
  }

  async delete_post(id: string) {
    await this._afs
      .collection('posts', (ref) => ref.where('id', '==', id))
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            this._router.navigate(['/posts']);
            // add deleted posts
            this._afs
              .collection('deleted-posts')
              .doc(doc.id)
              .set(doc.data())
              .then(() =>
                doc.ref.delete().then((_) => {
                  Swal.fire({
                    title: 'Success!',
                    text: 'This post has been deleted',
                    background: '#eeeeee',
                    type: 'success',
                  });
                  this._afs.collection(`post-titles`).doc(id).ref.delete();
                })
              );
          }
        });
      });

    await this._afs.collection('post-titles').doc(id).delete();
  }

  async saveUserImage(img) {
    try {
      let data = { avatarUrl: img.toString() };
      let username = window.sessionStorage['slu_username'];

      await this._afs
        .collection('users', (ref) => ref.where('username', '==', username))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.set(data, { merge: true }).then((_) => {
                Swal.fire({
                  title: 'Success!',
                  text: 'Image has been updated',
                  background: '#eeeeee',
                  type: 'success',
                });
              });
            }
          });
        });

      await this._afs
        .collection('topics', (ref) => ref.where('author', '==', username))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            doc.ref.set({ author_avatar: img.toString() }, { merge: true });
          });
        });

      await this._afs
        .collection('topic-comments', (ref) =>
          ref.where('user', '==', username)
        )
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            doc.ref.set({ avatarUrl: img.toString() }, { merge: true });
          });
        });

      await this._afs
        .collection('topic-comment-replies', (ref) =>
          ref.where('user', '==', username)
        )
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            doc.ref.set({ avatarUrl: img.toString() }, { merge: true });
          });
        });

      window.sessionStorage['slu_avatarUrl'] = img;
    } catch (e) {
      this._handleError(e);
    }
  }

  get_user_messages(username: string) {
    return this._afs
      .collection(`messages`)
      .doc(username)
      .collection(username, (ref) => ref.orderBy('time', 'desc'))
      .valueChanges();
  }

  get_unread_user_messages(username: string) {
    return this._afs
      .collection(`messages`)
      .doc(`${username}`)
      .collection(username, (ref) => ref.where('status', '==', 'unread'))
      .valueChanges();
  }

  get_user_message_thread(uid) {
    return this._afs
      .collection('message-thread')
      .doc(uid)
      .collection('thread', (ref) => ref.orderBy('time', 'asc'))
      .valueChanges();
  }

  mark_as_read(uid, user) {
    this._afs
      .collection('messages')
      .doc(user)
      .collection(user)
      .doc(uid)
      .ref.set({ status: 'read' }, { merge: true })
      .then(() => console.log('marked as read'))
      .catch((e) => console.log('error mar: ', e));
  }

  reply_message(data, username) {
    // const message = `New message from ${data.sender}`;
    // const time = data.time;

    this._afs
      .collection('message-thread')
      .doc(data.parent_message)
      .collection('thread')
      .add(data)
      .then((_) => {
        // this.add_user_notification(data.recipient, {
        //   message,
        //   time,
        //   link: "/messages",
        //   link_text: "messages",
        //   opened: false,
        // });

        // update message
        this.update_message(data, username);
      });
  }

  update_message(data, username) {
    const {
      message,
      time,
      parent_message,
      recipient,
      recipient_avatar,
      sender,
      sender_avatar,
    } = data;

    this._afs
      .collection('messages')
      .doc(data.sender)
      .collection(data.sender)
      .doc(parent_message)
      .set(
        {
          message,
          time,
          status: 'sent',
          recipient,
          recipient_avatar,
          sender,
          sender_avatar,
        },
        { merge: true }
      );

    this._afs
      .collection('messages')
      .doc(data.recipient)
      .collection(data.recipient)
      .doc(parent_message)
      .set(
        {
          message,
          time,
          status: 'unread',
          recipient,
          recipient_avatar,
          sender,
          sender_avatar,
        },
        { merge: true }
      );
  }

  send_message(data) {
    // const message = `New message from ${data.sender}`;
    // const time = data.time;
    let id = this._afs.createId();

    this._afs
      .collection('messages')
      .doc(`${data.sender}`)
      .collection(`${data.sender}`)
      .get()
      .subscribe((snapshot) => {
        if (!snapshot.empty) {
          // Do not add message, the thread if the user doc exist
          console.log('sender found');

          // Check if the sender already exist
          snapshot.docs.map((msg) => {
            let sender = msg.get('sender');
            let recipient = msg.get('recipient');

            if (
              (data.sender === sender || data.sender === recipient) &&
              (data.recipient === sender || data.recipient === recipient)
            ) {
              console.log(
                'sender conversation with recipient found: ' + msg.id
              );
            }
          });

          // Create new message for sender
          data.uid = id;
          data.status = 'sent';
          this._afs
            .collection('messages')
            .doc(`${data.sender}`)
            .collection(`${data.sender}`)
            .doc(id)
            .set(data);
        }
      });

    this._afs
      .collection('messages')
      .doc(`${data.recipient}`)
      .collection(`${data.recipient}`)
      .get()
      .subscribe((snapshot) => {
        if (!snapshot.empty) {
          // Do not add, the thread if the user doc exist
          console.log('recipient found');

          // Check if the recipient already exist
          snapshot.docs.map((msg) => {
            let sender = msg.get('sender');
            let recipient = msg.get('recipient');

            if (
              (data.sender === sender || data.sender === recipient) &&
              (data.recipient === sender || data.recipient === recipient)
            ) {
              console.log('recipient conversation found: ' + msg.id);

              console.log('This conversation already exist');
            }
          });

          data.uid = id;
          data.status = 'unread';
          this._afs
            .collection('messages')
            .doc(`${data.recipient}`)
            .collection(`${data.recipient}`)
            .doc(id)
            .set(data);
          console.log('created message to recipients collection');
        }
      });

    // Create new message for sender
    data.uid = id;
    data.status = 'sent';
    this._afs
      .collection('messages')
      .doc(`${data.sender}`)
      .collection(`${data.sender}`)
      .doc(id)
      .set(data);
    console.log('created message to senders collection');

    // add to user-messages
    this._afs
      .collection('user-messages')
      .add({ sender: data.sender, recipient: data.recipient });

    // add the message thread of this new conversation
    this._afs
      .collection('message-thread')
      .doc(id)
      .collection('thread')
      .add(data);
    // console.log("added message-thread for both sender and recipient");

    // Create new message for recipient
    data.uid = id;
    data.status = 'unread';
    this._afs
      .collection('messages')
      .doc(`${data.recipient}`)
      .collection(`${data.recipient}`)
      .doc(id)
      .set(data);
    // console.log("created message to recipients collection");

    // send message notification
    // this.add_user_notification(data.recipient, {
    //   message,
    //   time,
    //   link: "/messages",
    //   link_text: "messages",
    //   opened: false,
    // });
  }

  check_user_messages() {
    return this._afs.collection('user-messages').valueChanges();
  }

  async delete_message(
    message: string,
    recipient: string,
    sender: string,
    time: string
  ) {
    this._afs.collection('messages', (ref) =>
      ref
        .where('message', '==', message)
        .where('recipient', '==', recipient)
        .where('sender', '==', sender)
        .where('time', '==', time)
    );
  }

  get_user_profile(username: string) {
    return this._afs
      .collection('users', (ref) => ref.where('username', '==', username))
      .valueChanges();
  }

  add_user_notification(username: string, data) {
    this._afs
      .collection(`notifications`)
      .doc(username)
      .collection(username)
      .add(data);
  }

  get_user_notifications(username: string) {
    return this._afs
      .collection(`notifications`)
      .doc(username)
      .collection(username, (ref) => ref.orderBy('time', 'desc').limit(100))
      .valueChanges();
  }

  get_unopened_notifications(username: string) {
    return this._afs
      .collection(`notifications`)
      .doc(username)
      .collection(username, (ref) => ref.where('opened', '==', false))
      .valueChanges();
  }

  mark_as_opened(not, username) {
    this._afs
      .collection('notifications')
      .doc(username)
      .collection(username, (ref) =>
        ref.where('time', '==', not.time).where('message', '==', not.message)
      )
      .get()
      .subscribe((nots) => {
        nots.forEach((not) => not.ref.set({ opened: true }, { merge: true }));
      });
  }

  get_blocked_user_profile(bu: any) {
    return this._afs
      .collection('users', (ref) => ref.where('username', '==', bu.username))
      .valueChanges();
  }

  block_user(data) {
    this._afs.collection('blocked_users').add(data);
  }

  unblock_user(data: any) {
    this._afs
      .collection('blocked_users', (ref) =>
        ref
          .where('username', '==', data.username)
          .where('blocked_by', '==', data.blocked_by)
      )
      .get()
      .forEach((snapshot) => {
        snapshot.docs.map((doc) => {
          if (doc.exists) {
            doc.ref.delete();
          }
        });
      });
  }

  suspend_user(uid: string) {
    const data = {
      suspended: true,
      suspended_date: Date.now(),
    };
    try {
      this._afs
        .collection('users', (ref) => ref.where('uid', '==', uid))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.set(data, { merge: true });
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  get_suspended_users() {
    return this._afs
      .collection('users', (ref) => ref.where('suspended', '==', true))
      .valueChanges();
  }

  get_deactivated_users() {
    return this._afs
      .collection('users', (ref) => ref.where('deactivated', '==', true))
      .valueChanges();
  }

  get_active_users() {
    return this._afs
      .collection('users', (ref) =>
        ref.where('suspended', '==', false).where('deactivated', '==', false)
      )
      .valueChanges();
  }

  get_male_users() {
    return this._afs
      .collection('users', (ref) => ref.where('gender', '==', 'Male'))
      .valueChanges();
  }

  get_female_users() {
    return this._afs
      .collection('users', (ref) => ref.where('gender', '==', 'Female'))
      .valueChanges();
  }

  get_other_users() {
    return this._afs
      .collection('users', (ref) =>
        ref.where('gender', '==', 'Prefer not to say')
      )
      .valueChanges();
  }

  reactivate_user(uid: string) {
    const data = {
      suspended: false,
    };
    try {
      this._afs
        .collection('users', (ref) => ref.where('uid', '==', uid))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.set(data, { merge: true });
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  make_admin(uid: string) {
    const data = {
      role: 'admin',
    };
    try {
      this._afs
        .collection('users', (ref) => ref.where('uid', '==', uid))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.set(data, { merge: true });
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  revoke_admin(uid: string) {
    const data = {
      role: 'user',
    };
    try {
      this._afs
        .collection('users', (ref) => ref.where('uid', '==', uid))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.set(data, { merge: true });
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  make_moderator(uid: string) {
    const data = {
      role: 'moderator',
    };
    try {
      this._afs
        .collection('users', (ref) => ref.where('uid', '==', uid))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.set(data, { merge: true });
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  revoke_moderator(uid: string) {
    const data = {
      role: 'user',
    };
    try {
      this._afs
        .collection('users', (ref) => ref.where('uid', '==', uid))
        .get()
        .forEach((snapshot) => {
          snapshot.docs.map((doc) => {
            if (doc.exists) {
              doc.ref.set(data, { merge: true });
            }
          });
        });
    } catch (e) {
      this._handleError(e);
    }
  }

  get_blocked_users(username: string) {
    return this._afs
      .collection('blocked_users', (ref) =>
        ref.where('blocked_by', '==', username)
      )
      .valueChanges();
  }

  get_user_topics(username: string) {
    return this._afs
      .collection('topics', (ref) =>
        ref.where('author', '==', username).orderBy('timeCreated', 'desc')
      )
      .valueChanges();
  }

  get_users() {
    return this._afs
      .collection('users', (ref) =>
        ref.orderBy('role', 'asc').orderBy('username', 'asc')
      )
      .valueChanges();
  }

  get_user(uid: string) {
    return this._afs.collection('users').doc(uid).get();
  }

  add_push_notification_message = (data: any) => {
    let uid: string = this._afs.createId();
    data.uid = uid;
    this._afs
      .collection('pnm')
      .doc(uid)
      .set(data, { merge: true })
      .then(() => {
        Swal.fire({
          title: 'Success!',
          text: `${data.title} message has been sent to all users`,
          background: '#eeeeee',
          type: 'success',
        });
      });
  };

  get_push_notification_message = (id: string) => {
    return this._afs.collection('pnm').doc(id).valueChanges();
  };

  get_push_notification_messages = () => {
    return this._afs
      .collection('pnm', (ref) => ref.orderBy('time', 'desc'))
      .valueChanges();
  };

  // if error, console log and notify user
  private _handleError = (error) => {
    Swal.fire({
      title: 'Oops!',
      text: `Something went wrong: ${error}`,
      background: '#eeeeee',
      type: 'error',
    });
  };
}
