import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-archived-content',
  templateUrl: './archived-content.component.html',
  styleUrls: ['./archived-content.component.css'],
})
export class ArchivedContentComponent implements OnInit, OnDestroy {
  id: string;
  topics: any;
  sub: Subscription;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _route: ActivatedRoute,
    private _spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this._spinner.show();
    this._auth.isAuthenticated();

    this.id = this._route.snapshot.paramMap.get('id');
    this._forum.get_deleted_topic(this.id).subscribe((docs) => {
      this.topics = docs;
      this._spinner.hide();
    });
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  dateTime(dt: string) {
    return moment(dt).fromNow();
  }

  split(tagged_users: string) {
    return tagged_users.split(',');
  }

  category(category: string) {
    return category.split('-').join(' ');
  }
}
