import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { Router } from '@angular/router';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
// import {
//   PushNotificationService,
//   PushNotificationOptions,
// } from "ngx-push-notifications";

@Component({
  selector: 'app-send-notifications',
  templateUrl: './send-notifications.component.html',
  styleUrls: ['./send-notifications.component.css'],
})
export class SendNotificationsComponent implements OnInit, OnDestroy {
  sub: Subscription;
  avatar: string;
  username: string;
  role: string;
  messageForm: FormGroup;
  notification_keys: any;
  vapid_keys = {
    public_key: environment.vapid_public_key,
    private_key: environment.vapid_private_key,
  };
  messages = [];

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _router: Router // private _pushNotificationService: PushNotificationService
  ) {
    this.messageForm = new FormGroup({
      title: new FormControl('', [Validators.required]),
      message: new FormControl('', [Validators.required]),
    });

    this._auth.isAuthenticated();
    this.username = window.sessionStorage['slu_username']
      ? window.sessionStorage['slu_username']
      : window.localStorage['slu_username'];
    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];
    this.avatar = window.sessionStorage['slu_avatarUrl']
      ? window.sessionStorage['slu_avatarUrl']
      : window.localStorage['slu_avatarUrl'];
    this.notification_keys = window.sessionStorage['vapid_keys']
      ? window.sessionStorage['vapid_keys']
      : window.localStorage['vapid_keys'];

    this._forum.changeMessage('');
  }

  ngOnInit(): void {
    if (
      window.sessionStorage['slu_role'] == 'admin' ||
      window.localStorage['slu_role'] == 'admin' ||
      window.sessionStorage['slu_role'] == 'moderator' ||
      window.localStorage['slu_role'] == 'moderator'
    ) {
    } else {
      this._router.navigate(['/']);
    }

    this.sub = this._forum
      .get_push_notification_messages()
      .subscribe((m) => (this.messages = m.splice(0, 5)));
  }

  ngOnDestroy = () => {};

  send = () => {
    const { title, message } = this.messageForm.value;
    const data = {
      title,
      message,
      time: Date.now(),
    };
    this._forum.add_push_notification_message(data);
    this.messageForm.reset();
  };

  date = (date) => {
    return moment(date).fromNow();
  };
}
