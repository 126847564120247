<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <!-- End of col-lg-2-->
      <div class="col-lg-10">
        <div class="row">
          <div class="col-lg-12">
            <img src="./assets/images/1-2.jpg" alt="about-banner" class="img-fluid" />
          </div>
          <div class="col-lg-12 about-title">
            <h6>
              Contact Us
            </h6>
          </div>
          <div class="col-lg-8" *ngIf="edit">
            <form (ngSubmit)="edit_page()" #aboutForm="ngForm">
              <h4 class="mb-2">Edit Contacts Page</h4>
              <div class="form-group">
                <angular-editor [(ngModel)]="content" [ngModelOptions]="{ standalone: true }" [config]="config">
                </angular-editor>
              </div>
              <div class="form-group" style="margin-top: 0.5rem;">
                <button [disabled]="!aboutForm.form.valid" type="submit" class="btn btn-sm btn-primary">
                  Save
                </button>
              </div>
            </form>
          </div>
          <div class="col-lg-8 about-content" *ngIf="!edit">
            <div *ngFor="let about of page_content">
              <button class="btn btn-sm btn-primary mb-2" (click)="show_edit(about)" *ngIf="role === 'admin'">
                Edit Page
              </button>
              <div [innerHtml]="about.content"></div>
            </div>
            <div class="footer_social_icons">
              <ul>
                <li>
                  <a href="https://www.facebook.com/shutlips" target="_blank">
                    <i class="fa fa-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/Shutlips" target="_blank">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="https://www.twitter.com/shutlips_brand" target="_blank">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="embed-responsive embed-responsive-1by1">
              <iframe class="embed-responsive-item"
                src="https://docs.google.com/forms/d/e/1FAIpQLSc-2EbNp5krLMJvC5EXgSQL27lxenpq2ZQwjeWiCKmOakEk4w/viewform?embedded=true"></iframe>
            </div>
          </div>
          <div class="col-lg-4 col-12">
            <div class="addsbygoogle"></div>
            <!-- <img
                        src="./assets/images/add-2.png"
                        alt="addsence"
                        class="img-fluid"
                      /> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</section>