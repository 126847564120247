import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { ForumService } from "src/app/services/forum.service";
import { Subscription } from "rxjs";
import * as moment from "moment";
import { DomSanitizer } from "@angular/platform-browser";
import { FilterPipe } from "ngx-filter-pipe";

@Component({
  selector: "app-notifications",
  templateUrl: "./notifications.component.html",
  styleUrls: ["./notifications.component.css"],
})
export class NotificationsComponent implements OnInit {
  username: string;
  suspended: string;
  sub: Subscription;
  notifications = [];
  p: number = 1;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _sanitizer: DomSanitizer,
    private filterPipe: FilterPipe
  ) {}

  ngOnInit() {
    this._auth.isAuthenticated();
    this.username = window.sessionStorage["slu_username"]
      ? window.sessionStorage["slu_username"]
      : window.localStorage["slu_username"];
    this.suspended = window.sessionStorage["slu_suspended"]
      ? window.sessionStorage["slu_suspended"]
      : window.localStorage["slu_suspended"];

    this.sub = this._forum
      .get_user_notifications(this.username)
      .subscribe((not) => {
        this.notifications = not;
      });
  }

  sanitize(value: string) {
    return this._sanitizer.bypassSecurityTrustUrl(value);
  }

  date(dt: string) {
    return moment(dt).fromNow();
  }

  open(notification) {
    this._forum.mark_as_opened(notification, this.username);
  }
}
