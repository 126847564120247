<h4>Reset Password</h4>
<form (ngSubmit)="onResetPassword()" #resetPasswordForm="ngForm" class="login">
  <div class="username_input form-group">
    <label for="newpassword">New Password</label>
    <i class="fa fa-unlock"></i>
    <input
      type="password"
      class="input_style"
      id="newpassword"
      required
      [(ngModel)]="new_password"
      name="u_new_password"
      #u_new_password="ngModel"
      [class.is-valid]="u_new_password.valid"
      placeholder="Enter your new password"
      minlength="6"
      maxlength="20"
    />
  </div>
  <div
    *ngIf="
      !u_new_password.valid && (u_new_password.dirty || u_new_password.touched)
    "
    class="alert alert-danger alert-sm"
  >
    <span *ngIf="u_new_password.errors.required">
      New Password is required.
    </span>
    <span *ngIf="u_new_password.errors.minlength">
      Password must be 6 or more characters.
    </span>
    <span *ngIf="u_new_password.errors.maxlength">
      Password must be 20 or less characters.
    </span>
  </div>

  <div class="username_input form-group">
    <label for="conpassword">Confirm Password&nbsp;*</label>
    <input
      type="password"
      class="form-control"
      id="conpassword"
      required
      [(ngModel)]="confirm_password"
      name="u_confirm_password"
      #u_confirm_password="ngModel"
      placeholder="Confirm new password"
      minlength="6"
      maxlength="20"
      (input)="check_password_match()"
    />
  </div>
  <div
    *ngIf="
      !u_confirm_password.valid &&
      (u_confirm_password.dirty || u_confirm_password.touched)
    "
    class="alert alert-danger alert-sm"
  >
    <span *ngIf="u_confirm_password.errors.required">
      New Password is required.
    </span>
    <span *ngIf="u_confirm_password.errors.minlength">
      Password must be 6 or more characters.
    </span>
    <span *ngIf="u_confirm_password.errors.maxlength">
      Password must be 20 or less characters.
    </span>
  </div>
  <div class="alert alert-sm alert-danger" *ngIf="mismatch">
    Confirm password doesn't match
  </div>

  <span class="wrap-button">
    <span class="wrap-button">
      <input
        class="submit_bt"
        type="submit"
        value="Reset my password"
        [disabled]="!resetPasswordForm.form.valid"
      />
    </span>
  </span>
</form>

<div class="sign_up_wrap text-center">
  <h6>or</h6>
  <div class="sign_up_btn">
    <a [routerLink]="['/auth', 'sign-up']" title="Sign Up" style="color: #007bff;">
      Sign Up!
    </a>
  </div>
</div>
