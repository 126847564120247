import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent implements OnInit, OnDestroy {
  avatarUrl: string = '';
  username: string = '';
  password: string;
  confirm_password: string;
  age: string;
  age_range: string = 'Select age range';
  gender: string;
  select_gender: string = 'Select gender';
  question_one: string;
  q_one: string = 'Select a security question';
  question_two: string;
  q_two: string = 'Select a security question';
  role = 'user';
  answer_one: string;
  answer_two: string;
  suspended = false;
  deactivated = false;
  display_avatar = false;
  selected = false;
  timeCreated: string;
  imgUrl = [];
  genders = ['Male', 'Female'];
  questions1 = [
    'What was your childhood nickname?',
    "What is your father's middle name?",
    'What is the name of your first pet?',
    'what year was your father born?',
  ];
  questions2 = [
    'At what age did you have your first kiss?',
    'What’s the name of your favorite candy?',
    "What is your mother's maiden name?",
    'What was your dream job as a child?',
  ];
  usernames = [];
  sub: Subscription;
  taken: boolean;
  mismatch: boolean;
  recaptcha: string = '';
  theme: string = 'light';
  size: string = 'normal';
  submitted: boolean = false;

  constructor(private _auth: AuthService) {}

  ngOnInit() {
    this.sub = this._auth.get_usernames().subscribe((usernames) => {
      this.usernames = usernames;
    });
  }

  show_password(e) {
    e.preventDefault();

    if (document.getElementById('eye').classList.contains('fa-eye-slash')) {
      document.getElementById('eye').classList.remove('fa-eye-slash');
      document.getElementById('eye').classList.add('fa-eye');
      document.getElementById('password').setAttribute('type', 'text');
    } else if (document.getElementById('eye').classList.contains('fa-eye')) {
      document.getElementById('eye').classList.remove('fa-eye');
      document.getElementById('eye').classList.add('fa-eye-slash');
      document.getElementById('password').setAttribute('type', 'password');
    }
  }

  handleSuccess(event) {
    this.recaptcha = event;
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  setAvatar() {
    if (this.select_gender === 'Male') {
      this.imgUrl = this._auth.male;
    } else if (this.select_gender === 'Female') {
      this.imgUrl = this._auth.female;
    } else if (this.select_gender === 'Prefer not to say') {
      this.imgUrl = this._auth.other;
    }
  }

  onSubmit() {
    if (this.select_gender == 'Select gender') {
      alert('Please, select gender');
    } else if (this.age_range == 'Select age range') {
      alert('Please, select age range');
    } else if (this.q_one == 'Select a security question') {
      alert('Please, select a security question');
    } else if (this.q_two == 'Select a security question') {
      alert('Please, select a security question');
    } else if (this.avatarUrl == '') {
      alert('No avatar selected, please choose an avatar');
      this.setAvatar();
    } else if (this.password.trim() != this.confirm_password.trim()) {
      Swal.fire({
        title: 'Error!',
        text: `The passwords you entered does not match`,
        type: 'error',
        confirmButtonText: 'Cool',
      });
    } else {
      this.gender = this.select_gender;
      this.age = this.age_range;
      this.question_one = this.q_one;
      this.question_two = this.q_two;

      let data = {
        username: this.username.trim().toLowerCase(),
        avatarUrl: this.avatarUrl,
        age: this.age.trim(),
        gender: this.gender.trim(),
        question_one: this.question_one.trim(),
        answer_one: this.answer_one.trim(),
        question_two: this.question_two.trim(),
        answer_two: this.answer_two.trim(),
        role: this.role.trim(),
        suspended: this.suspended,
        deactivated: this.deactivated,
        joined: Date.now(),
      };

      try {
        this._auth.sign_up(
          this.username.trim().toLowerCase(),
          this.password.trim(),
          data
        );
      } catch (error) {
        console.log('$runtimeType::onSubmit() -- ' + error);
      }

      this._resetForm();
    }
  }

  _resetForm() {
    this.username = '';
    this.password = '';
    this.confirm_password = '';
    this.age = '';
    this.gender = '';
    this.question_one = '';
    this.answer_one = '';
    this.question_two = '';
    this.answer_two = '';
  }

  check_password_match() {
    if (this.password.trim() !== this.confirm_password.trim()) {
      this.mismatch = true;
    } else {
      this.mismatch = false;
    }
  }

  show_avatar() {
    if (this.select_gender === 'Select gender') {
      alert('Please select your gender first');
    } else {
      this.display_avatar = !this.display_avatar;
    }
  }

  select_avatar(img, event) {
    this.avatarUrl = img;
    this.show_avatar();
    this.selected = true;
  }

  check_username() {
    if (this.username !== '') {
      this.username = this.username.trim().replace(/[^a-zA-Z0-9 ]/g, '');
      this._auth
        .check_username(
          this.username
            .trim()
            .toLowerCase()
            .replace(/[^a-zA-Z0-9 ]/g, '')
        )
        .subscribe((doc) => {
          if (doc.exists) {
            this.taken = true;
          } else {
            this.taken = false;
          }
        });
    }
  }
}
