import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css'],
})
export class MessagesComponent implements OnInit, OnDestroy {
  messages = [];
  message_threads = [];
  user_messages = [];
  username: string;
  recipient: string;
  message: string;
  message_reply: string;
  chatForm: FormGroup;
  sub: Subscription;
  avatar: string;
  compose: boolean = false;
  chat_window: boolean = false;
  available: boolean;
  self: boolean;
  recipient_data = {
    image: '',
    username: '',
  };
  chat: any;
  suspended: string;
  conversation_exist: boolean = false;
  userFilter: any;
  search_user: string = '';

  constructor(private _auth: AuthService, private _forum: ForumService) {
    this.chatForm = new FormGroup({
      message: new FormControl('', [Validators.required]),
    });
  }

  ngOnInit() {
    this._auth.isAuthenticated();
    this.username = window.sessionStorage['shutlips_username']
      ? window.sessionStorage['shutlips_username']
      : window.localStorage['shutlips_username'];
    this.avatar = window.sessionStorage['slu_avatarUrl']
      ? window.sessionStorage['slu_avatarUrl']
      : window.localStorage['slu_avatarUrl'];
    this.suspended = window.sessionStorage['slu_suspended']
      ? window.sessionStorage['slu_suspended']
      : window.localStorage['slu_suspended'];
    this.recipient =
      window.sessionStorage['user_to_message'] != '' ||
      window.sessionStorage['user_to_message'] == null
        ? window.sessionStorage['user_to_message']
        : '';
    window.sessionStorage.removeItem('user_to_message');

    this.sub = this._forum.get_user_messages(this.username).subscribe((m) => {
      this.messages = m;
    });

    this.sub = this._forum
      .check_user_messages()
      .subscribe((messages) => (this.user_messages = messages));

    this.sub = this._forum
      .get_unread_user_messages(this.username)
      .subscribe((messages) => {
        messages.forEach((m) => {
          if (this.chat) {
            if (m.recipient === this.chat.recipient) {
              if (this.username !== m.sender) {
                this._forum.mark_as_read(m.uid, m.recipient);
              }
            }
          }
        });
      });

    if (history.state.recipient) {
      this.compose = true;
      setTimeout(() => {
        this.recipient = history.state.recipient;
        this.check_username();
      }, 1000);
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  dateTime(dt: string): string {
    return moment(dt).fromNow();
  }

  open(m: any, uid: string) {
    this.compose = false;
    this.chat_window = true;
    this.chat = m;
    if (m.recipient !== this.username) {
      this.recipient_data.username = m.recipient;
      this.recipient_data.image = m.recipient_avatar;
    } else {
      this.recipient_data.username = m.sender;
      this.recipient_data.image = m.sender_avatar;
    }
    // mark the message as read
    if (this.username !== m.sender) {
      this._forum.mark_as_read(uid, m.recipient);
    }
    this._forum
      .get_user_message_thread(m.uid)
      .subscribe((messages) => (this.message_threads = messages));
    // this._forum.mark_as_read(uid, this.username);
  }

  onSubmit() {
    this.compose = false;
    this.chat_window = false;
    if (
      this.recipient_data.username !== '' &&
      this.recipient_data.image !== '' &&
      this.message !== ''
    ) {
      const data = {
        recipient: this.recipient_data.username,
        recipient_avatar: this.recipient_data.image,
        sender: this.username,
        sender_avatar: this.avatar,
        message: this.message.trim(),
        time: Date.now(),
      };

      this._forum.send_message(data);
      this._resetForm();
    } else {
      alert('Please check your internet connection and try again.');
    }
  }

  onReply() {
    if (this.message_reply !== '') {
      if (this.chat.recipient !== this.username) {
        this.recipient_data.username = this.chat.recipient;
        this.recipient_data.image = this.chat.recipient_avatar;
      } else if (this.chat.sender !== this.username) {
        this.recipient_data.username = this.chat.sender;
        this.recipient_data.image = this.chat.sender_avatar;
      }
      const message = {
        recipient: this.recipient_data.username,
        recipient_avatar: this.recipient_data.image,
        sender: this.username,
        sender_avatar: this.avatar,
        message: this.message_reply.trim(),
        time: Date.now(),
        parent_message: this.chat.uid,
      };
      this._forum.reply_message(message, this.username);
      this._resetForm();
    }
  }

  delete_message(
    message: string,
    recipient: string,
    sender: string,
    time: string
  ) {
    this._forum.delete_message(message, recipient, sender, time);
  }

  _resetForm() {
    this.message = '';
    this.recipient = '';
    this.message_reply = '';
  }

  new_message() {
    this.compose = !this.compose;
    this.chat_window = false;
    this.recipient_data.image = '';
    this.recipient_data.username = '';
  }

  check_username() {
    if (this.recipient !== '') {
      this._auth
        .check_username(this.recipient.trim().toLowerCase())
        .subscribe((doc) => {
          if (doc.exists) {
            if (
              this.recipient.trim().toLowerCase() ===
              this.username.trim().toLowerCase()
            ) {
              this.self = true;
            } else {
              this.self = false;
              this.sub = this._forum
                .get_user(doc.data().username)
                .subscribe((doc) => {
                  this.recipient_data.username = doc.data().username;
                  this.recipient_data.image = doc.data().avatarUrl;

                  // check if recipient already exist in messages
                  for (let i = 0; i < this.messages.length; i++) {
                    const element = this.messages[i];
                    let sender: string = element.sender;
                    let recipient: string = element.recipient;
                    if (
                      sender.trim().toLowerCase() ===
                        this.recipient.trim().toLowerCase() ||
                      recipient.trim().toLowerCase() ===
                        this.recipient.trim().toLowerCase()
                    ) {
                      this.conversation_exist = true;
                    }
                  }
                });
            }
            this.available = true;
          } else {
            this.available = false;
          }
        });
    }

    if (this.recipient === '') {
      this.recipient_data.image = '';
      this.recipient_data.username = '';
      this.conversation_exist = false;
    }
  }

  first10(content: string): string {
    if (content.length >= 20) {
      return `${content.substring(0, 20)}...`;
    } else {
      return content;
    }
  }

  search(event) {
    // let recipient = { recipient: event };
    // let sender = { sender: event };
    // this.userFilter = recipient || sender;
    // Declare variables
    var input, filter, ul, li, a, i, txtValue, span;
    input = this.search_user;
    filter = input.toUpperCase();
    ul = document.getElementById('message-ul');
    li = ul.getElementsByTagName('li');
    // span = document.getElementById("span");

    // Loop through all list items, and hide those who don't match the search query
    for (i = 0; i < li.length; i++) {
      a = li[i].getElementsByTagName('a')[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = '';
        // span.style.display = "none";
      } else {
        li[i].style.display = 'none';
        // span.style.display = "";
      }
    }
  }

  cancel() {
    this.compose = !this.compose;
    this.recipient_data.image = '';
    this.recipient_data.username = '';
    this.recipient = '';
  }
}
// ERROR Error: "ExpressionChangedAfterItHasBeenCheckedError: Expression has changed after it was checked. Previous value: '32 minutes ago'. Current value: '33 minutes ago'."
//     Angular 4
//     MessagesComponent_div_6_ul_9_li_1_Template messages.component.html:196
