import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class TopicComponent implements OnInit, OnDestroy {
  uid: string;
  username: string;
  id: string;
  comment_message: string;
  avatar: string;
  post: string;
  comment_reply: string;
  reply_to: string;
  parent_comment: string;
  cuser: string;
  ccomment: string;
  ctime: string;
  parent_comment_time: string;
  report_topic: string;
  topics = [];
  topic_comments = [];
  comment_replies = [];
  show_form = false;
  comment_form = false;
  report_topic_form = false;
  edit_topic_form = false;
  edit_comment = false;
  edit_child_comment = false;
  categories = [];
  favorites = [];
  content: string;
  root_comment: string;
  root_comment_time: string;
  topic_category: string;
  tuid: string = '';
  role: string = '';
  sub: Subscription;
  blocked_users = [];
  liked_topics = [];
  liked_comments = [];
  comment_uid: string;
  current_comment: string;
  suspended: string;
  parent_comment_uid: any;
  not_available: boolean;
  old_comment: string;
  adClient = environment.adsense_profile_preview.adClient;
  slotID = environment.adsense_disscussion.slotID;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _spinner: NgxSpinnerService,
    private _share: NgNavigatorShareService,
    private _afs: AngularFirestore
  ) {}

  ngOnInit() {
    this._spinner.show();
    this._auth.isAuthenticated();
    this.username = window.sessionStorage['slu_username']
      ? window.sessionStorage['slu_username']
      : window.localStorage['slu_username'];
    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];
    this.avatar = window.sessionStorage['slu_avatarUrl']
      ? window.sessionStorage['slu_avatarUrl']
      : window.localStorage['slu_avatarUrl'];
    this.uid = window.sessionStorage['slu_uid']
      ? window.sessionStorage['slu_uid']
      : window.localStorage['slu_uid'];
    this.suspended = window.sessionStorage['slu_suspended']
      ? window.sessionStorage['slu_suspended']
      : window.localStorage['slu_suspended'];

    this.id = this._route.snapshot.paramMap.get('id');
    this._forum.get_topic(this.id).subscribe((docs) => {
      this.topics = docs;
    });
    this.sub = this._forum
      .get_forum_categories()
      .subscribe((c) => (this.categories = c));
    this.sub = this._forum.get_topic_comments(this.id).subscribe((tc) => {
      this.topic_comments = tc;
      this.sub = this._forum
        .get_blocked_users(this.username)
        .subscribe((bu) => {
          if (bu) {
            this.blocked_users = bu;
            for (let i = 0; i < tc.length; i++) {
              for (let j = 0; j < bu.length; j++) {
                let count = 1;
                var index = tc.indexOf(tc[i]);
                if (
                  this.blocked_users[j].username === this.topic_comments[i].user
                ) {
                  count++;
                  this.topic_comments.splice(index, count);
                }
              }
            }
          }
        });

      this._spinner.hide();
    });
    this.sub = this._forum
      .get_topic_comment_replies(this.id)
      .subscribe((tcr) => {
        if (tcr) {
          this.comment_replies = tcr;

          this.sub = this._forum
            .get_blocked_users(this.username)
            .subscribe((bu) => {
              if (bu) {
                this.blocked_users = bu;
                for (let i = 0; i < tcr.length; i++) {
                  for (let j = 0; j < bu.length; j++) {
                    let count = 1;
                    var index = tcr.indexOf(tcr[i]);
                    if (
                      this.blocked_users[j].username ===
                      this.comment_replies[i].user
                    ) {
                      count++;
                      this.comment_replies.splice(index, count);
                    }
                  }
                }
              }
            });

          // Sync comment counts
          this._forum.sync_comment_count(
            this.id,
            this.topic_comments.length + this.comment_replies.length
          );
        }
      });
    this.sub = this._forum
      .check_if_favorite(this.id, this.username)
      .subscribe((f) => (this.favorites = f));

    this.sub = this._forum
      .get_liked_topics(this.id, this.username)
      .subscribe((lt) => (this.liked_topics = lt));

    this.sub = this._forum
      .get_liked_comments(this.id, this.username)
      .subscribe((lc) => (this.liked_comments = lc));

    setTimeout(() => {
      if (history.state.data) {
        let element = document.getElementById(history.state.data);
        element.scrollIntoView({
          behavior: 'smooth',
          inline: 'nearest',
        });
      }
    }, 1000);

    setTimeout(() => {
      if (document.getElementById('not_available') !== null) {
        document.getElementById('not_available').classList.remove('invisible');
        document.getElementById('not_available').classList.add('visible');
      }
    }, 4000);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this._forum.search = '';
  }

  dateTime(dt: string) {
    return moment(dt).fromNow();
  }

  split(tagged_users: string) {
    return tagged_users.split(',');
  }

  category(category: string) {
    return category.split('-').join(' ');
  }

  edit(category: string, content: string, uid: string, time): void {
    if (this.lessThanOneHourAgo(time)) {
      this.edit_topic_form = !this.edit_topic_form;
      this.topic_category = category;
      this.tuid = uid;
      this.content = content;
    } else {
      if (this.role === 'user') {
        alert('Time has elapsed on when you can edit this post.');
      } else {
        this.edit_topic_form = !this.edit_topic_form;
        this.topic_category = category;
        this.tuid = uid;
        this.content = content;
      }
    }
  }

  edit_topic() {
    const data = {
      content: this.content.trim(),
      category: this.topic_category,
      edited: true,
      edited_time: Date.now(),
    };

    this._forum.edit_topic(data, this.tuid);
    this.edit_topic_form = !this.edit_topic_form;
    this.topic_category = '';
    this.content = '';
  }

  lessThanOneHourAgo = (date: number) =>
    moment(date).isAfter(moment().subtract(1, 'hours'));

  delete(id: string, author: string, time: number) {
    if (this.lessThanOneHourAgo(time)) {
      let confirm = window.confirm(
        'Are you sure you want to delete this discussion?'
      );
      if (confirm) {
        this._forum.delete_user_topic(id, author);
      }
    } else {
      if (this.role === 'user') {
        alert('Time has elapsed on when you can make a deletion of this post.');
      } else {
        let confirm = window.confirm(
          'Are you sure you want to delete this discussion?'
        );
        if (confirm) {
          this._forum.delete_user_topic(id, author);
        }
      }
    }
  }

  like(id: string, author: string) {
    this._forum.like_topic(id, author, this.username);
  }

  unlike(id: string, author: string) {
    this._forum.unlike_topic(id, author, this.username);
  }

  like_comment(
    post: string,
    time: string,
    comment: string,
    owner: string,
    uid: string
  ) {
    this._forum.like_comment(post, time, comment, owner, uid, this.username);
  }

  unlike_comment(
    post: string,
    time: string,
    comment: string,
    owner: string,
    uid: string
  ) {
    this._forum.unlike_comment(post, time, comment, owner, uid, this.username);
  }

  get_liked_comments(comment: string, uid: string): number {
    let count = 0;
    this.liked_comments.forEach((lc) => {
      if (lc.comment === comment && lc.uid === uid) {
        count++;
      }
    });
    return count;
  }

  like_child_comment(
    post: string,
    time: string,
    comment: string,
    owner: string,
    uid: string
  ) {
    this._forum.like_child_comment(
      post,
      time,
      comment,
      owner,
      uid,
      this.username
    );
  }

  unlike_child_comment(
    post: string,
    time: string,
    comment: string,
    owner: string,
    uid: string
  ) {
    this._forum.unlike_child_comment(
      post,
      time,
      comment,
      owner,
      uid,
      this.username
    );
  }

  dislike(id: string, author: string) {
    this._forum.dislike_topic(id, author);
  }

  dislike_comment(post: string, time: string, user: string) {
    this._forum.dislike_comment(post, time, user);
  }

  dislike_child_comment(post: string, time: string, user: string) {
    this._forum.dislike_child_comment(post, time, user);
  }

  show_report_form() {
    this.report_topic_form = !this.report_topic_form;
  }

  favorite(post) {
    const data = {
      owner: this.username,
      topic: this.id,
      post,
    };

    this._forum.add_favorite_topic(data);
  }

  remove_favorite(post) {
    const data = {
      owner: this.username,
      topic: this.id,
      post,
    };

    this._forum.delete_favorite(data.topic, data.owner);
  }

  delete_comment(comment, uid, time) {
    if (this.lessThanOneHourAgo(time)) {
      const con = confirm('Are you sure you want to delete this comment?');
      if (con) {
        this._forum.delete_comment(comment, uid, this.id);
      }
    } else {
      if (this.role === 'user') {
        alert(
          'Time has elapsed on when you can make a deletion of this comment.'
        );
      } else {
        const confirm = window.confirm(
          'Are you sure you want to delete this comment?'
        );
        if (confirm) {
          this._forum.delete_comment(comment, uid, this.id);
        }
      }
    }
  }

  delete_child_comment(comment, uid, time) {
    if (this.lessThanOneHourAgo(time)) {
      const con = confirm('Are you sure you want to delete this comment?');
      if (con) {
        this._forum.delete_child_comment(comment, uid, this.id);
      }
    } else {
      if (this.role === 'user') {
        alert(
          'Time has elapsed on when you can make a deletion of this comment.'
        );
      } else {
        const confirm = window.confirm(
          'Are you sure you want to delete this comment?'
        );
        if (confirm) {
          this._forum.delete_child_comment(comment, uid, this.id);
        }
      }
    }
  }

  report(id: string, author: string, title: string) {
    const data = {
      topic: id,
      title: title,
      author: author,
      reported_by: this.username,
      reason: this.report_topic,
      date: Date.now(),
    };

    this.report_topic_form = !this.report_topic_form;
    this._forum.report_topic(data);
  }

  cancel_report() {
    this.report_topic_form = !this.report_topic_form;
  }

  show_comment(id: string) {
    this.show_form = !this.show_form;
    this.post = id;
  }

  onSubmit(author: string) {
    const data = {
      post: this.id,
      comment: this.comment_message.trim(),
      user: this.username,
      time: Date.now(),
      avatarUrl: this.avatar,
      likes: 0,
      author,
    };

    this._forum.add_topic_comment(data, this.username);
    this.show_form = !this.show_form;
    this.comment_message = '';
    this.comment_reply = '';
    this.reply_to = '';
    this.parent_comment = '';
    this.cuser = '';
    this.ccomment = '';
  }

  reply_comment(user, comment, time, uid) {
    this.comment_form = !this.comment_form;
    this.reply_to = user;
    this.parent_comment = comment;
    this.parent_comment_time = time;
    this.comment_reply = '@' + this.reply_to + ` `;
  }

  edit_topic_comment(comment: string, uid: string, time) {
    if (this.lessThanOneHourAgo(time)) {
      this.edit_comment = !this.edit_comment;
      this.current_comment = comment;
      this.old_comment = comment;
      this.comment_uid = uid;
    } else {
      if (this.role === 'user') {
        alert('Time has elapsed on when you can edit this comment.');
      } else {
        this.edit_comment = !this.edit_comment;
        this.current_comment = comment;
        this.old_comment = comment;
        this.comment_uid = uid;
      }
    }
  }

  save_comment() {
    const data = {
      comment: this.current_comment.trim(),
      edited_at: Date.now(),
    };

    this._forum.edit_comment(data, this.comment_uid, this.old_comment);
    this.edit_comment = !this.edit_comment;
    this.current_comment = '';
    this.old_comment = '';
    this.comment_uid = '';
  }

  save_child_comment(uid) {
    const data = {
      comment: this.current_comment.trim(),
      edited_at: Date.now(),
    };

    this.edit_child_comment = !this.edit_child_comment;
    this._forum.edit_child_comment(data, uid, this.old_comment);
    this.current_comment = '';
    this.comment_uid = '';
  }

  send_reply(author: string, root_user: string) {
    const data = {
      post: this.id,
      comment: this.comment_reply.replace(`@${this.reply_to} `, '').trim(),
      parent_comment: this.parent_comment,
      parent_comment_time: this.parent_comment_time,
      root_user,
      root_comment: '',
      root_comment_time: '',
      user: this.username,
      reply_to: this.reply_to,
      time: Date.now(),
      avatarUrl: this.avatar,
      likes: 0,
      author,
    };
    this._forum.add_topic_comment_reply(data, this.username);
    this.comment_form = !this.comment_form;
    this.reply_to = '';
    this.comment_reply = '';
    this.ctime = '';
    this.parent_comment_time = '';
  }

  reply_child_comment(user, comment, time, rootc, rootct, uid) {
    this.comment_form = !this.comment_form;
    this.reply_to = user;
    this.parent_comment = comment;
    this.parent_comment_time = time;
    this.root_comment = rootc;
    this.root_comment_time = rootct;
    this.comment_reply = '@' + this.reply_to + ` `;
    this.comment_uid = uid;
  }

  edit_topic_child_comment(puid, comment, time, uid) {
    if (this.lessThanOneHourAgo(time)) {
      this.edit_child_comment = !this.edit_child_comment;
      this.comment_uid = uid;
      this.current_comment = comment;
      this.old_comment = comment;
      this.parent_comment_time = time;
      this.parent_comment_uid = puid;
    } else {
      if (this.role === 'user') {
        alert('Time has elapsed on when you can edit this comment.');
      } else {
        this.edit_child_comment = !this.edit_child_comment;
        this.comment_uid = uid;
        this.current_comment = comment;
        this.old_comment = comment;
        this.parent_comment_time = time;
        this.parent_comment_uid = puid;
      }
    }
  }

  send_child_reply(author: string, root_user) {
    const data = {
      post: this.id,
      comment: this.comment_reply.replace(`@${this.reply_to}`, '').trim(),
      parent_comment: this.parent_comment,
      parent_comment_time: this.parent_comment_time,
      root_user,
      root_comment: this.root_comment,
      root_comment_time: this.root_comment_time,
      user: this.username,
      reply_to: this.reply_to,
      time: Date.now(),
      avatarUrl: this.avatar,
      likes: 0,
      author,
    };

    this._forum.add_topic_comment_reply(data, this.username);
    this.comment_form = !this.comment_form;
    this.reply_to = '';
    this.comment_reply = '';
    this.ctime = '';
    this.parent_comment_time = '';
  }

  share = (title: string): void => {
    if (this.role === ('admin' || 'moderator')) {
      this._share
        .share({
          title,
          text: 'Check out this interesting discussion on shutlips - ',
          url: 'https://shutlips.com/topic/' + this.id,
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    } else {
      this._share
        .share({
          title,
          text: '',
          url: 'https://shutlips.com/topic/' + this.id,
        })
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  check_if_mobile = (): boolean => {
    let bool: boolean;
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      )
    ) {
      bool = true;
    } else {
      bool = false;
    }
    return bool;
  };

  add_to_featured = (uid: string) => {
    this._forum.add_to_featured(uid);
  };

  remove_from_featured = (uid: string) => {
    this._forum.remove_from_featured(uid);
  };
}
