<section id="one">
  <div class="container">
    <div class="row">
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <!-- End of col-lg-2-->

      <div class="col-md-7" *ngIf="suspended === 'true'">
        <div class="alert alert-danger alert-sm">
          Your account has been suspended, please contact the moderator at
          moderator@shutlips.com
        </div>
      </div>

      <div class="col-lg-7 text-center" *ngIf="suspended === 'false'">
        <h4 class="mb-3 mt-3">Here's what happened while you were away</h4>

        <small class="text-center" *ngIf="notifications.length === 0">
          No notifications found
        </small>
        <div class="list-group">
          <div
            class="list-group-item list-group-item-action"
            *ngFor="
              let n of notifications
                | paginate: { itemsPerPage: 20, currentPage: p }
            "
          >
            <div class="row">
              <div class="col-md-12">
                <div class="w-100 text-left">
                  <small [innerHtml]="n.message" *ngIf="!n.link"></small>
                  <br />
                  <span *ngIf="n.link">
                    <a
                      [routerLink]="n.link"
                      [state]="{ data: n.comment_id }"
                      (click)="open(n)"
                    >
                      <small [innerHtml]="n.message"></small>
                    </a>
                  </span>
                  <small>
                    <b>
                      {{ date(n.time) }} -
                      {{ n.opened ? "opened" : "unopened" }}
                    </b>
                  </small>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>

      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
