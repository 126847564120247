import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css'],
})
export class UserProfileComponent implements OnInit, OnDestroy {
  id: string;
  username: string;
  uid: string;
  report_user: string;
  joined: string;
  users = [];
  user_topics = [];
  blocked_users = [];
  blocked = false;
  report_user_form = false;
  sub: Subscription;
  admin: boolean = false;
  moderator: boolean = false;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _route: ActivatedRoute
  ) {}

  async ngOnInit() {
    this._auth.isAuthenticated();
    this.username = window.sessionStorage['slu_username']
      ? window.sessionStorage['slu_username']
      : window.localStorage['slu_username'];
    this.uid = window.sessionStorage['slu_uid']
      ? window.sessionStorage['slu_uid']
      : window.localStorage['slu_uid'];
    this.joined = window.sessionStorage['slu_joined']
      ? window.sessionStorage['slu_joined']
      : window.localStorage['slu_joined'];

    this.id = this._route.snapshot.paramMap.get('id');

    if (
      window.sessionStorage['slu_role'] == 'admin' ||
      window.localStorage['slu_role'] == 'admin'
    ) {
      this.admin = true;
    } else {
      this.admin = false;
    }
    if (
      window.sessionStorage['slu_role'] == 'moderator' ||
      window.localStorage['slu_role'] == 'moderator'
    ) {
      this.moderator = true;
    } else {
      this.moderator = false;
    }
    this.sub = this._forum.get_user_profile(this.id).subscribe((up) => {
      this.users = up;
    });
    this.sub = this._forum
      .get_user_topics(this.id)
      .subscribe((ut) => (this.user_topics = ut));

    this.sub = this._forum.get_blocked_users(this.username).subscribe((bu) => {
      this.blocked_users = bu;
      for (let j = 0; j < bu.length; j++) {
        if (this.blocked_users[j].username === this.id) {
          this.blocked = true;
        }
      }
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  dateTime(dt: string): string {
    return moment(dt).fromNow();
  }

  first30(content: string) {
    return `${content.substring(0, 50)}...`;
  }

  set_user(username: string) {
    window.sessionStorage['user_to_message'] = username;
  }

  show_report_form() {
    this.report_user_form = !this.report_user_form;
  }

  cancel_report() {
    this.report_user_form = !this.report_user_form;
  }

  report() {
    const data = {
      user: this.id,
      reported_by: this.username,
      reason: this.report_user,
      date: Date.now(),
    };

    this.report_user_form = !this.report_user_form;
    this._forum.report_user(data);
  }

  suspend_user(uid: string) {
    this._forum.suspend_user(uid);
  }

  unsuspend_user(uid: string) {
    this._forum.reactivate_user(uid);
  }

  disable_user(uid: string) {
    this._auth.disable_user(uid);
  }

  enable_user(uid: string) {
    this._auth.enable_user(uid);
  }

  block_user(u: any) {
    const data = {
      username: u.username,
      blocked_by: this.username,
      avatarUrl: u.avatarUrl,
    };
    let con = confirm('Are you sure you want to block this user?');
    if (con) {
      this._forum.block_user(data);
      this.blocked = true;
    }
  }

  unblock_user(u: any) {
    const data = {
      username: u.username,
      blocked_by: this.username,
      avatarUrl: u.avatarUrl,
    };
    let con = confirm('Are you sure you want to unblock this user?');
    if (con) {
      this._forum.unblock_user(data);
      this.blocked = false;
    }
  }
}
