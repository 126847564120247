<section id="one">
  <div class="container">
    <div class="row">
      <!-- End of col-lg-2-->
      <div class="col-lg-2 Categories">
        <app-category-list></app-category-list>
      </div>
      <div class="col-lg-7 hm-3-bg">
        <div class="bg-white hm-3-p">
          <div class="row cat-h3-top">
            <div class="col-lg-4">
              <img
                src="{{ c.category_image }}"
                class="image-fluid"
                alt="topbar"
                *ngFor="let c of categories"
              />
            </div>
            <div class="col-lg-8 cat-h3-top-right">
              <h3
                class="about-title text-primary"
                style="text-transform: capitalize;"
              >
                Forum: {{ category(id) }}
              </h3>
              <p style="margin-left: 15px;">
                {{
                  category_topics.length == 0
                    ? "There are no"
                    : category_topics.length
                }}
                disscussions in this category
              </p>
              <button
                class="btn btn-primary btn-sm"
                type="button"
                data-toggle="collapse"
                data-target="#collapseExample"
                aria-expanded="false"
                aria-controls="collapseExample"
                style="margin-left: 15px;"
              >
                Category Description
              </button>
              <hr />
            </div>
          </div>

          <div class="collapse" id="collapseExample">
            <div class="single_blog">
              <div class="card card-body">
                <small
                  *ngFor="let c of categories"
                  [innerHtml]="c.description"
                ></small>
              </div>
            </div>
          </div>

          <div
            class="single_blog text-center"
            *ngIf="(category_topics | filterBy: userFilter).length === 0"
          >
            <div class="row">
              No matching post found
            </div>
          </div>

          <div class="single_blog">
            <div
              class="row mb-2 pt-0 pr-0"
              *ngFor="
                let t of category_topics
                  | filterBy: userFilter
                  | paginate: { itemsPerPage: 15, currentPage: p }
              "
              [ngClass]="{ question: t.featured }"
            >
              <div class="col-lg-2 pt-0 pr-0" id="{{ t.id }}">
                <small
                  class="bg-primary featured d-block d-sm-block d-md-block d-lg-none d-xl-none"
                  *ngIf="t.featured"
                >
                  <i class="fa fa-bookmark"></i> featured
                </small>
                <div class="blg-img pt-2">
                  <img
                    src="{{ t.author_avatar }}"
                    alt="{{ t.author }}"
                    class="img-fluid"
                    width="80px"
                    height="70px"
                  />
                </div>
              </div>
              <div class="col-lg-10 pt-0 pr-0">
                <small
                  class="bg-primary featured d-none d-sm-none d-md-none d-lg-block d-xl-block"
                  *ngIf="t.featured"
                >
                  <i class="fa fa-bookmark"></i> featured
                </small>
                <div
                  class="blog-header-2 pt-3"
                  [ngClass]="{ 'mt-4': t.featured }"
                >
                  <h6>
                    <a
                      title="{{ t.title }}"
                      [routerLink]="['/topic', t.id]"
                      (click)="set_page_number(p, t.id)"
                    >
                      {{ t.title }}
                    </a>
                  </h6>
                </div>
                <div class="author_name_date">
                  <small>
                    <span>
                      By
                      <a
                        [routerLink]="['/user-profile', t.author]"
                        style="color: #c154c1;"
                      >
                        {{ t.author }}
                      </a>
                      <span *ngIf="t.edited">
                        | edited - {{ dateTime(t.edited_time) }}
                      </span>
                    </span>
                  </small>
                </div>
                <div class="blog-paragrap pr-2">
                  <span [innerHtml]="first30(t.content)"></span>
                  <small>
                    <a
                      title="{{ t.title }}"
                      [routerLink]="['/topic', t.id]"
                      (click)="set_page_number(p, t.id)"
                    >
                      more
                    </a>
                  </small>
                  <ul class="list-inline mt-2 text-capitalize">
                    <li class="list-inline-item">
                      <i class="fa fa-thumbs-up"></i>
                      {{ t.likes > 0 ? t.likes : "No" }} likes
                    </li>
                    <li class="list-inline-item">
                      <i class="fa fa-comments"></i>
                      {{ t.comments > 0 ? t.comments : "No" }} comments
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="text-center">
              <pagination-controls
                (pageChange)="p = $event"
              ></pagination-controls>
            </div>
          </div>

          <!--END OF CONTENT-->
        </div>
      </div>
      <!-- End of col-lg-7-->
      <div class="col-lg-3">
        <app-profile-preview></app-profile-preview>
      </div>
    </div>
  </div>
</section>
