import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-recovery-questions",
  templateUrl: "./recovery-questions.component.html",
  styleUrls: ["./recovery-questions.component.css"],
})
export class RecoveryQuestionsComponent implements OnInit {
  question_one: string;
  q_one: string = "Select a security question";
  question_two: string;
  q_two: string = "Select a security question";
  answer_one: string;
  answer_two: string;
  questions1 = [
    "What was your childhood nickname?",
    "What is your father's middle name?",
    "What is the name of your first pet?",
    "what year was your father born?",
  ];
  questions2 = [
    "At what age did you have your first kiss?",
    "What’s the name of your favorite candy?",
    "What is your mother's maiden name?",
    "What was your dream job as a child?",
  ];

  constructor(private _auth: AuthService, private _router: Router) {}

  ngOnInit() {
    if (
      window.sessionStorage.getItem("slu_username_fp") !== null &&
      window.sessionStorage["slu_username_fp"] == ""
    ) {
      this._router.navigate(["/auth", "forgot-password"]);
    }
  }

  onSubmit() {
    if (this.q_one == "Select a security question") {
      alert("Please, select a security question");
    } else if (this.q_two == "Select a security question") {
      alert("Please, select a security question");
    } else {
      this.question_one = this.q_one;
      this.question_two = this.q_two;

      try {
        this._auth.recovery_questions(
          this.question_one.trim(),
          this.answer_one.trim(),
          this.question_two.trim(),
          this.answer_two.trim()
        );
      } catch (error) {
        console.log("$runtimeType::onSubmit() -- " + error);
      }
    }
  }
}
