import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import { Subscription } from 'rxjs';
import * as moment from 'moment';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit, OnDestroy {
  username: string;
  role: string;
  gender: string;
  image: string;
  avatar: string;
  password: string;
  new_password: string;
  confirm_password: string;
  id: string;
  uid: string;
  joined: string;
  users;
  user_topics = [];
  blocked_users = [];
  favorite_topics = [];
  sub: Subscription;
  suspended: any;

  constructor(private _auth: AuthService, private _forum: ForumService) {}

  async ngOnInit() {
    this._auth.isAuthenticated();
    this.username = window.sessionStorage['slu_username']
      ? window.sessionStorage['slu_username']
      : window.localStorage['slu_username'];
    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];
    this.avatar = window.sessionStorage['slu_avatarUrl']
      ? window.sessionStorage['slu_avatarUrl']
      : window.localStorage['slu_avatarUrl'];
    this.uid = window.sessionStorage['slu_uid']
      ? window.sessionStorage['slu_uid']
      : window.localStorage['slu_uid'];
    this.joined = window.sessionStorage['slu_joined']
      ? window.sessionStorage['slu_joined']
      : window.localStorage['slu_joined'];
    this.suspended = window.sessionStorage['slu_suspended']
      ? window.sessionStorage['slu_suspended']
      : window.localStorage['slu_suspended'];

    this.sub = this._forum
      .get_user_profile(this.username)
      .subscribe((users) => (this.users = users));
    this.sub = this._forum
      .get_user_topics(this.username)
      .subscribe((topics) => {
        this.user_topics = topics;
      });
    this.sub = this._forum.get_blocked_users(this.username).subscribe((bu) => {
      this.blocked_users = bu;
    });
    this.sub = this._forum
      .get_favorite_topics(this.username)
      .subscribe((ft) => {
        this.favorite_topics = ft;
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.sub.unsubscribe();
  }

  dateTime(dt: string): string {
    return moment(dt).fromNow();
  }

  first30(content: string) {
    return `${content.substring(0, 50)}...`;
  }

  set_user(username: string) {
    window.sessionStorage['user_to_message'] = username;
  }

  category(title: string) {
    return title.split('-').join(' ');
  }

  remove_from_favorites(topic: string) {
    let con = confirm(
      'Are you sure you want to remove to remove from favorite?'
    );
    if (con) {
      this._forum.delete_favorite(topic, this.username);
    }
  }

  unblock_user(user: any) {
    const data = {
      username: user.username,
      blocked_by: this.username,
    };
    let con = confirm('Are you sure you want to unblock this user?');
    if (con) {
      this._forum.unblock_user(data);
    }
  }
}
