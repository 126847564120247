import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ForumService } from 'src/app/services/forum.service';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { SwPush } from '@angular/service-worker';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class SettingsComponent implements OnInit {
  sub: Subscription;
  username: string;
  new_username: string;
  role: string;
  gender: string;
  image: string;
  avatar: string;
  current_password: string;
  password: string;
  new_password: string;
  confirm_password: string;
  uid: string;
  display_avatar = false;
  selected = false;
  imgUrls = [];
  user: any;
  usernames = [];
  confirm_p: boolean = false;
  suspended: any;
  disable: boolean;
  isEnabled: boolean;
  isGranted: any;
  taken: boolean;
  username_changed: boolean = false;

  constructor(
    private _auth: AuthService,
    private _forum: ForumService,
    private _router: Router,
    private _sw_push: SwPush,
    private _spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    this._auth.isAuthenticated();
    this.username = window.sessionStorage['slu_username']
      ? window.sessionStorage['slu_username']
      : window.localStorage['slu_username'];
    this.role = window.sessionStorage['slu_role']
      ? window.sessionStorage['slu_role']
      : window.localStorage['slu_role'];
    this.avatar = window.sessionStorage['slu_avatarUrl']
      ? window.sessionStorage['slu_avatarUrl']
      : window.localStorage['slu_avatarUrl'];
    this.uid = window.sessionStorage['slu_uid']
      ? window.sessionStorage['slu_uid']
      : window.localStorage['slu_uid'];
    this.suspended = window.sessionStorage['slu_suspended']
      ? window.sessionStorage['slu_suspended']
      : window.localStorage['slu_suspended'];
    this.gender = window.sessionStorage['slu_gender']
      ? window.sessionStorage['slu_gender']
      : window.localStorage['slu_gender'];
    this.current_password = window.sessionStorage['slu_password']
      ? window.sessionStorage['slu_password']
      : window.localStorage['slu_password'];
    if (this.gender === 'Male') {
      this.imgUrls = this._auth.male;
    } else if (this.gender === 'Female') {
      this.imgUrls = this._auth.female;
    } else if (this.gender === 'Prefer not to say') {
      this.imgUrls = this._auth.other;
    } else {
      this.imgUrls = [];
    }

    this.sub = this._auth.get_usernames().subscribe((usernames) => {
      this.usernames = usernames;
    });

    this.sub = this._auth.get_user(this.username).subscribe((user) => {
      this.user = user[0];
    });

    this.isEnabled = this._sw_push.isEnabled;
    this.isGranted = Notification.permission;
  }

  postUserImage() {
    this._forum.saveUserImage(this.avatar);
  }

  show_avatar() {
    this.display_avatar = !this.display_avatar;
  }

  select_avatar(img) {
    this.avatar = img;
    this.show_avatar();
    this.selected = true;
    this.postUserImage();
  }

  onChangePassword() {
    var p = this._auth.set('123456$#@$^@1ERF', '' + this.password.trim());
    var p1 = this._auth.set('123456$#@$^@1ERF', '' + this.new_password.trim());
    var p2 = this._auth.set(
      '123456$#@$^@1ERF',
      '' + this.confirm_password.trim()
    );
    if (p === this.current_password) {
      const data = {
        password: p1.toString(),
      };
      this._forum.change_password(data, this.username);

      this._router.navigate(['/settings']);
    } else {
      Swal.fire({
        title: 'Oops!',
        text: 'Please enter the right current password to confirm this action',
        background: '#eeeeee',
        type: 'error',
      });
    }
  }

  check_password(value) {
    if (value === this.password) {
      this.disable = true;
    } else {
      this.disable = false;
    }
  }

  check(value) {
    if (
      this.new_password.trim() !== '' &&
      this.new_password.trim() === this.confirm_password
    ) {
      this.confirm_p = false;
    } else {
      this.confirm_p = true;
    }
  }

  show_old_password(e) {
    e.preventDefault();
    if (document.getElementById('old-eye').classList.contains('fa-eye-slash')) {
      document.getElementById('old-eye').classList.remove('fa-eye-slash');
      document.getElementById('old-eye').classList.add('fa-eye');
      document.getElementById('old-password').setAttribute('type', 'text');
    } else if (
      document.getElementById('old-eye').classList.contains('fa-eye')
    ) {
      document.getElementById('old-eye').classList.remove('fa-eye');
      document.getElementById('old-eye').classList.add('fa-eye-slash');
      document.getElementById('old-password').setAttribute('type', 'password');
    }
  }

  show_new_password(e) {
    e.preventDefault();
    if (document.getElementById('new-eye').classList.contains('fa-eye-slash')) {
      document.getElementById('new-eye').classList.remove('fa-eye-slash');
      document.getElementById('new-eye').classList.add('fa-eye');
      document.getElementById('new-password').setAttribute('type', 'text');
    } else if (
      document.getElementById('new-eye').classList.contains('fa-eye')
    ) {
      document.getElementById('new-eye').classList.remove('fa-eye');
      document.getElementById('new-eye').classList.add('fa-eye-slash');
      document.getElementById('new-password').setAttribute('type', 'password');
    }
  }

  show_con_password(e) {
    e.preventDefault();
    if (document.getElementById('con-eye').classList.contains('fa-eye-slash')) {
      document.getElementById('con-eye').classList.remove('fa-eye-slash');
      document.getElementById('con-eye').classList.add('fa-eye');
      document.getElementById('con-password').setAttribute('type', 'text');
    } else if (
      document.getElementById('con-eye').classList.contains('fa-eye')
    ) {
      document.getElementById('con-eye').classList.remove('fa-eye');
      document.getElementById('con-eye').classList.add('fa-eye-slash');
      document.getElementById('con-password').setAttribute('type', 'password');
    }
  }

  subscribe = () => {
    this._spinner.show();
    if (Notification.permission !== 'granted') {
      this._sw_push
        .requestSubscription({ serverPublicKey: environment.vapid_public_key })
        .then((sub: any) => {
          this._auth.save_vapid_to_user(this.username, this.role, sub);
        })
        .catch((err) =>
          console.error('Could not subscribe to notifications', err)
        );
    }
  };

  check_if_ios = (): boolean => {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  };

  check_if_android = (): boolean => {
    if (
      /Android/i.test(navigator.userAgent) ||
      navigator.userAgent.includes('Chrome')
    ) {
      return true;
    } else {
      return false;
    }
  };

  change_username = (form: NgForm) => {
    if (this.new_username !== '') {
      if (
        confirm(
          `You're about to change your username from ${
            this.username
          } to ${this.new_username
            .trim()
            .replace(/[^a-zA-Z0-9 ]/g, '')}, do you want to proceed?`
        )
      ) {
        this._forum.change_username(
          this.username,
          this.new_username.trim().replace(/[^a-zA-Z0-9 ]/g, '')
        );
      }
      form.reset();
    }
  };

  check_username() {
    if (
      this.new_username !== '' &&
      !this.user.old_username &&
      !this.user.username_changed_at
    ) {
      this._auth
        .check_username(this.new_username.trim().toLowerCase())
        .subscribe((doc) => {
          if (doc.exists) {
            this.taken = true;
          } else {
            this.taken = false;
          }
        });
    }
  }
}
